/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  InputGroup,
  Form,
  Tabs,
  Tab,
  Row,
  Col,
  Accordion
} from "react-bootstrap";
import ResizeableTables from "../../../components/table/resizeable_table";
import SplitterLayout from "react-splitter-layout";
import useDatasetApiRequest from "../../helper/dataset";
import settings from "../../../config/config";
import PopupContent from "../../../components/table/table_popup_content";
import CustomPagination from "../../../components/pagination/pagination";
import search from "../../../components/search/search";
import { DatasetProperties } from "../statistics/dataset_properties";
import { GeometricProperties } from "../statistics/geometric_properties";
import AddTag from "../../../components/edit_text/tag";
import SaveNotes from "../../../components/edit_text/edit";
import CustomToggle from "../../../components/tab/toggle";

const initialHeaderState = {
  Select: { active: true },
  id: { active: true },
  name: { active: true },
  UpdateLabel: { active: true },
  status: { active: true },
  labelType: { active: true },
  creationDate: { active: true },
  Delete: { active: true },
};

const ViewDataset = (props) => {
  let token = props.refreshedIdToken;
  let url = settings.dataset;
  const { dataset_error, is_dataset_loaded, dataset_items } =
    useDatasetApiRequest(url, token);

  const filter_unknown_status = ['UNKNOWN']

  const filtered_datasets = dataset_items.filter(
    (val) => filter_unknown_status.indexOf(val.state) === -1
  );

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tableHeadsObject, setTableHeads] = useState(initialHeaderState);

  useEffect(() => {
    !JSON.parse(localStorage.getItem("tableHeads")) &&
      window.localStorage.setItem(
        "tableHeads",
        JSON.stringify(initialHeaderState)
      );
  }, [tableHeadsObject]);

  const changeTableHeads = (id, e) => {
    let heads = { ...tableHeadsObject };
    heads[id].active = e;
    setTableHeads(heads);

    window.localStorage.setItem("tableHeads", JSON.stringify(tableHeadsObject));
  };
  const saveTableInfo = () => {
    window.localStorage.setItem("tableHeads", JSON.stringify(tableHeadsObject));
    handleClose();
  };

  //for pagination
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 6;
  const currentData = filtered_datasets.slice(currentPage * dataPerPage, (currentPage + 1) * dataPerPage);
  const paginate = (e,pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };
  //for searchbar
  const [search_value, setSearchValue] = useState("");
  const handleSearchInputChanges = (e) => {
    setSearchValue(e.target.value);
  };
  let searchResults = search(filtered_datasets, search_value);
  //for error details
  const [showError, setshowError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  //for checkbox statistics display
  const [dataset_id, setDatasetID] = useState("");
  const [dataset_name, setDatasetName] = useState("");
  const [dataset_version, setDatasetVersion] = useState("");
  const [dataset_tags, setDatasetTags] = useState("");
  const [dataset_notes, setDatasetNotes] = useState("");

  const handleStatisticsDisplay = (dataset_id,dataset_name,dataset_version,dataset_tag,dataset_notes) => {
    setDatasetID(dataset_id)
    setDatasetName(dataset_name)
    setDatasetVersion(dataset_version)
    setDatasetTags(dataset_tag)
    setDatasetNotes(dataset_notes)
  };

  return (
    <SplitterLayout vertical>
      <div className="my-pane">
        <Card.Body>
          <div className="d-flex align-items-center">
            <Card.Title>Datasets</Card.Title>
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Button
                  size="sm"
                  variant="warning"
                  onClick={() =>
                    (window.location.href = "/internal/add-dataset")
                  }
                >
                  Add New Dataset
                </Button>
              </div>
            </div>
          </div>
          <div className="top-menu">
            <InputGroup className="search-bar">
              <Form.Control
                value={search_value}
                onChange={handleSearchInputChanges}
                type="text"
                className="form-control-sm"
                placeholder="Search"
              />
            </InputGroup>

            {filtered_datasets.length > dataPerPage && (
              <CustomPagination
                size={"lg"}
                previous={(e) => paginate(e, currentPage - 1)}
                next={(e) => paginate(e, currentPage + 1)}
                currentPage={currentPage}
                increment={
                  currentPage +
                  1 +
                  " of " +
                  Math.ceil(filtered_datasets.length / dataPerPage)
                }
                pagesCount={Math.ceil(filtered_datasets.length / dataPerPage)}
              />
            )}

            <div className="customised-configuration">
              <i
                className="fas fa-cog fa-lg setting-icon icon-color"
                onClick={handleShow}
              ></i>
            </div>
          </div>
        </Card.Body>
        <ResizeableTables
          minCellWidth={120}
          data={search_value === "" ? currentData : searchResults}
          error={dataset_error}
          loading={is_dataset_loaded}
          user={props}
          tableHeadsObject={tableHeadsObject}
          domainObject="dataset"
          showError={showError}
          setshowError={setshowError}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          handleStatisticsDisplay={handleStatisticsDisplay}
        ></ResizeableTables>

        {/* <div className="d-flex justify-content-end"> */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Preferences</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
            <p className="attribute-column">
              Attribute columns
              <br />
              <span className="attribute-text">
                Select visible attribute columns
              </span>
            </p>
              <PopupContent
                tableHeads={tableHeadsObject}
                handleChange={changeTableHeads}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" onClick={saveTableInfo}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        {/* </div> */}
      </div>

      <div className="my-pane">
        <Card.Body>
          <Card.Title>
            {dataset_id
              ? "Dataset: " + dataset_id + " (" + dataset_name + ")"
              : "Select a dataset"}
          </Card.Title>

          {selectedRow && (
            <Tabs
              defaultActiveKey="Details"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="Details" title="Details">
                <Accordion defaultActiveKey="summary" className="mb-3">
                  <Accordion.Item eventKey="summary">
                    <CustomToggle eventKey="summary">
                      <small>Dataset Summary</small>
                    </CustomToggle>
                    <Accordion.Body>
                      <Row>
                        <Col>
                          <Col>
                            <small>
                              <b>Name</b>
                            </small>
                          </Col>
                          <Col>
                            <small>{dataset_name}</small>
                          </Col>
                        </Col>
                        <Col>
                          <Col>
                            <small>
                              <b>ID</b>
                            </small>
                          </Col>
                          <Col>
                            <small>{dataset_id}</small>
                          </Col>
                        </Col>
                        <Col>
                          <Col>
                            <small>
                              <b>State</b>
                            </small>
                          </Col>
                          <Col>
                            <small>{showError.status}</small>
                          </Col>
                        </Col>
                        <Col>
                          <Col>
                            <small>
                              <b>Label Type</b>
                            </small>
                          </Col>
                          <Col>
                            <small>{showError.labelType}</small>
                          </Col>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Tab>

              <Tab eventKey="Statistics" title="Statistics">
                <DatasetProperties id={dataset_id} {...props} token={token} />
              </Tab>

              {showError && (
                <Tab eventKey="Status checks" title="Status checks">
                  {showError.validationResults ? (
                    <div>
                      {Object.entries(showError.validationResults)
                        .filter(([k, v]) =>
                          ["failure"].some((s) => s === v.status)
                        )
                        .map((error_details, i) => {
                          return (
                            <Accordion
                              key={i}
                              defaultActiveKey={"error details"}
                              className="mb-3"
                            >
                              <Accordion.Item eventKey={"error details"}>
                                <CustomToggle eventKey={"error details"}>
                                  <small>Error Details</small>
                                </CustomToggle>
                                <Accordion.Body>
                                  <Row>
                                    <Col>
                                      <Col>
                                        <small>
                                          <b>{error_details[0]}</b>
                                        </small>
                                      </Col>
                                      <Col>
                                        <small>
                                          Message :{" "}
                                          {error_details[1]["message"]}{" "}
                                        </small>
                                        <br />
                                        <small>
                                          {" "}
                                          Count : {error_details[1]["count"]}
                                        </small>
                                      </Col>
                                    </Col>
                                  </Row>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          );
                        })}
                    </div>
                  ) : null}
                  {showError.status === "FAILURE" ? (
                    <Row>
                      <Col>
                        <Col>
                          <small>
                            <b>Error Message : </b>
                          </small>
                        </Col>
                        <Col>
                          <small>{showError.statusExplanation}</small>
                        </Col>
                      </Col>
                    </Row>
                  ) : null}
                </Tab>
              )}

              <Tab eventKey="Geometric" title="Geometric">
                {dataset_id === selectedRow.id && <GeometricProperties id={dataset_id} {...props} token={token} />}
              </Tab>

              <Tab eventKey="Photometric" title="Photometric"></Tab>
              <Tab eventKey="Tags" title="Tags">
                <AddTag
                  tags={dataset_tags}
                  id={dataset_id}
                  user={props}
                  version={dataset_version}
                  domainObject={"dataset"}
                  token={token}
                />
              </Tab>
              <Tab eventKey="Notes" title="Notes">
                <SaveNotes
                  notes={dataset_notes}
                  id={dataset_id}
                  user={props}
                  version={dataset_version}
                  domainObject={"dataset"}
                  token={token}
                />
              </Tab>
            </Tabs>
          )}
        </Card.Body>
      </div>
    </SplitterLayout>
  );
};

export default ViewDataset;
