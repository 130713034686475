/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Form, Button, Card, Col, Row } from "react-bootstrap";
import { Requests } from "../request/request.js";
import settings from "../../config/config";
import { Link } from 'react-router-dom';
import { Spinner, Badge } from "reactstrap";
import CustomAlert from "../../components/alert/alert";
import Icons from "../../components/icons/icons";
import useIsMountedRef from '../../views/helper/isMounted'

export const CreateRoles = (props) => {
  const [checked, setChecked] = useState([]);
  const [userType, setUserType] = useState("Xailient");
  const [name, setName] = useState("");
  const [permissionList, setPermissionList] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [label, setLabel] = useState("Xailient");
  const isMountedRef = useIsMountedRef();
  let token = props.refreshedIdToken;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true)
  const [spin, setSpin] = useState(false);
  const [roleNameLabel, setRoleNameLabel] = useState("");

  useEffect(() => {
    var permissionAPI = settings.permission;
    setLoading(true)
    fetch(permissionAPI, {
      method: "GET",
      headers: {
        Accept: "*",
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if(isMountedRef.current && data && !data.status){
        setPermissionList(data);
        setLoading(false)
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, isMountedRef]);

  const handleGetIndexOfObj = (group) => {
    if (selectedPermissions.length > 0) {
      for (let i = 0; i < selectedPermissions.length; i++) {
        const element = selectedPermissions[i];
        if (element.group == group) {
          return i;
        }
      }
      return selectedPermissions.length;
    } else {
      return 0;
    }
  };

  const handleSelectedPermissions = (checked, permission, group) => {
    if (checked) {
      let tempArr = [...selectedPermissions];
      let exist = false;
      for (let i = 0; i < tempArr.length; i++) {
        const element = tempArr[i];
        if (element.group == group) {
          for (let j = 0; j < element.outputArr.length; j++) {
            const element1 = element.outputArr[j];
            if (element1 == permission) {
              exist = true;
            }
          }
        }
      }
      if (!exist) {
        if (selectedPermissions.length == 0) {
          let tempArr = [...selectedPermissions];
          tempArr[handleGetIndexOfObj(group)] = {
            group: group,
            outputArr: [permission],
          };
          setSelectedPermissions(tempArr);
        } else {
          let tempArr = [...selectedPermissions];
          if (tempArr[handleGetIndexOfObj(group)]) {
            tempArr[handleGetIndexOfObj(group)] = {
              ...tempArr[handleGetIndexOfObj(group)],
              outputArr: [
                ...tempArr[handleGetIndexOfObj(group)].outputArr,
                permission,
              ],
            };
            setSelectedPermissions(tempArr);
          } else {
            tempArr[handleGetIndexOfObj(group)] = {
              group: group,
              outputArr: [permission],
            };
            setSelectedPermissions(tempArr);
          }
        }
      }
    } else {
      setSelectedPermissions([
        ...selectedPermissions.filter(
          (item, i) => i != handleGetIndexOfObj(group)
        ),
      ]);
    }
  };
  
  var isChecked = (item) =>
    checked.includes(item) ? "checked-item small" : "not-checked-item small";

  const createDynamicObj = () => {
    let newObj = {};
    for (let i = 0; i < selectedPermissions.length; i++) {
      const element = selectedPermissions[i];
      newObj[element.group] = element.outputArr;
    }
    return newObj;
  };

  const handleUserTypeChange = (userType) => {
    setUserType(userType);
    setSelectedPermissions([]);
    changeLabel(userType)

  };
  const changeLabel = (userType) => {
    if(userType === "Xailient")
      setLabel("Xailient")  
      else if (userType === "XailientAdmin" ){
      setLabel("Xailient Admin") 
      }  
    else
      setLabel("Customers")
    return label
  }


  const isPermissionSelected = (p, group) => {
    for (let i = 0; i < selectedPermissions.length; i++) {
      const element = selectedPermissions[i];
      if (element.group == group) {
        for (let j = 0; j < element.outputArr.length; j++) {
          const element1 = element.outputArr[j];
          if (element1 == p) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSpin(true);
    //integrating role api
    const submitObj = {
      userType: String(userType),
      name: name,
      permissions: createDynamicObj(),
    };
    var roleAPI = settings.role;
    Requests(roleAPI, "POST", JSON.stringify(submitObj), "event_body", token)
      .then((data) => {
        if (data && data.statusCode !== 400) {
          setSpin(false);
          setRoleNameLabel(name)
          setSuccess(true);
          resetForm()
        }
        if (data.statusCode === 400) {
          setSpin(false);
          setError(`An account with role ${submitObj.name} already exists.`);
        }
      })
      .catch((err) => {
        setSpin(false);
        setError('Oops, Server Error. Please try again later. If error persist please contact support@xailient.com ');
      });
  };

  const resetForm = () => {
    setUserType("Xailient")
    setName("")
    setSelectedPermissions([])
  }
  
  return (
    <div>
      <div className="content mt-4">
        <div>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <Card.Title className="custom-card-title">
                  Create New Role
                </Card.Title>
                <div className="ml-auto d-flex no-block align-items-center">
                  <div className="dl">
                  <Link to={'/viewroles'}>
                    <Button
                      type="submit"
                      id="view-roles-btn"
                      variant="primary"
                      className="mb-2"
                    >
                      View roles{" "}
                    </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <hr />
              <Form onSubmit={handleSubmit}>
                {success && (
                  <CustomAlert
                    message={`${roleNameLabel} created successfully.`}
                    theme="success"
                    image={Icons.CheckMark}
                    style={{ textAlign: "inherit" }}
                  />
                )}
                {error && (
                  <CustomAlert
                    message={error}
                    theme="danger"
                    image={Icons.Alert}
                    style={{ textAlign: "inherit" }}
                  />
                )}
                <Form.Group>
                  <Form.Label style={{ color: "#333333" }}>
                    User Type
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="userType-roles"
                    value={userType}
                    required
                    onChange={(e) => handleUserTypeChange(e.target.value)}
                  >
                    <option value="">Choose</option>
                    <option value="Xailient">Xailient</option>
                    <option value="Customer">Customer</option>
                    <option value="XailientAdmin">Xailient Admin</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mt-3 mb-3">
                  <Form.Label style={{ color: "#333333" }}>
                    Role Name
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Role Name"
                    required
                    name="role-name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Permissions for <b>{label}</b></Form.Label>

                  {permissionList ? (
                    <div className="checkList">
                      <Row>
                        {permissionList
                          .filter((item) => item.userType == userType)
                          ?.map((item, index) => (
                            <div key={index} className="mr-5">
                              <b className="font-14">
                                {
                                  permissionList.filter(
                                    (item) => item.userType == userType
                                  )[index]?.group
                                }
                              </b>
                              {item.permissions.map((p, i) => (
                                <Col key={i}>
                                  <Row className="align-items-center ml-1">
                                    <input
                                      name={"permissions"}
                                      checked={isPermissionSelected(
                                        p,
                                        permissionList.filter(
                                          (item) => item.userType == userType
                                        )[index]?.group
                                      )}
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleSelectedPermissions(
                                          e.target.checked,
                                          p,
                                          permissionList.filter(
                                            (item) => item.userType == userType
                                          )[index]?.group
                                        )
                                      }
                                    />
                                    &nbsp; &nbsp;
                                    <span className={isChecked(p)}>{p}</span>
                                  </Row>
                                </Col>
                              ))}
                              <br />
                            </div>
                          ))}
                      </Row>
                    </div>
                  ) : (
                    "No permissions to show"
                  )}

                  {loading && (
                    <div className="text-center">
                      <Spinner
                        style={{ color: "#705AF3" }}
                        animation="grow"
                      ></Spinner>
                    </div>
                  )}

                  <br></br>
                </Form.Group>

                <Button
                  type="submit"
                  id="create-roles-btn"
                  variant="primary"
                  style={{ margin: "0 auto", display: "block" }}
                >
                  Submit {""}
                  {spin && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      color="light"
                    />
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};