/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DashboardSidebar from "../components/sidebar/dashboard_sidebar";
import DashboardThemeRoutes from "../routes/main_route";
import DefaultThemeRoutes from "../routes/default_route";
import settings from "../config/config";
import { Requests } from "../views/request/request";
import useIsMountedRef from '../views/helper/isMounted'
import CustomSpinner from "../components/Spinner/customSpinner";
import { TimeoutLogic } from "../components/auto-logout/timeout_logic";

const DashboardLayout = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [userType, setUserType] = useState();
  const [error, setError] = useState(null);
  const isSubscribed = useRef(null);
  const isMountedRef = useIsMountedRef();

  props.history.listen((location, action) => {
    if (
      window.innerWidth < 767 &&
      document
        .getElementById("main-wrapper")
        .className.indexOf("show-sidebar") !== -1
    ) {
      document.getElementById("main-wrapper").classList.toggle("show-sidebar");
    }
  });

  //logic for checking permissions

  let retrieveEmail = props.authData;
  let idToken = retrieveEmail && retrieveEmail.signInUserSession.idToken;
  let token = idToken && idToken.jwtToken;
  let email = retrieveEmail && `${Object.values(retrieveEmail)[0]}`;
  let encodedEmail = email && encodeURIComponent(email);
  const [roleID, setRoleID] = useState();
  const [menuList, setMenuList] = useState();
  
  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  useEffect(() => {
    isSubscribed.current = true;
  
    const updateDimensions = () => {
      let element = document.getElementById("main-wrapper");
      setWidth(window.innerWidth);
      if (element && isMountedRef.current) {
        if (width < 1170) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
          element.classList.add("mini-sidebar");
          if (element.className.indexOf("mini-sidebar") !== -1) {
            if(document.getElementById("brand-thumbnail")){
            document.getElementById("brand-thumbnail").style.display =
              "initial";
            }
            if(document.getElementById("brand")){
            document.getElementById("brand").style.display = "none";
            }
          }
        } else {
          element.setAttribute("data-sidebartype", "full");
          element.classList.remove("mini-sidebar");
          if(document.getElementById("brand-thumbnail")){
          document.getElementById("brand-thumbnail").style.display = "none";
          }
          if(document.getElementById("brand")){
          document.getElementById("brand").style.display = "initial";
          }
        }
      }
    };

    if (document.readyState === "complete") {
      updateDimensions();
    }
    window.addEventListener("resize", updateDimensions.bind(this));
    window.addEventListener("load", updateDimensions.bind(this));
    return () => {
      window.removeEventListener("load", updateDimensions.bind(this));
      window.removeEventListener("resize", updateDimensions.bind(this));
      isSubscribed.current = false;
    };
  }, [width, isMountedRef]);


  const checkUserPermission = () => {
    Requests(settings.user, "GET", `customerID=${encodedEmail}`, "query", token).then(
      (data) => {
        if(isMountedRef.current && data && !data.status){
          window.localStorage.setItem("userType", data[0].userType.toLowerCase());
          setUserType(data[0].userType.toLowerCase());
          setRoleID(data[0].roleID);
          if(data[0].roleID){
            Requests(
              settings.role,
              "GET",
              `id=${data[0].roleID}`,
              // userType === "xailient" ? "userType=1" : "userType=2",
              "query",
              token
            ).then((data) => {
              if(isMountedRef.current && Object.keys(data).length > 0 && !data.status){
              let RoleName = data.map((e) => e.permissions);
              const sortPermissions = () => {
                for (var i = 0; i < RoleName.length; i++) {
                  return RoleName[i];
                }
              };
              let PermissionsList = sortPermissions();
              let permissionsObj = Object.keys(PermissionsList).length > 0 ? Object.keys(PermissionsList) : [];
              window.localStorage.setItem("permission", permissionsObj);
              setMenuList(permissionsObj);
            }else{
              setMenuList(null)
              setError('No data found')
              return null;
            }
            });
            }else{
              setError('No data found')
              return null;
            }
        }else{
          setError('No data found')
          return null;
        }
      }
    ).catch((error) => {
      setError('No data found')
      return null;
    });
  }
  useEffect(() => {
    checkUserPermission()
   }, [isMountedRef]);

  /*--------------------------------------------------------------------------------*/
  /* Theme Setting && Layout Options wiil be Change From Here                       */
  /*--------------------------------------------------------------------------------*/
  return (
    <div
      id="main-wrapper"
      data-theme="dark"
      data-layout="vertical"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
      data-boxed-layout="full"
    >
      {/*--------------------------------------------------------------------------------*/}
      {/* Sidebar                                                                        */}
      {/*--------------------------------------------------------------------------------*/}
      {userType && roleID && menuList ? (
        <DashboardSidebar {...props} routes={DashboardThemeRoutes()} />
      ) : (
         <DashboardSidebar {...props} routes={DefaultThemeRoutes(error)} />
      )}

      {/*--------------------------------------------------------------------------------*/}
      {/* Page Main-Content                                                              */}
      {/*--------------------------------------------------------------------------------*/}
      <div className="page-wrapper d-block">
        <div className="page-content container-fluid">
          <Switch>
            {userType && roleID && menuList
              ? DashboardThemeRoutes().map((prop, key) => {
                  if (prop.redirect) {
                    return (
                      <Redirect from={prop.path} to={prop.pathTo} key={key} />
                    );
                  } else {
                    return (
                      <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                      />
                    );
                  }
                })
              : !error && (
                <React.Fragment>
                  <div
                    style={{
                      margin: "auto",
                      verticalAlign: "middle",
                      display: "block",
                    }}
                    className="mt-5"
                  >
                    <CustomSpinner />
                  </div>
                  </React.Fragment>
                )}
          </Switch>
        </div>
        <TimeoutLogic/>
      </div>
    </div>
  );
};

export default DashboardLayout;
