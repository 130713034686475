import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import AccountSetup from './main_layouts/cognito_layout';
import AccountSetup from './main_layouts/login_layout';
import './assets/scss/style.scss';
// import './assets/scss/internal.css';
import './assets/scss/all/bootstrap/bootstrap.css';
import reportWebVitals from './reportWebVitals';

// if (process.env.REACT_APP_STAGE === 'customer_dev') {
//   require(`./assets/scss/customer.css`)
// }
// if (process.env.REACT_APP_STAGE === 'internal_dev') {
//   require('./assets/scss/internal.css');
// }
// require(`./assets/scss/orchestrait.scss`)
require(`./assets/scss/customer.css`)


  ReactDOM.render(

  // <Router>
  //   {process.env.REACT_APP_STAGE === 'internal_dev' ? 
  //   <Route path="/" component={AccountSetup} /> 
  //   :
  //   process.env.REACT_APP_STAGE === `customer_dev` && 
  //   <Route path="/" component={AccountSetup} /> 
  //   }
  // </Router>,
  <Router>
    <Route path="/" component={AccountSetup} />
  </Router>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
