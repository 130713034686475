/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Modal,
  InputGroup,
  Form,
  Tabs,
  Tab,
  Row,
  Col,
  Accordion,
  Image
} from "react-bootstrap";
import SplitterLayout from "react-splitter-layout";
import ResizeableTables from "../../../components/table/resizeable_table";
import useSDKApiRequest from "../../helper/sdk";
import settings from "../../../config/config";
import PopupContent from "../../../components/table/table_popup_content";
import CustomPagination from "../../../components/pagination/pagination";
import search from "../../../components/search/search";
import { Requests } from "../../../views/request/request";
import Icons from "../../../components/icons/icons";
import AddTag from "../../../components/edit_text/tag";
import SaveNotes from "../../../components/edit_text/edit";
import CustomToggle from "../../../components/tab/toggle";
import CustomSpinner from "../../../components/Spinner/customSpinner";
import useIsMountedRef from '../../../views/helper/isMounted'

const initialHeaderState = {
  Select: { active: true },
  id: { active: true },
  version: { active: true },
  buildInfo: { active: true },
  status: { active: true },
  Models: { active: false },
  Publish: { active: true },
  creationDate: { active: true },
  Delete: { active: true },
};

const ViewSdk = (props) => {
  const token = props.refreshedIdToken;
  const url = settings.sdk;
  const { sdkError, isSdkLoaded, sdkItems } = useSDKApiRequest(url, token);

  const [show, setShow] = useState(false);
  const [modelName, setModelName] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tableHeadsObject, setTableHeads] = useState(initialHeaderState);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    !JSON.parse(localStorage.getItem("tableHeads")) &&
      window.localStorage.setItem(
        "tableHeads",
        JSON.stringify(initialHeaderState)
      );
  }, [tableHeadsObject, isMountedRef]);

  const changeTableHeads = (id, e) => {
    let heads = { ...tableHeadsObject };
    heads[id].active = e;
    setTableHeads(heads);

    window.localStorage.setItem("tableHeads", JSON.stringify(tableHeadsObject));
  };
  const saveTableInfo = () => {
    window.localStorage.setItem("tableHeads", JSON.stringify(tableHeadsObject));
    handleClose();
  };

  //for pagination
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 6;
  const currentData = sdkItems.slice(currentPage * dataPerPage, (currentPage + 1) * dataPerPage);
  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };
  //for searchbar
  const [search_value, setSearchValue] = useState("");
  const handleSearchInputChanges = (e) => {
    setSearchValue(e.target.value);
  };
  let searchResults =
    Object.keys(sdkItems).length > 0 &&
    search(sdkItems, search_value).slice(
      currentPage * dataPerPage,
      (currentPage + 1) * dataPerPage
    );
  //for error details
  const [showError, setshowError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  //for checkbox statistics display
  const [sdkID, setSdkID] = useState("");
  const [sdkName, setSdkName] = useState("");
  const [sdkInfo, setSdkInfo] = useState("");
  const [sdkVersion, setSdkVersion] = useState("");
  const [sdkTags, setSdkTags] = useState("");
  const [sdkNotes, setSdkNotes] = useState("");

  const handleStatisticsDisplay = (
    sdkID,
    sdkName,
    sdkVersion,
    sdkTags,
    sdkNotes,
    sdkInfo
  ) => {
    setSdkID(sdkID ? sdkID : 'hhh');
    setSdkName(sdkName);
    setSdkVersion(sdkVersion);
    setSdkTags(sdkTags);
    setSdkNotes(sdkNotes);
    setSdkInfo(sdkInfo);
  };

  return (
    <SplitterLayout vertical>
      <div className="my-pane card mt-4">
        <Card.Body>
          <div className="d-flex align-items-center">
            {/* <Card.Title>SDKs</Card.Title> */}
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl"></div>
            </div>
          </div>
          <div className="top-menu">
            {isSdkLoaded &&
              (Object.keys(searchResults).length > 0 ||
                Object.keys(sdkItems).length > 0) && (
                <InputGroup className="search-bar">
                  <Form.Control
                    value={search_value}
                    onChange={handleSearchInputChanges}
                    type="text"
                    className="form-control-sm"
                    placeholder="Search"
                  />
                </InputGroup>
              )}

            {sdkItems.length > 1 && (
              <CustomPagination
                size={"lg"}
                previous={(e) => paginate(e, currentPage - 1)}
                next={(e) => paginate(e, currentPage + 1)}
                currentPage={currentPage}
                increment={
                  currentPage +
                  1 +
                  " of " +
                  Math.ceil(sdkItems.length / dataPerPage)
                }
                pagesCount={Math.ceil(sdkItems.length / dataPerPage)}
              />
            )}

            {isSdkLoaded &&
              (Object.keys(searchResults).length > 0 ||
                Object.keys(sdkItems).length > 0) && (
                <div className="customised-configuration">
                  <i
                    className="fas fa-cog fa-lg setting-icon icon-color"
                    onClick={handleShow}
                  ></i>
                </div>
              )}
          </div>
        </Card.Body>
        {isSdkLoaded &&
          (Object.keys(searchResults).length > 0 ||
            Object.keys(sdkItems).length > 0) && (
            <ResizeableTables
              minCellWidth={120}
              data={search_value === "" ? currentData : searchResults}
              error={sdkError}
              loading={isSdkLoaded}
              user={props}
              tableHeadsObject={tableHeadsObject}
              domainObject="sdk"
              showError={showError}
              setshowError={setshowError}
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
              handleStatisticsDisplay={handleStatisticsDisplay}
            ></ResizeableTables>
          )}
        {!sdkError &&
          !isSdkLoaded &&
          !(
            search_value &&
            Array.isArray(searchResults) &&
            searchResults.length === 0
          ) && (
            <div
              style={{
                margin: "auto",
                verticalAlign: "middle",
                display: "block",
              }}
            >
              <CustomSpinner />
            </div>
          )}
        {(sdkError ||
          (search_value &&
            Array.isArray(searchResults) &&
            searchResults.length === 0)) && (
          <Card.Title style={{ textAlign: "center", color: "gray" }}>
            <Image
              style={{
                margin: "0 auto",
                display: "block",
              }}
              src={Icons.emptyData.toString()}
              alt=""
            ></Image>

            {search_value &&
            Array.isArray(searchResults) &&
            searchResults.length === 0 ? (
              <>
                <Card.Text>No Results.</Card.Text>{" "}
                <Card.Text>There are no matching SDKs found. </Card.Text>
              </>
            ) : (
              sdkError && <div>{sdkError} </div>
            )}
          </Card.Title>
        )}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Preferences</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className="attribute-column">
              Attribute columns
              <br />
              <span className="attribute-text">
                Select visible attribute columns
              </span>
            </p>
            <PopupContent
              tableHeads={tableHeadsObject}
              handleChange={changeTableHeads}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={saveTableInfo}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* </div> */}
      </div>

      <div className="my-pane">
        <Card.Body>
          <Card.Title>{sdkID && selectedRow ? "SDK: " + sdkID : "Select one SDK at a time"}</Card.Title>

          {selectedRow && (
            <Tabs
              defaultActiveKey="Details"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="Details" title="Details">
                <Accordion defaultActiveKey="summary" className="mb-3">
                  <Accordion.Item eventKey="summary">
                    <CustomToggle eventKey="summary">
                      <small>SDK Summary</small>
                    </CustomToggle>
                    <Accordion.Body>
                      <Row>
                        {/* */}
                        <Col>
                          <Col>
                            <small>
                              <b>ID</b>
                            </small>
                          </Col>
                          <Col>
                            <small>{sdkID}</small>
                          </Col>
                        </Col>
                        <Col>
                          <Col>
                            <small>
                              <b>Version</b>
                            </small>
                          </Col>
                          <Col>
                            <small>{sdkVersion}</small>
                          </Col>
                        </Col>
                        <Col>
                          <Col>
                            <small>
                              <b>State</b>
                            </small>
                          </Col>
                          <Col>
                            <small>{showError.status}</small>
                          </Col>
                        </Col>
                        <Col>
                          <Col>
                            <small>
                              <b>Build Info</b>
                            </small>
                          </Col>
                          <Col>
                            <small>
                              <ul>
                                {Object.entries(sdkInfo.buildInfo).map(
                                  ([k, v]) => {
                                    return (
                                      <li key={k}>{k + " - " + v.status}</li>
                                    );
                                  }
                                )}
                              </ul>
                            </small>
                          </Col>
                        </Col>
                        <Col>
                          <Col>
                            <small>
                              <b>Models</b>
                            </small>
                          </Col>
                          <Col>
                            <small>
                              {sdkInfo.models.map((model) => {
                                var params = `id=${model[0]}&version=${model[1]}`;
                                Requests(
                                  settings.model,
                                  "GET",
                                  params,
                                  "query",
                                  token
                                ).then((response) => {
                                  if (
                                    response &&
                                    response[0] &&
                                    response[0].name
                                  ) {
                                    setModelName(response[0].name);
                                    // document.getElementById("model_name").innerHTML =
                                    //   response[0].name;
                                  } else {
                                    setModelName(null);
                                    // return;
                                    // document.getElementById("model_name").innerHTML = "-"
                                  }
                                  if (
                                    response.message ===
                                    "The incoming token has expired"
                                  ) {
                                    window.location.reload(true);
                                  }
                                });
                                return (
                                  <div key={model} id="model_name">
                                    {modelName}
                                  </div>
                                );
                              })}
                            </small>
                          </Col>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Tab>

              <Tab eventKey="Tags" title="Tags">
                <AddTag
                  tags={sdkTags}
                  id={sdkID}
                  user={props}
                  version={sdkVersion}
                  domainObject={"sdk"}
                  token={token}
                />
              </Tab>
              <Tab eventKey="Notes" title="Notes">
                <SaveNotes
                  notes={sdkNotes}
                  id={sdkID}
                  user={props}
                  version={sdkVersion}
                  domainObject={"sdk"}
                  token={token}
                />
              </Tab>
            </Tabs>
          )}
        </Card.Body>
      </div>
    </SplitterLayout>
  );
};

export default ViewSdk;
