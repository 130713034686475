/*eslint-disable*/
/*
""" 
  A js script to check whether label files (polygon and/or boundry box) are present 
  in an archive file. Currently supported archive formats are .zip and .tar.gz, while 
  the supported annotation formats include Pascal VOC (.xml), YOLO (.txt), AWS (.json), 
  LabelMe (.json), Hasty v1.1 (.json), Supervisely (.json), LabelBox (.json), and 
  Xailient (.csv) for boundry box labels and Pascal VOC (.xml), LabelMe (.json, .xml), 
  COCO (.json), Hasty v1.1 (.json), Supervisely (.json), LabelBox (.json) and 
  Xailient (.csv) for polygon labels.

  Example Usage:
  GetLabelType(test_data/test.zip)
"""
*/
import { useState } from "react";
import JSZip from "jszip";
import utf8 from "utf8";

const useAutoDetectLabels = () => {
  const [labels_exist, setLabelsExisted] = useState(false);

  /* 
    A script to check whether label files (polygon and/or boundry box) 
    are present in an archive file. 
  */
  const checkColumnsExist = (set1, set2) => {
    for (var k = 0; k < set1.length; k++) {
      if (!set2.has(set1[k])) {
        return false;
      }
    }
    return true;
  };

  /*
  """Check if a CSV file is a legitimate Xailient bbox or polygon label file.
    The checking comprises of comparing the columns inside the file to a list of 
    mandatory (minimum) columns that should exist inside a Xailient bbox/polygon 
    label file. Note: Only column headers are checked, order of columns and case 
    does not matter.
    :param archive_file: archive file object
    :param filename: name of file being checked
    :return: bool denoting whether file is a legitimate label file and a set 
    specifying type of labels found
  """
  */
  const checkCsv = (
    archive_file,
    filename,
    min_labels_to_detect,
    label_counts,
    numOfLabelsDetected,
    filesProcessed
  ) => {
    var labelsDetected = false;
    var labelFormatsFound = new Set();
    var mandatoryPolygonColumns = new Set([
      "image_name",
      "class",
      "x1",
      "y1",
      "x2",
      "y2",
      "x3",
      "y3",
    ]);
    var mandatoryBboxColumns = new Set([
      "image_name",
      "class",
      "xmin",
      "ymin",
      "xmax",
      "ymax",
    ]);
    try {
      var fileColumns = "";
      JSZip.loadAsync(archive_file).then(function (zip) {
        zip
          .file(filename)
          .async("text")
          .then(function (fileText) {
            try {
              fileColumns = fileText;
              fileColumns = utf8.decode(fileColumns.split("\n")[0]).split(",");
              fileColumns = fileColumns.map(function (x) {
                return x.toLowerCase();
              });
              if (
                checkColumnsExist(mandatoryBboxColumns, new Set(fileColumns))
              ) {
                console.log("Xailient bbox file found");
                labelFormatsFound.add("xailient_bbox");
              } else if (
                checkColumnsExist(mandatoryPolygonColumns, new Set(fileColumns))
              ) {
                console.log("Xailient polygon file found");
                labelFormatsFound.add("xailient_polygon");
              }
            } catch (err) {
              console.log(err);
            }
            if (labelFormatsFound.size > 0) {
              labelsDetected = true;
            }

            const csvItems = {
              labelsDetected: labelsDetected,
              labelFormatsFound: labelFormatsFound,
            };
            autoDetectResponse(
              labelsDetected,
              labelFormatsFound,
              min_labels_to_detect,
              label_counts,
              numOfLabelsDetected,
              filesProcessed
            );

            return csvItems;
          });
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  /* 
  """Check if an XML file is a legitimate LabelMe, Pascal VOC bbox or polygon 
    label file.
    Checking comprises of seeing if certain elements are present in the XML file.
    :param archive_file: archive file object
    :param filename: name of file being checked
    :return: bool denoting whether file is a legitimate label file and a set 
    specifying type of labels found
  """
  */
  const checkXml = (
    archive_file,
    filename,
    min_labels_to_detect,
    label_counts,
    numOfLabelsDetected,
    filesProcessed
  ) => {
    var labelsDetected = false;
    var labelFormatsFound = new Set();
    var labelmeElements = new Set([
      "folder",
      "filename",
      "imagesize",
      "object",
    ]);
    var vocElements = new Set(["folder", "filename", "size", "object"]);
    try {
      JSZip.loadAsync(archive_file).then(function (zip) {
        zip
          .file(filename)
          .async("text")
          .then(function (fileText) {
            try {
              var xmlData = utf8.decode(fileText);
              var parser = new DOMParser();
              xmlData = parser.parseFromString(xmlData, "text/xml");
              xmlData = xmlData.documentElement.childNodes;
              var xmlElements = [];
              for (var x = 0; x < xmlData.length; x++) {
                xmlElements.push(xmlData[x].tagName);
              }
              if (checkColumnsExist(vocElements, new Set(xmlElements))) {
                try {
                  var objectChild = false;
                  for (var i = 0; i <= xmlData.length; i++) {
                    if (xmlData[i].tagName === "object") {
                      objectChild = xmlData[i];
                      break;
                    }
                  }
                  if (objectChild) {
                    var objectChildChildren = objectChild.childNodes;
                    var foundPolygon = false;
                    if (objectChildChildren) {
                      for (var j = 0; j <= objectChildChildren.length; j++) {
                        if (objectChildChildren[j].tagName === "polygon") {
                          console.log("Pascal VOC polygon file found");
                          labelFormatsFound.add("voc_polygon");
                          foundPolygon = true;
                          break;
                        }
                      }
                      if (foundPolygon) {
                        for (var k = 0; k <= objectChildChildren.length; k++) {
                          if (objectChildChildren[k].tagName === "bndbox") {
                            console.log("Pascal VOC bbox file found");
                            labelFormatsFound.add("voc_bbox");
                            foundPolygon = true;
                            break;
                          }
                        }
                      }
                    }
                  }
                } catch (err) {
                  console.log(err);
                }
              } else if (
                checkColumnsExist(labelmeElements, new Set(xmlElements))
              ) {
                console.log("LabelMe polygon file found");
                labelFormatsFound.add("labelme_polygon");
              }
            } catch (err) {
              console.log(err);
            }
            if (labelFormatsFound.size > 0) {
              labelsDetected = true;
            }

            autoDetectResponse(
              labelsDetected,
              labelFormatsFound,
              min_labels_to_detect,
              label_counts,
              numOfLabelsDetected,
              filesProcessed
            );
          });
      });
    } catch (err) {
      console.log(err);
    }
  };
  

  /*
  """Check if a JSON file is a legitimate AWS SageMaker bbox file or 
    LabelBox, Hasty, Supervisely, LabelMe, COCO bbox or polygon label file.
    Checking comprises of seeing if certain keys are present in the JSON data. 
    The keys vary depending on the format. Note: Only the existance of the keys 
    is checked and used to discern legitimate label files.
    :param archive_file: archive file object
    :param filename: name of file being checked
    :return: bool denoting whether file is a legitimate label file and a set 
    specifying type of labels found
  """
  */
  const checkJson = (
    archive_file,
    filename,
    min_labels_to_detect,
    label_counts,
    numOfLabelsDetected,
    filesProcessed
  ) => {
    var labelsDetected = false;
    var labelFormatsFound = new Set();
    try {
      JSZip.loadAsync(archive_file).then(function (zip) {
        zip
          .file(filename)
          .async("text")
          .then(function (fileText) {
            try {
              var jsonData = utf8.decode(fileText);
              jsonData = JSON.parse(jsonData);
              if (jsonData.constructor === Array) {
                for (var i; i < jsonData.length; i++) {
                  var image = jsonData[i];
                  try {
                    if (image.hasOwnProperty("External ID")) {
                      for (var label in image["Label"]["objects"]) {
                        label = image["Label"]["objects"][label];
                        if (label.hasOwnProperty("title")) {
                          if (label.hasOwnProperty("polygon")) {
                            console.log("LabelBox polygon file found");
                            labelFormatsFound.add("labelbox_polygon");
                          } else if (label.hasOwnProperty("bbox")) {
                            console.log("LabelBox bbox file found");
                            labelFormatsFound.add("labelbox_bbox");
                          }
                        }
                      }
                    }
                  } catch (err) {
                    console.log(err);
                    return;
                  }
                }
              } else {
                var mandatoryCocoKeys = new Set([
                  "images",
                  "annotations",
                  "categories",
                ]);
                var jsonkeys = Object.keys(jsonData);
                if (checkColumnsExist(mandatoryCocoKeys, new Set(jsonkeys))) {
                  console.log("COCO polygon file found");
                  labelFormatsFound.add("coco_polygon");
                } else if (jsonkeys.includes("shapes")) {
                  // eslint-disable-next-line
                  for (var label in jsonData["shapes"]) {
                    label = jsonData["shapes"][label];
                    try {
                      if (label["shape_type"] === "polygon") {
                        console.log("LabelMe polygon file found");
                        labelFormatsFound.add("labelme_polygon");
                      } else if (label["shape_type"] === "rectangle") {
                        console.log("LabelMe bbox file found");
                        labelFormatsFound.add("labelme_bbox");
                      }
                    } catch (err) {
                      console.log(err);
                      continue;
                    }
                  }
                } else if (
                  checkColumnsExist(
                    new Set(["label_classes", "images"]),
                    new Set(jsonkeys)
                  )
                ) {
                  // eslint-disable-next-line
                  for (var image in jsonData["image"]) {
                    image = jsonData["image"][image];
                    try {
                      // eslint-disable-next-line
                      for (var label in image["labels"]) {
                        label = image["labels"][label];
                        if (label.hasOwnProperty("class_name")) {
                          if (label.hasOwnProperty("polygon")) {
                            console.log("Hasty v1.1 polygon file found");
                            labelFormatsFound.add("hasty_polygon");
                          } else if (label.hasOwnProperty("bbox")) {
                            console.log("Hasty v1.1 bbox file found");
                            labelFormatsFound.add("hasty_bbox");
                          }
                        }
                      }
                    } catch (err) {
                      console.log(err);
                      return;
                    }
                  }
                } else if (jsonkeys.includes("objects")) {
                  // eslint-disable-next-line
                  for (var label in jsonData["objects"]) {
                    label = jsonData["objects"][label];
                    try {
                      if (label["geometryType"] === "rectangle") {
                        console.log("Supervisely bbox file found");
                        labelFormatsFound.add("supervisely_bbox");
                      } else if (label["geometryType"] === "bitmap") {
                        console.log("Supervisely bbox file found");
                        labelFormatsFound.add("supervisely_bbox");
                      } else if (label["geometryType"] === "polygon") {
                        console.log("Supervisely polygon file found");
                        labelFormatsFound.add("supervisely_polygon");
                      }
                    } catch (err) {
                      console.log(err);
                      return;
                    }
                  }
                } else {
                  var mandatoryAwsFields = [
                    "image_size",
                    "annotations",
                    "objects",
                    "class-map",
                    "type",
                    "human-annotated",
                    "creation-date",
                    "job-name",
                  ];
                  var countOfFields = 0;
                  if (jsonData.hasOwnProperty("source-ref")) {
                    countOfFields = countOfFields + 1;
                  }
                  for (var key in jsonkeys) {
                    if (jsonData[key].constructor === Object) {
                      if (
                        checkColumnsExist(
                          new Set(Object.keys(jsonData[key])),
                          new Set(mandatoryAwsFields)
                        )
                      ) {
                        countOfFields = countOfFields + 1;
                      }
                    }
                  }
                  if (countOfFields >= 2) {
                    console.log("AWS SageMaker bbox file found");
                    labelFormatsFound.add("aws_bbox");
                  }
                }
              }
            } catch (err) {
              console.log(err);
            }
            if (labelFormatsFound.size > 0) {
              labelsDetected = true;
            }
            autoDetectResponse(
              labelsDetected,
              labelFormatsFound,
              min_labels_to_detect,
              label_counts,
              numOfLabelsDetected,
              filesProcessed
            );
          });
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  /*
  """Check if a TXT file is a legitimate YOLO bbox file.
    YOLO bbox files follow the format outlined at: 
    https://github.com/AlexeyAB/Yolo_mark/issues/60#issuecomment-401854885 
    :param archive_file: archive file object
    :param filename: name of file being checked
    :return: bool denoting whether file is a legitimate label file and a set 
    specifying type of labels found
  """
  */
  const checkTxt = (
    archive_file,
    filename,
    min_labels_to_detect,
    label_counts,
    numOfLabelsDetected,
    filesProcessed
  ) => {
    var labelsDetected = false;
    var labelFormatsFound = new Set();
    try {
      JSZip.loadAsync(archive_file).then(function (zip) {
        zip
          .file(filename)
          .async("text")
          .then(function (fileText) {
            try {
              var rowData = fileText.split("\n");
              for (var row in rowData) {
                row = utf8.decode(rowData[row].trim()).split(" ");
                if (row.length === 5) {
                  try {
                    parseInt(row[0]);
                    try {
                      var slicedArr = row.slice(1);
                      for (var value in slicedArr) {
                        parseFloat(slicedArr[value]);
                      }
                    } catch (err) {
                      console.log(err);
                    }
                    console.log("YOLO bbox file found");
                    labelsDetected = true;
                    labelFormatsFound.add("yolo_bbox");
                    break;
                  } catch (err) {
                    console.log(err);
                  }
                }
              }
            } catch (err) {
              console.log(err);
            }
          });
        autoDetectResponse(
          labelsDetected,
          labelFormatsFound,
          min_labels_to_detect,
          label_counts,
          numOfLabelsDetected,
          filesProcessed
        );
      });
    } catch (err) {
      console.log(err);
    }
  };

  /* 
    This workflow that allows us to auto-detect labels in datasets that get uploaded. 
    If the dataset appears to have no supported labels then we ask for a .csv to be uploaded. 

    """Given a path to a .zip or .tar.gz file, attempts to read `min_labels_to_detect` 
    of any of the supported boundry box labels formats (Pascal VOC, YOLO, AWS, LabelMe, 
    Hasty, Supervisely, LabelBox, Xailient) and/or polygon label formats (Pascal VOC, 
    LabelMe, Xailient, COCO, Hasty, Supervisely, LabelBox), stopping after 
    `max_files_to_process` have been processed.
    :param archive_file: A path to a .zip or .tar.gz file.
    :param min_labels_to_detect: Minimum number of labels files to search for.
    :param max_files_to_process: Maximum number of files to process and check.
    :return: One or more of the following if `min_labels_to_detect` legitimate label files 
    were detected, or None if not enough label files were detected after processing 
    `max_files_to_process` files in the archive: 'xailient_bbox', 'voc_bbox', 'aws_bbox', 
    'labelme_bbox', 'yolo_bbox', 'hasty_bbox', 'supervisely_bbox', 'labelbox_bbox', 
    'xailient_polygon', 'voc_polygon', 'labelme_polygon', 'coco_polygon', 'hasty_polygon', 
    'supervisely_polygon', 'labelbox_polygon'
    """
  */
  const getLabelType = (
    archive_file,
    min_labels_to_detect = null,
    max_files_to_process = null
  ) => {
    var label_counts = {
      xailient_bbox: 0,
      voc_bbox: 0,
      aws_bbox: 0,
      labelme_bbox: 0,
      yolo_bbox: 0,
      hasty_bbox: 0,
      supervisely_bbox: 0,
      labelbox_bbox: 0,
      xailient_polygon: 0,
      voc_polygon: 0,
      labelme_polygon: 0,
      coco_polygon: 0,
      hasty_polygon: 0,
      supervisely_polygon: 0,
      labelbox_polygon: 0,
    };
    var filesProcessed = 0;
    var numOfLabelsDetected = 0;
    var files;

    setLabelsExisted(false);

    if (min_labels_to_detect === null) {
      min_labels_to_detect = 10 ** 10;
    }
    if (max_files_to_process === null) {
      max_files_to_process = 10 ** 10;
    }
    if (max_files_to_process === 0) {
      return null;
    }
    if (min_labels_to_detect < 0 || max_files_to_process < 0) {
      console.log("--min_labels and --max_files must be greater than 0.");
      return null;
    }

    try {
      JSZip.loadAsync(archive_file).then(async (zip) => {
        files = Object.keys(zip.files);

        for (var file in files) {
          file = files[file];
          if (filesProcessed >= max_files_to_process) {
            break;
          }
          var fileExt = file.split(".").pop().toLowerCase();
          if (fileExt === "csv") {
            try {
             await checkCsv(
                archive_file,
                file,
                min_labels_to_detect,
                label_counts,
                numOfLabelsDetected,
                filesProcessed
              );
              
            } catch (err) {
              console.log(err);
              continue;
            }
          } else if (fileExt === "xml") {
            try {
              checkXml(
                archive_file,
                file,
                min_labels_to_detect,
                label_counts,
                numOfLabelsDetected,
                filesProcessed
              );
            } catch (err) {
              console.log(err);
              continue;
            }
          } else if (fileExt === "json") {
            try {
              checkJson(
                archive_file,
                file,
                min_labels_to_detect,
                label_counts,
                numOfLabelsDetected,
                filesProcessed
              );
            } catch (err) {
              console.log(err);
              continue;
            }
          } else if (fileExt === "txt") {
            try {
              checkTxt(
                archive_file,
                file,
                min_labels_to_detect,
                label_counts,
                numOfLabelsDetected,
                filesProcessed
              );
            } catch (err) {
              console.log(err);
              continue;
            }
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const autoDetectResponse = (
    labels_detected,
    labels_format_found,
    min_labels_to_detect,
    label_counts,
    numOfLabelsDetected,
    filesProcessed
  ) => {
    if (labels_detected) {
      setLabelsExisted(true);
      numOfLabelsDetected = numOfLabelsDetected + 1;
      labels_format_found.forEach(
        (labelFormat) =>
          (label_counts[labelFormat] = label_counts[labelFormat] + 1)
      );
      if (numOfLabelsDetected >= min_labels_to_detect) {
        return;
      }
    }
    filesProcessed = filesProcessed + 1;

    console.log("Label formats found: " + JSON.stringify(label_counts));
    console.log("min_labels_to_detect", min_labels_to_detect);
    console.log("numOfLabelsDetected", numOfLabelsDetected);

    if (
      numOfLabelsDetected < min_labels_to_detect &&
      min_labels_to_detect !== 10 ** 10
    ) {
      return null;
    } else {
      var res = [];
      var keys = Object.keys(label_counts);
      for (var key in keys) {
        key = keys[key];
        if (label_counts[key] > 0) {
          res.push(key);
        }
      }
      console.log("Auto Detect Label Response", res);
      return res;
    }
  };

  const labelCheck = (file) => {
    getLabelType(file);
  };

  return [{ labels_exist }, labelCheck];
};

export default useAutoDetectLabels;
