/*eslint-disable*/
import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import swaggerdevjson from "./swagger_dev.json"
import swaggerstagingjson from "./swagger_staging.json"
import swaggerprodjson from "./swagger_prod.json"

function SwaggerApp(props) {

  const requestInterceptor = (req) => (
    {
      ...req, 
      headers: {
        'Authorization': props && props.signInUserSession.idToken.jwtToken
      }
    }
  );

  return (
    <div className="App card">
      <SwaggerUI spec={process.env.REACT_APP_STAGE === 'prod' ? swaggerprodjson : process.env.REACT_APP_STAGE === 'staging' ? swaggerstagingjson : swaggerdevjson} requestInterceptor={requestInterceptor}/>
    </div>
  );
}


export default SwaggerApp;
