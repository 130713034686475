import { TimeStampTodateConverter } from '../timestamp/unix_to_datetime_converter';

export default function search(data, searchValue) {
  return data.filter((dataset) => (
    !searchValue
      || (dataset.name && dataset.name
        .toString()
        .toLowerCase()
        .indexOf(searchValue.toLowerCase()) > -1)
      || (dataset && dataset.notes
          && dataset.notes
            .toString()
            .toLowerCase()
            .includes(
              searchValue
                  && typeof searchValue === 'string'
                  && searchValue.toLowerCase(),
            ))
      || (dataset && dataset.tags
          && dataset.tags
            .toString()
            .toLowerCase()
            .includes(
              searchValue
                  && typeof searchValue === 'string'
                  && searchValue.toLowerCase(),
            ))
      || (dataset && dataset.status
          && dataset.status
            .toString()
            .toLowerCase()
            .includes(
              searchValue
                  && typeof searchValue === 'string'
                  && searchValue.toLowerCase(),
            ))
      || (dataset.labelType
          && dataset.labelType
            .toString()
            .toLowerCase()
            .includes(
              searchValue
                  && typeof searchValue === 'string'
                  && searchValue.toLowerCase(),
            ))
      || (dataset && dataset.creationDate
          && TimeStampTodateConverter(dataset.creationDate)
            .toString()
            .toLowerCase()
            .includes(
              searchValue
                  && typeof searchValue === 'string'
                  && searchValue.toLowerCase(),
            ))
      || (dataset && dataset.id
          && dataset.id
            .toString()
            .toLowerCase()
            .includes(
              searchValue
                  && typeof searchValue === 'string'
                  && searchValue.toLowerCase(),
            ))
      || (dataset.version
              && dataset.version
                .toString()
                .toLowerCase()
                .includes(
                  searchValue
                      && typeof searchValue === 'string'
                      && searchValue.toLowerCase(),
                ))
      || (dataset.buildInfo
        && Object.keys(dataset.buildInfo)
          .toString()
          .toLowerCase()
          .includes(
            searchValue
                && typeof searchValue === 'string'
                && searchValue.toLowerCase(),
          ))
      || (dataset.modelArchitecture
          && dataset.modelArchitecture
            .toString()
            .toLowerCase()
            .includes(
              searchValue
                  && typeof searchValue === 'string'
                  && searchValue.toLowerCase(),
            ))

  ), []);
}
