/*eslint-disable*/
/*
   Choose one or more classes to begin the training process. 
   Step 2 - Invoke the custom and pre-loaded dataset API by combining the total no of classes and show it as a list.
*/
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Button, Row } from "react-bootstrap";
import settings from "../../../../config/config";
import { Spinner } from "reactstrap";
// import CustomPagination from "../../../components/pagination/pagination.js";
import Icons from '../../../../components/icons/icons';
import { stringify } from 'query-string';
import { Requests } from "../../../request/request.js";
import CustomAlert from "../../../../components/alert/alert"

const Classes = (props) => {

  var token = (props.refreshedIdToken && (props.refreshedIdToken === props.signInUserSession.idToken.jwtToken)) ? props.refreshedIdToken : (props.refreshedIdToken && (props.refreshedIdToken !== props.signInUserSession.idToken.jwtToken)) ? props.refreshedIdToken : props.signInUserSession.idToken.jwtToken;

  const [selected_class, setSelectedClass] = useState("");
  const [custom_classes, setCustomClasses] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [validated, setValidated] = useState(false);
  const [is_class_loaded, setIsClassLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const [disableCheckbox, setDisableCheckbox] = useState(false);
  const isSubscribe = React.useRef(false);

  useEffect(() => {
    fetchPrivateDatasetClasses(isSubscribe.current = false);
    return () => {
      isSubscribe.current = true;
    };
    // eslint-disable-next-line
  }, [token,props.chooseDatasets[0].dataset]);


  function fetchPrivateDatasetClasses(isSubscribe) {
    var listDatasetApi = settings.dataset;
    const queryParams={
      id:props.chooseDatasets[0].dataset
   }
   var ids =  stringify(queryParams);
   var fields = "&" + stringify({
      fields:['classes']
   });
   var params =  "?" + ids + fields;
   
     Requests(listDatasetApi, "GET", params, "query", token)
      .then((data) => {
        console.log("Fetching List of Datasets to Choose Classes ",data);
        if (!isSubscribe.current && data) {
          var objectOfClasses = {};
          var arrayOfClasses = [];

          var datasetItems = props.chooseDatasets[0].dataset;
          if (!datasetItems || datasetItems.length === 0) {
            setCustomClasses([]);
            return null;
          } else {
            setIsClassLoading(true);
            data.map((item) => {
              arrayOfClasses.push(...item.classes);
              objectOfClasses = [...new Set(arrayOfClasses)];
              return objectOfClasses;
            });
            setCustomClasses(objectOfClasses);
          }
        }
      })
      .catch((err) => {
        console.log("Error Fetching List of Datasets: ", err);
        setIsClassLoading(false);
      });
  }

  function handleChange(event) {
    setError(null);
    var selectAll = document.getElementById("option");
    if (event.target.checked === false) {
      selectAll.checked = false;
      setSelectedClass("");
    }
    setSelectedClass({
      ...selected_class,
      [event.target.name]: event.target.checked,
    });
  }

  var checkCaseSensitive = custom_classes.toLocaleString().toLowerCase().split(",");
  checkCaseSensitive.sort(sortClass);
  var classItems = [...new Set(checkCaseSensitive)];

  function sortClass(dataset1, dataset2) {
    var a = dataset1;
    var b = dataset2;

    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    } else if (a === b) {
      return 0;
    }
  }

  function handleSelectClassSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (Object.entries(selected_class).length === 0) {
      setSpin(false);
      setError("Please select one or more classes to train your model.");
      return null;
    } else {
      setSpin(true);
      const acceptedValues = [true];
      var filteredObject = Object.keys(selected_class).reduce(function (key, e) {
        if (acceptedValues.includes(selected_class[e])) key[e] = selected_class[e];
        return key;
      }, {});

      var classes = Object.keys(filteredObject);
      if (classes.length === 0) {
        setSpin(false);
        setError("Please select one or more classes to train your model.");
        return null;
      }

      var datasetIDs = props.chooseDatasets[0].dataset;

      var selectedList = {
        datasetIDs,
        // public_dataset_ids,
        classes,
      };

      setSpin(false);
      setDisableCheckbox(true);
      var value = {
        items: selectedList,
        // email: email,
        token: token,
      };
      props.chooseClasses.push(value);

      props.nextStep();
      props.jumpToStep(2);
    }
  }

  function handleClick(e, index) {
    e.preventDefault();
    setCurrentPage(index);
  }

  var pageSize = 15;
  var pagesCount = Math.ceil(classItems.length / pageSize);

  function handlecheckall(e) {
    setError(false);
    var checkboxArray = [];
    // eslint-disable-next-line
    classItems.map(function (values, id) {
      const allCheckboxChecked = e.target.checked;
      var items = document.getElementsByClassName("subOption");
      //Select and Deselect of checkbox logic
      if (allCheckboxChecked) {
        if (items[id]) {
          items[id].checked = true;
        }
        checkboxArray.push({ key: [values], value: e.target.checked });
      } else {
        if (items[id]) {
          items[id].checked = false;
        }
      }
    });

    //Convert array of objects into single object
    var result = {};
    for (var i = 0; i < checkboxArray.length; i++) {
      result[checkboxArray[i].key] = checkboxArray[i].value;
    }
    setSelectedClass(result);
  }

  return (
    <Card>
      <Card.Body>
        <div>
          
            <Card.Title>2. Combine classes</Card.Title>
            
              <hr/>
              <Row>
            <Col>
              {/* {classItems.length > 5 && (
                <CustomPagination
                  style={{ float: "right" }}
                  size={"sm"}
                  currentPage={currentPage}
                  pagesCount={pagesCount}
                  previous={(e) => handleClick(e, currentPage - 1)}
                  next={(e) => handleClick(e, currentPage + 1)}
                  increment={currentPage + 1 + " of " + pagesCount}
                  customStyleLink={true}
                />
              )} */}
            </Col>
          </Row>
          <br />
          {is_class_loaded ? (
            <div>
              <Row>
                <Col>
                  <Form
                    noValidate
                    validated={validated}
                    style={{ overflow: "auto" }}
                  >
                    <ul
                      className="chooseClass"
                      style={{
                        listStyle: "none",
                      }}
                    >
                      <li>
                        <input
                          type="checkbox"
                          disabled={disableCheckbox}
                          value=""
                          name="option"
                          id="option"
                          className="option"
                          onClick={(e) => handlecheckall(e)}
                        />
                        <label htmlFor="option" className="checkbox-select-all">
                          {" "}
                          Select all classes
                        </label>
                      </li>
                      <br />
                    </ul>

                    <ul className="checkboxes">
                      {classItems
                        .slice(
                          currentPage * pageSize,
                          (currentPage + 1) * pageSize
                        )
                        .map(function (val, i) {
                          return (
                            <li key={i}>
                              <input
                                type="checkbox"
                                checked={selected_class[val] || ""}
                                id={i}
                                name={val}
                                disabled={disableCheckbox}
                                className="subOption"
                                onChange={handleChange}
                              />

                              <label style={{ color: "#212529" }} htmlFor={i}>
                                {val}
                              </label>
                              <hr />
                            </li>
                          );
                        })}
                      <br />
                    </ul>
                  </Form>
                  <br />
                  {error && (
                     <CustomAlert
                     message={error}
                     theme="danger"
                     image={Icons.WarningRed}
                   />
                  )}
                </Col>
                <div className="vertical-divider" />
                <Col sm={3}>
                  <Card.Text className="summary">Summary</Card.Text>
                  <Card.Text>
                    <small style={{ fontSize: "12px", color: "#777777" }}>
                      Datasets Used
                    </small>
                  </Card.Text>
                  {props.chooseDatasets[0] &&
                    props.chooseDatasets[0].dataset_name && (
                      <div className="wrap-text">
                        {props.chooseDatasets[0].dataset_name.map((val) => (
                          <div key={val}>{val}</div>
                        ))}
                      </div>
                    )}

                  {props.chooseDatasets[0] &&
                    props.chooseDatasets[0].public_dataset_name && (
                      <div className="wrap-text">
                        {props.chooseDatasets[0].public_dataset_name.map((val) => (
                          <div key={val}>{val}</div>
                        ))}
                      </div>
                    )}
                </Col>
              </Row>

              {/* Check if user is subscribed to access config file upload feature or not */}
              {/* {props.isSubscribed ? (
                <Button
                  size="sm"
                  variant="primary"
                  disabled={disable}
                  onClick={(e) => {
                    Event("CustomModels", "TrainNewModel", "SelectClasses");
                    handleClassSubmit(e);
                  }}
                  style={{ float: "right" }}
                >
                  Continue{" "}
                  {start && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      color="primary"
                    />
                  )}
                </Button>
              ) : ( */}
                <Button
                  size="sm"
                  variant="warning"
                  style={{ float: "right" }}
                  onClick={(e) => {
                    handleSelectClassSubmit(e);
                  }}
                >
                  Continue {" "}
                  {spin && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      color="light"
                    />
                  )}
                </Button>
              {/* )} */}
            </div>
          ) : !error ? (
            <Spinner
            size="sm"
            color="warning"
            className="align-items"
          />
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Classes;
