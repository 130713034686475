/*eslint-disable*/
import React from "react";
import { Auth } from "aws-amplify";
import {
  SignIn,
  Link,
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  ButtonRow,
} from "aws-amplify-react";
import { Image, InputGroup, Form, Button, Alert } from "react-bootstrap";
import { Spinner } from "reactstrap";
import Icons from "../../components/icons/icons";
import CustomAlert from "../../components/alert/alert"
import { Route } from "react-router-dom";
import Home from '../../main_layouts/login_layout';


export default class MySignIn extends SignIn {
  _isMounted = false;
  state = {
    status: false,
    msg: null,
    show: true,
    loader: false,
    height: 0,
    width: 0,
    passwordShown: "password",
    failure: false,
    validated: false,
    isEmailVerified: false
  };
  constructor() {
    super();
    window.addEventListener("resize", this.update);
  }

  componentDidMount() {
    this._isMounted = true;

    this.update();
    document.body.style.backgroundColor = "white"
    window.addEventListener("resize", function () {
      return window.innerWidth;
    });

    var queryParams = new URLSearchParams(window.location.search);
    var status = queryParams.get('status');
    if (status === "success") {
      this.setState({ isEmailVerified: true })
    }
    if (status === "failure") {
      this.setState({ msg: "Verification link is not valid/expired." })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  update = () => {
    if (this._isMounted) {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
  };

  signIn() {
    const { password, username } = this.inputs;
    this.setState({ msg: null });
    this.setState({ loader: true });
    this.setState({ validated: true });
    var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;

    if (!username && !password) {
      this.setState({ msg: "Email and Password fields cannot be empty." });
      this.setState({ loader: false });
    }
    else if (username && username.search(mailformat) === -1) {
      this.setState({ loader: false });
      this.setState({ msg: "You have entered an invalid email address!" });
      return true;
    }
    else {
      const param = {
        username: username,
        password: password,
        validationData: [],
      };
      Auth.signIn(param)
        .then((data) => {
          console.log(data,'data');
          if (data && data.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const { requiredAttributes } = data.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            Auth.completeNewPassword(
                data,               // the Cognito User Object
                password,       // the new password
            ).then(user => {
                console.log(user);
                this.setState({ msg: null });
                this.setState({ show: false });
                this.setState({ loader: false });
                this.setState({ status: true });
            }).catch(e => {
              console.log(e);
            });
        } else {
          this.setState({ msg: null });
          this.setState({ show: false });
          this.setState({ loader: false });
          this.setState({ status: true });
        }
        })
        .catch((error) => {
          this.setState({ loader: false });
          if (error["code"] === "NotAuthorizedException") {
            this.setState({ msg: "Incorrect email or password." });
          }
          else if (error["code"] === "InvalidParameterException") {
            return false;
          }
          else if (error["code"] === "UserNotFoundException") {
            this.setState({ msg: "Email does not exist. Please create an account." });
          } else {
            console.log(error);
            return false;
          }
        });
    }
  }

  togglePassword = () => {
    if (this.state.passwordShown === "password") {
      this.setState({ passwordShown: "text" });
      return;
    }
    this.setState({ passwordShown: "password" });
  };


  showComponent(theme) {
    return (
      <div>
        {this.state.status && this._isMounted && (
          <Route path="/" render={(props) => <Home {...props} />} />
        )}
        {this.state.show && (
          <div>

            <div
              style={{
                textAlign: "center",
                margin: "auto",
                display: "block",
              }}
              className="mt-5 logo-icon"
            >
              <Image
                id="brand"
                src={Icons.xailientOrchestraitLogo.toString()}
                alt="homepage"
                style={{ marginLeft: "15px", marginTop: "50px" }}
              />
              <Image
                id="brand-thumbnail"
                src={Icons.Xlogo.toString()}
                alt="homepage"
                style={{
                  marginLeft: "15px",
                  marginTop: "30px",
                  display: "none",
                }}
              />
            </div>

            <FormSection style={{ background: "#FFFFFF" }} theme={theme}>
              <div>
                <SectionHeader style={{ textAlign: "center" }} theme={theme}>
                  <b>Login to Orchestrait</b>
                </SectionHeader>

                {this.state.isEmailVerified && (
                  <Alert variant="success">
                    <Image src={Icons.CheckMark} className="login-timer" />
                    {''}
                    <span className="emailSuccessMsg">Email has been successfully verified</span> <br></br>
                    <span className="verifyEmailLoginMsg">You can now login to Orchestrait.</span>
                  </Alert>
                )}

                {this.state.msg && (
                  <CustomAlert
                    message={this.state.msg}
                    theme="danger"
                    image={Icons.Alert.toString()}
                    style={{ width: "390px" }}
                  />
                )}

                <SectionBody theme={theme}>
                  <Form
                    noValidate
                    validated={this.state.validated}
                    id="validate"
                  >
                    <Form.Group className="mt-3" controlId="validateUsername">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        placeholder={"Enter your email"}
                        theme={theme}
                        key="username"
                        name="username"
                        type="email"
                        pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$" //RFC 5322
                        onChange={this.handleInputChange}
                        data-testid="emailInput"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email address.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mt-3 mb-3">
                      <Form.Label>Password</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          placeholder={"Enter your password"}
                          theme={theme}
                          type={this.state.passwordShown}
                          key="password"
                          name="password"
                          id="id_password"
                          onChange={this.handleInputChange}
                          data-testid="passwordInput"
                          aria-label={"Enter your password"}
                          onSubmit={() => {
                            this.signIn()
                          }}
                          onKeyPress={(event) => {
                            if (event.charCode === 13) {
                              this.signIn()
                            }
                          }}
                          required
                        />
                        <Button
                          onClick={() => {
                            this.togglePassword();
                          }}
                          theme={theme}
                          style={{ border: "1px solid #ced4da" }}
                          variant="light"
                          id="button-addon1"
                          data-testid="passord_toggle"
                        >
                          <span>
                            {this.state.passwordShown === "password" ? (
                              <i className="fa fa-eye-slash"></i>
                            ) : (
                              <i className="fa fa-eye" id="togglePassword"></i>
                            )}
                          </span>
                        </Button>
                        <Form.Control.Feedback className="mb-1" type="invalid">
                          Please enter a valid password.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <div style={{ color: "var(--grey)", fontSize: "12px" }}>
                    Having trouble logging in?{" "}
                    <Link
                      theme={theme}
                      style={{ float: "right" }}
                      onClick={() => {
                        this.setState({ msg: null });
                        this.changeState("forgotPassword");
                      }}
                    >
                      {"Reset password"}
                    </Link>
                  </div>
                  </Form>
                </SectionBody>

                <SectionFooter theme={theme}>
                  <div style={{ minWidth: "400px", width: "390px" }}>
                    <ButtonRow
                      onClick={() => {
                        this.signIn();
                      }}
                      theme={theme}
                      data-testid="submitButton"
                    >
                      {"Login "}{" "}
                      {this.state.loader && (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          color="light"
                        />
                      )}
                    </ButtonRow>
                  </div>
                </SectionFooter>
                <hr style={{ width: "390px", margin: "initial" }} />
                <br />
                <div
                  style={{
                    textAlign: "center",
                    color: "grey",
                    width: "390px",
                  }}
                >
                  {/* Trouble logging in ? <br />
                  <Link
                    theme={theme}
                    style={{ color: "#6544d0" }}
                    // onClick={() => {
                    //   this.setState({ msg: null });
                    //   this.changeState("signUp");
                    // }}
                    data-testid="signupLink"
                  >
                    {"Contact support@xailient.com"}
                  </Link> */}
                </div>
              </div>
            </FormSection>
          </div>
        )}
      </div>
    );
  }
}