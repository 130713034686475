/*eslint-disable*/
import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
  Nav, Navbar, NavbarBrand, Row, Col, Collapse, NavLink,
} from 'reactstrap';
import Icons from '../icons/icons';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/

function DashboardHeader(props) {
  /*--------------------------------------------------------------------------------*/
  /* To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  const showMobilemenu = () => {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    if ((document.getElementById('main-wrapper').classList.contains('mini-sidebar'))) {
      document.getElementById('logobg').classList.toggle('expand-logo');
      document.getElementById('brand-thumbnail').style.display = 'none';
      document.getElementById('brand').style.display = 'initial';
    }
  };

  return (
    <header className="topbar navbarbg" data-navbarbg="skin6">
        <Navbar className="top-navbar" dark expand="md">
          <div className="navbar-header" id="logobg" data-logobg="skin2">
            <NavbarBrand href="/">
              <b className="logo-icon">
                <img
                  id="brand"
                  // className="hide-menu"
                  src={Icons.xailientOrchestraitLogo}
                  alt="homepage"
                  style={{ marginLeft: '15px', marginTop: '50px' }}
                />
                <img
                  id="brand-thumbnail"
                  src={Icons.Xlogo}
                  alt="homepage"
                  style={{ marginLeft: '15px', marginTop: '30px', display: 'none' }}
                />
              </b>
              {/* <span className="logo-text">
                <img src={Icons.OrchestraitLogoHeader} alt="homepage" />
              </span> */}
            </NavbarBrand>
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}
            <Row>
              <Col>
                <button
                  className="btn-link nav-toggler d-block d-md-none custom-topbar"
                  onClick={() => showMobilemenu()}
                  type="button"
                >
                  <span>
                    <i className="ti-menu ti-close" />
                  </span>
                </button>
              </Col>
              {/* <Col>
                <button
                  className="btn-link nav-toggler d-block d-md-none"
                  onClick={() => signOut()}
                  type="button"
                >
                  <img
                    src={Icons.logouticon}
                    alt="logout"
                    className="iconlogout"
                  />
                </button>
              </Col> */}
          </Row>
        </div>
        <Collapse className="navbarbg" navbar data-navbarbg="skin6">
          <Col sm={10}>
            <Nav>
              {props.routes.map((header, i) => {
                if (header.redirect) {
                  return null;
                }
                return (
                  <NavLink
                    to={header.path}
                    key={i}
                    style={{ color: "#000000", textDecoration: "none" }}
                    activeStyle={{
                      color: "#000000",
                      textDecoration: "none",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "22px",
                      width: "235px",
                      height: "3px",
                      left: "0px",
                      top: "0px",
                    }}
                    activeClassName="active"
                    tag={RRNavLink}
                  >
                    {location.pathname === header.path && header.name}
                  </NavLink>
                );
              })}
            </Nav>
          </Col>
          {/* <Col>
            <span>{window.localStorage.getItem('companyName') !== 'undefined' ? 'Welcome ' + window.localStorage.getItem('companyName') : ''}</span>
          </Col> */}
        </Collapse>
      </Navbar>
    </header>
  );
}
export default DashboardHeader;
