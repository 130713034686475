/*eslint-disable*/
/*
   Choose Training Dataset by selecting one or more custom or pre-loaded datasets : 
   Step 1 - Invoke the list of custom and pre-loaded dataset API by passing Email ID , 
   cognito Id token.
*/
import React, { useEffect, useState,useRef } from "react";
import {
  Card,
  Form,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { Spinner } from "reactstrap";
import Icons from "../../../../components/icons/icons";
import useDatasetApiRequest from "../../../../views/helper/dataset";
import settings from "../../../../config/config";
import CustomAlert from "../../../../components/alert/alert";
import ResizeableTables from "../../../../components/table/resizeable_table";
import PopupContent from "../../../../components/table/table_popup_content";
import CustomPagination from "../../../../components/pagination/pagination";
import search from "../../../../components/search/search";

const initialHeaderState = {
  Select: { active: true },
  name: { active: true },
  creationDate: { active: true },
};

export default function Datasets(props) {
  var token =
    props.refreshedIdToken &&
    props.refreshedIdToken === props.signInUserSession.idToken.jwtToken
      ? props.refreshedIdToken
      : props.refreshedIdToken &&
        props.refreshedIdToken !== props.signInUserSession.idToken.jwtToken
      ? props.refreshedIdToken
      : props.signInUserSession.idToken.jwtToken;
  const isSubscribed = useRef(false);

  const [selected_custom_dataset, setSelectedCustomDataset] = useState({});
  const [disable, setDisable] = useState(false);
  const [dataset_name, setDatasetName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [search_value, setSearchValue] = React.useState("");
  const { dataset_error, is_dataset_loaded, dataset_items } =
    useDatasetApiRequest(settings.dataset, token);
  const sanity_status_filter = ['SANITY_CHECK_COMPLETE','ANALYSIS_COMPLETE','SANITY_CHECK_PARTIAL_SUCCESS','SANITY_CHECK_USER_APPROVED']

  const filtered_datasets = dataset_items.filter(
    (val) => sanity_status_filter.indexOf(val.state) !== -1
  );  

  useEffect(() => {
    if (props.customDatasetID) {
      var customData = {
        emailId: encodeURI(props.attributes.email),
        email: props.attributes.email,
        tokenInfo: props.signInUserSession.idToken.jwtToken,
        dataset: [props.customDatasetID],
        publicDataset: [],
        dataset_name: [props.customDatasetName],
      };
      // setChooseDatasets(customData);
      props.chooseDatasets.push(customData);
      props.nextStep();
      props.jumpToStep(1);
    }

    return () => (isSubscribed.current = true);
    // eslint-disable-next-line
  }, []);

  function handleDatasetChange(event, type, item) {
    setError(null);
    setDatasetName({
      ...dataset_name,
      [item.name]: "",
    });
    setSelectedCustomDataset({
      ...selected_custom_dataset,
      [event.target.name]: event.target.checked === true,
    });
  }

  function handleDatasetCheckboxSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    if (Object.entries(selected_custom_dataset).length === 0) {
      setError(
        "Please select single or combine multiple datasets to train an AI model"
      );
      setIsLoading(false);
      return null;
    } else {
      const acceptedValues = [true];
      var isSelected = Object.keys(
        filterSelectedFields(acceptedValues, selected_custom_dataset)
      );

      if (isSelected.length === 0) {
        setError(
          "Please select single or combine multiple (both pre-loaded and custom) datasets to train an AI model"
        );
        setIsLoading(false);
        return null;
      } else {
        setError(null);
        setDisable(true);

        var selected_custom_dataset_name = Object.keys(
          filterSelectedFields("", dataset_name)
        );

        var userData = {
          emailId: encodeURI(props.attributes.email),
          email: props.attributes.email,
          tokenInfo: token,
          dataset: isSelected,
          dataset_name: selected_custom_dataset_name,
        };

        //   setChooseDatasets(userData);
        props.chooseDatasets.push(userData);
        props.nextStep();
        props.jumpToStep(1);
      }
    }
  }

  function filterSelectedFields(acceptedValues, selectedValues) {
    var filteredItems = Object.keys(selectedValues).reduce(function (key, e) {
      if (acceptedValues.includes(selectedValues[e]))
        key[e] = selectedValues[e];
      return key;
    }, {});
    return filteredItems;
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tableHeadsObject, setTableHeads] = useState(initialHeaderState);

  useEffect(() => {
    !JSON.parse(localStorage.getItem("tableHeadsTrain")) &&
      window.localStorage.setItem(
        "tableHeadsTrain",
        JSON.stringify(tableHeadsObject)
      );
  }, [tableHeadsObject]);

  const changeTableHeads = (id, e) => {
    let heads = { ...tableHeadsObject };
    heads[id].active = e;
    setTableHeads(heads);

    window.localStorage.setItem(
      "tableHeadsTrain",
      JSON.stringify(tableHeadsObject)
    );
  };
  const saveTableInfo = () => {
    window.localStorage.setItem(
      "tableHeadsTrain",
      JSON.stringify(tableHeadsObject)
    );
    handleClose();
  };
  //for pagination
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 6;
  const currentData = filtered_datasets.slice(currentPage * dataPerPage, (currentPage + 1) * dataPerPage);
  const paginate = (e,pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };
  //for searchbar
  const handleSearchInputChanges = (e) => {
    setSearchValue(e.target.value);
  };
  let searchResults = search(filtered_datasets, search_value);

  return (
    <Card>
      <Card.Body>
        <Card.Title>1. Choose training Dataset(s)</Card.Title>

        <hr />

        <div className="top-menu">
          <InputGroup className="search-bar">
            <Form.Control
              value={search_value}
              onChange={handleSearchInputChanges}
              type="text"
              placeholder="Search"
            />
          </InputGroup>
          {filtered_datasets.length > dataPerPage && (
            <CustomPagination
              size={"lg"}
              previous={(e) => paginate(e, currentPage - 1)}
              next={(e) => paginate(e, currentPage + 1)}
              currentPage={currentPage}
              increment={
                currentPage +
                1 +
                " of " +
                Math.ceil(filtered_datasets.length / dataPerPage)
              }
              pagesCount={Math.ceil(filtered_datasets.length / dataPerPage)}
            />
          )}
        </div>

        <br />

        <ResizeableTables
          minCellWidth={120}
          data={search_value === "" ? currentData : searchResults}
          error={dataset_error}
          loading={is_dataset_loaded}
          user={props}
          type="choose_dataset_table"
          checked={selected_custom_dataset}
          onChange={(e, type, item) => handleDatasetChange(e, type, item)}
          disableCheckbox={disable}
          tableHeadsObject={tableHeadsObject}
        />

        <div className="d-flex justify-content-end">
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Toggle Table Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PopupContent
                tableHeads={tableHeadsObject}
                handleChange={changeTableHeads}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" onClick={saveTableInfo}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {is_dataset_loaded && (
          <div>
            {error && (
              <CustomAlert
                message={error}
                theme="danger"
                image={Icons.WarningRed}
              />
            )}

            <Button
              size="sm"
              style={{ float: "right" }}
              disabled={disable}
              variant="warning"
              onClick={(e) => {
                handleDatasetCheckboxSubmit(e);
              }}
            >
              Continue
              {isLoading && <Spinner size="sm" color="light" />}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
