/*eslint-disable*/
import React from "react";
import Amplify, { I18n } from "aws-amplify";
import MySignIn from "../views/account/sign_in";
import MyForgotPassword from "../views/account/forgot_password";
import {
  ConfirmSignIn,
  withAuthenticator,
  AmplifyTheme,
} from "aws-amplify-react";
import awsconfig from "../config/aws-exports";
import DashboardLayout from "./dashboard_layout";

Amplify.configure({
  ...awsconfig,
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

const authScreenLabels = {
  en: {
    Username: "Email",
    "Enter your username": "Enter your email",
    Code: "Enter Verification Code",
  },
};

I18n.setLanguage("en");
I18n.putVocabularies(authScreenLabels);

export default withAuthenticator((DashboardLayout), {
  includeGreetings: true,
   theme: 
   {
    ...AmplifyTheme,
    background: "#ffffff",
    button: {
      ...AmplifyTheme.button,
      backgroundColor: "#705AF3",
      borderColor: "#6544d0",
      color: "#FFFFFF",
      minWidth: "390px",
    },
    container: {
      ...AmplifyTheme.container,
      color: "#6544d0",
    },
    formContainer: {
      ...AmplifyTheme.formContainer,
      textAlign: "center",
      margin: "2% auto 50px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "32px",
      gap: "32px",
      width: "454px",
      background: "#FFFFFF",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
      borderRadius: "16px",
      flex: "none",
      order: "1",
      flexGrow: "0"
    },
    a: {
      ...AmplifyTheme.a,
      color: "#6544d0",
    },

    formSection: {
      ...AmplifyTheme.formSection,
      boxShadow: "unset",
      position: "relative",
      backgroundColor: "unset",
      border: "unset",
      textAlign: "left",
      width: "390px",
      borderRadius: "unset",
      boxSizing: "unset",
    },
    label: {
      ...AmplifyTheme.label,
      fontWeight: "normal",
      color: "rgb(21, 41, 57)",
    },
    navButton: {
      ...AmplifyTheme.navButton,
      color: "white",
      backgroundColor: "#FFFFFF",
    },
    sectionHeader: {
      ...AmplifyTheme.sectionHeader,
      color: "black",
      marginBottom: "10px",
      borderBottomStyle: "unset",
      fontSize: "20px",
      fontWeight: "500",
      backgroundColor: "unset",
      borderColor: "unset",
      padding: "unset",
      textAlign: "center",
      fontFamily: "Inter",
    },
    sectionBody: {
      ...AmplifyTheme.sectionBody,
      marginBottom: "30px",
      padding: "0px",
      color: "#152939",
      fontSize: "14px",
    },
    toast: {
      ...AmplifyTheme.toast,
      display: "flex",
      justifyContent: "space-between",
      top: "0",
      position: "fixed",
      left: "0",
      width: "100%",
      zIndex: "99",
      backgroundColor: "indianred",
      color: " #fff",
      padding: "16px",
    },
    sectionFooter: {
      ...AmplifyTheme.sectionFooter,
      fontSize: "14px",
      backgroundColor: "unset",
      borderTop: "unset",
      padding: "unset",
      borderTopLeftRadius: "unset",
    },
    navBar: {
      ...AmplifyTheme.navBar,
      border: "0px",
    },
    nav: {
      ...AmplifyTheme.nav,
      margin: "11px",
    },
    sectionFooterSecondaryContent: {
      ...Amplify.sectionFooterSecondaryContent,
      float: "right",
    },
    inputLabel: {
      ...AmplifyTheme.inputLabel,
      marginBottom: "10px",
    },
    input: {
      ...AmplifyTheme.input,
      marginBottom: "10px",
      padding: "6px 12px",
      display: "block",
      width: "100%",
    },
  },
  authenticatorComponents: [
    <MySignIn override={"SignIn"} />,
    <ConfirmSignIn />,
    // <MySignUp override={"SignUp"} />,
    <MyForgotPassword override={"ForgotPassword"} />,
  ],
});