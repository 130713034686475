/*eslint-disable*/
import React, { useState, useEffect, Suspense } from "react";
import {
  Table,
  Card,
  Button,
  Spinner,
  Modal,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Requests } from "../request/request";
import settings from "../../config/config";
import Icons from "../../components/icons/icons";
import CustomPagination from "../../components/pagination/pagination";
import { TimeStampTodateConverter } from "../../components/timestamp/unix_to_datetime_converter";
import useIsMountedRef from "../../views/helper/isMounted";
import Popup from "../../components/popups/popup";
import CustomAlert from "../../components/alert/alert";
import { stringify } from "query-string";

export const ViewUsers = (props) => {

  const [token, setToken] = useState(props.refreshedIdToken);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 6;
  const currentData = users.slice(
    currentPage * dataPerPage,
    (currentPage + 1) * dataPerPage
  );
  const isMountedRef = useIsMountedRef();
  const [deletePopup, setDeletePopup] = useState(false);
  const [spin, setSpin] = useState(false);
  const [customerID, setCustomerID] = useState("");
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleClose = () => {
    setError(false);
    setShow(false);
  };
  const handleShow = () => {
    setSuccess(false);
    setShow(true);
  };

  const [newvalue, setnewvalue] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userType, setUserType] = useState("Customer");
  const [formData, setFormData] = useState({
    userType: "Customer",
    roleID: "",
    companyID: props.inviteTeamMember
      ? window.localStorage.getItem("companyID")
      : "",
    customerID: "",
    firstName: "",
    lastName: "",
  });
  const [rolesList1, setRolesList1] = useState([]);
  const [rolesList2, setRolesList2] = useState([]);
  const [rolesList3, setRolesList3] = useState([]);

  const fetchAllUsers = () => {
    var queryParams = {
      q: JSON.stringify({
        userType: 'Customer',
        companyID: window.localStorage.getItem('companyID') || ""
      }),
    };
    Requests(settings.user, "GET", (props.viewTeamMembers ? stringify(queryParams) : "fetch=all"), "query", token).then(
      (data) => {
        if(isMountedRef.current && data && !data.status){
          setUsers(data)
          setLoading(false)
        }else{
          return;
        }
      }
    ).catch((e) => {
      setUsers(null);
      setLoading(false);
      return null;
    });
  }
  useEffect(() => {
    fetchAllUsers();
  }, [isMountedRef]);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const deleteUser = (id) => {
    setSpin(true);
    Requests(
      settings.user,
      "DELETE",
      "customerID=" + encodeURIComponent(id),
      "query",
      token
    )
      .then((data) => {
        setSpin(false);
        setDeletePopup(false);
        fetchAllUsers();
      })
      .catch((e) => {
        setSpin(false);
        setDeletePopup(false);
        return null;
      });
  };

  const viewRoleNames = (id, user_id) => {
    if (id && user_id) {
      Requests(
        settings.role,
        "GET",
        "id=" + encodeURIComponent(id),
        "query",
        token
      )
        .then((data) => {
          if (data && id === data[0].id) {
            return (document.getElementById(user_id).innerHTML = data[0].name);
          } else {
            return null;
          }
        })
        .catch((e) => {
          return null;
        });
    } else {
      return null;
    }
  };

  const handleDeletePopup = (customerID) => {
    setCustomerID(customerID);
    setDeletePopup(true);
  };

  //role type 1

  useEffect(() => {
    Requests(
      settings.role,
      "GET",
      `q=${encodeURIComponent(JSON.stringify({ userType: "Xailient" }))}`,
      "query",
      token
    )
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setRolesList1(data);
        }
      })
      .catch((e) => {
        return null;
      });
  }, [isMountedRef]);

  //role type 2
  useEffect(() => {
    Requests(
      settings.role,
      "GET",
      `q=${encodeURIComponent(JSON.stringify({ userType: "Customer" }))}`,
      "query",
      token
    )
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setRolesList2(data);
        }
      })
      .catch((e) => {
        return null;
      });
  }, [isMountedRef]);

  //role type 3
  useEffect(() => {
    Requests(
      settings.role,
      "GET",
      `q=${encodeURIComponent(JSON.stringify({ userType: "XailientAdmin" }))}`,
      "query",
      token
    )
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setRolesList3(data);
        }
      })
      .catch((e) => {
        return null;
      });
  }, [isMountedRef]);

  const editUser = (id) => {
    handleShow();
    let value = users.filter((user) => user.id === id)[0];
    setnewvalue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setnewvalue({
      ...newvalue,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    setSpin(true);
    e.preventDefault();
    e.stopPropagation();
    let email = newvalue.customerID;
    let encodedEmail = encodeURIComponent(email);
    let param = `customerID=${encodedEmail}`;
    Requests(
      settings.user,
      "PATCH",
      JSON.stringify({
        roleID: newvalue.roleID,
      }),
      "event_body_params",
      token,
      param
    )
      .then((data) => {
        if (data && data.statusCode !== 400) {
          setSuccess(true);
          setSpin(false);
          const updatedUser = users.map((item, i) => {
            if (item.id == data.id) {
              return data;
            } else {
              return item;
            }
          });
          setUsers(updatedUser);
        }
        if (data.statusCode === 400) {
          setSpin(false);
          setError(`An account with name ${newvalue.name} already exists.`);
        }
      })

      .catch((err) => {
        console.log(err);
        setError(
          "Oops, Server Error. Please try again later. If error persist please contact support@xailient.com "
        );
        setSpin(false);
      });
  };

  return (
    <div className="content mt-4">
      <Card>
        <Card.Body style={{ overflow: "auto" }}>
          <div className="d-flex align-items-center">
            <Card.Title className="custom-card-title">{props.viewTeamMembers ? "View Team Members" : `View Users ` } ({users.length})</Card.Title>
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
              <Link to={props.viewTeamMembers ? "/inviteTeamMember" : "/manageusers"}>
                <Button
                  type="submit"
                  id="manage-users-btn"
                  variant="primary"
                  className="mb-2"
                >
                  {props.viewTeamMembers ?  "Invite New Team Member" : "Create new user "}
                </Button>
                </Link>
              </div>
            </div>
          </div>
          <Table
            borderless
            size="md"
            className="border-top"
            id="view-users-table"
          >
            <thead className="font-14">
              <tr className="border-bottom">
                <th className="white-header pl-5 column-width">ID</th>
                <th className="white-header">Email</th>
                <th className="white-header">First Name</th>
                <th className="white-header">Last Name</th>
                <th className="white-header">Role Name</th>
                <th className="white-header">User Type</th>
                <th className="white-header">Company</th>
                <th className="white-header">Date</th>
                <th className="white-header">Edit</th>
                <th className="white-header">Delete</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((user, index) => (
                <tr className="font-14 border-bottom" key={index}>
                  <td className="pl-5">{user.id}</td>
                  <td>{user.customerID}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td id={user.id}>{viewRoleNames(user.roleID, user.id)}</td>
                  <td>{user.userType}</td>
                 <td>{user.companyName}</td>
                  <td>{TimeStampTodateConverter(user.creationDate)}</td>
                   <td>
                    <span>
                      <img
                        alt="Edit"
                        className="icon-button"
                        onClick={() => editUser(user.id)}
                        src={Icons.Edit}
                      />
                    </span>
                  </td>
                   <td>
                    <span>
                      <img
                        alt="Delete"
                        // onClick={(e) => deleteUser(user.customerID)}
                        onClick={(e) => handleDeletePopup(user.customerID)}
                        className="icon-button"
                        src={Icons.Trash}
                      />
                    </span>
                  </td>            
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && (
            <div className="text-center">
              <Spinner style={{ color: "#705AF3" }} animation="grow"></Spinner>
            </div>
          )}
        </Card.Body>
        <CustomPagination
          size="lg"
          previous={() => paginate(currentPage - 1)}
          next={() => paginate(currentPage + 1)}
          currentPage={currentPage}
          increment={`${currentPage + 1} of ${Math.ceil(
            users.length / dataPerPage
          )}`}
          pagesCount={Math.ceil(users.length / dataPerPage)}
        />
        {deletePopup && (
          <Suspense fallback="Loading">
            <Popup
              spin={spin}
              action={() => deleteUser(customerID)}
              show={deletePopup}
              hide={() => setDeletePopup(false)}
              modal_title="Delete Confirm"
              modal_body={`Are you sure you want to delete this user ${customerID} and all of its content ? This action cannot be undone.`}
              button_variant="danger"
              button_label="Delete"
              button_icon="fas fa-trash-alt"
            />
          </Suspense>
        )}
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h4>Edit User</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success && (
            <CustomAlert
              message={`Success, Edited successfully.`}
              theme="success"
              image={Icons.CheckMark}
              style={{ textAlign: "inherit" }}
            />
          )}

          {error && (
            <CustomAlert
              message={error}
              theme="danger"
              image={Icons.Alert}
              style={{ textAlign: "inherit" }}
            />
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mt-3 mb-3">
              <Form.Label style={{ color: "#333333" }}>Email</Form.Label>
              <Form.Control
                required
                name="name"
                value={newvalue.customerID}
                disabled
              />
            </Form.Group>

            {/* <Form.Group className="mt-3 mb-3">
              <Form.Label style={{ color: "#333333" }}>
                Company
              </Form.Label>
              <Form.Control
                name="details"
                value={newvalue.companyName}
                disabled
          
              />
            </Form.Group> */}

            <Form.Group>
              <Form.Label>Select Role</Form.Label>
              <InputGroup>
                <Form.Control
                  as="select"
                  required
                  multiple={false}
                  value={newvalue.roleID}
                  name="roleID"
                  onChange={handleChange}
                >
                  {newvalue.userType === "Customer" &&
                        rolesList2.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      {newvalue.userType === "Xailient" &&
                        rolesList1.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      {newvalue.userType === "XailientAdmin" &&
                        rolesList3.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                </Form.Control>
              </InputGroup>
            </Form.Group>

            <Modal.Footer>
              <Button
                type="submit"
                variant="primary"
                style={{ margin: "0 auto", display: "block" }}
                id="edit-company-btn"
              >
                Submit{" "}
                {spin && (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    color="light"
                  />
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
