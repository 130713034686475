import React from 'react';

import { Spinner } from 'reactstrap';

function CustomSpinner() {
  return (
    <div
      style={{
        textAlign: 'center',
        fontSize: '12px',
        textTransform: 'uppercase',
        color: '#9A9A9A',
        margin: '0 auto',
        display: 'block',
      }}
    >
      <Spinner
        type="grow"
        style={{
          color: '#6544D0',
          margin: '0 auto',
          display: 'block',
        }}
      />
      <span>Loading..</span>
    </div>
  );
}

export default CustomSpinner;
