import React, { useContext } from 'react';
import { AccordionContext, Button, useAccordionButton } from 'react-bootstrap';
import PropTypes from 'prop-types';

function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button
      variant="Light"
      onClick={decoratedOnClick}
      className="pl-0"
    >
      {children}
      <i className={isCurrentEventKey ? 'fas fa-chevron-down ml-2 chevron-icon-size' : 'fas fa-chevron-up ml-2 chevron-icon-size'} />
    </Button>
  );
}

CustomToggle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  callback: PropTypes.string,
};

CustomToggle.defaultProps = {
  callback: '',
};
export default CustomToggle;
