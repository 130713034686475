/*eslint-disable*/
import React from "react";
import { Auth } from "aws-amplify";
import {
  ForgotPassword,
  Link,
  FormSection,
  SectionHeader,
  FormRow,
  SectionBody,
  SectionFooter,
  InputRow,
  ButtonRow,
  Label,
  AmplifyTheme,
} from "aws-amplify-react";
import { Image } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { I18n } from "aws-amplify";
import Icons from "../../components/icons/icons";
import CustomAlert from "../../components/alert/alert";

export default class MyForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this.send = this.send.bind(this);
    this.submit = this.submit.bind(this);
    this._validAuthStates = ["forgotPassword"];
    this.state = { delivery: null };
    window.addEventListener("resize", this.update);
  }
  _isMounted = false;

  state = {
    status: false,
    msg: null,
    show: true,
    loader: false,
    height: 0,
    width: 0,
  };

  componentDidMount() {
    this._isMounted = true;

    this.update();
    document.body.style.backgroundColor = "white";
    window.addEventListener("resize", function () {
      return window.innerWidth;
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  update = () => {
    if (this._isMounted) {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
  };

  send() {
    const { authData = {} } = this.props;
    this.setState({ loader: true });
    this.setState({ msg: null });
    const username = this.getUsernameFromInput() || authData.username;

    if (!Auth || typeof Auth.forgotPassword !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    Auth.forgotPassword(username)
      .then((data) => {
        this.setState({ loader: false });
        this.setState({ msg: null });
        this.setState({ delivery: data.CodeDeliveryDetails });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loader: false });
        this.setState({ msg: "Please enter a valid email address" });
      });
  }

  submit() {
    const { authData = {} } = this.props;
    const { code, password } = this.inputs;
    this.setState({ msg: null });
    this.setState({ loader: true });

    const username = this.getUsernameFromInput() || authData.username;

    if (!Auth || typeof Auth.forgotPasswordSubmit !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    Auth.forgotPasswordSubmit(username, code, password)
      .then((data) => {
        this.setState({ status: true });
        this.setState({ msg: null });
        this.setState({ loader: false });
        this.changeState("signIn");
        this.setState({ delivery: null });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loader: false });
        if (
          err["code"] === "InvalidParameterException" ||
          err["code"] === "InvalidPasswordException"
        ) {
          this.setState({
            msg: "Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters!",
          });
        } else if (err["code"] === "CodeMismatchException") {
          this.setState({
            msg: "Sorry the verification code you entered was invalid, please try again, or request a new code to be sent to your email",
          });
        }
      });
  }

  sendView() {
    const theme = this.props.theme || AmplifyTheme;
    return <div>{this.renderUsernameField(theme)}</div>;
  }

  submitView(theme) {
    //const theme = this.props.theme || AmplifyTheme;
    return (
      <div style={{ width: "80%" }}>
        <FormRow theme={theme}>
          <Label theme={theme}>{I18n.get("Code")} *</Label>
          <InputRow
            placeholder={I18n.get("Code")}
            theme={theme}
            key="code"
            name="code"
            autoComplete="off"
            onChange={this.handleInputChange}
          />
        </FormRow>
        <FormRow theme={theme}>
          <Label theme={theme}>{I18n.get("New Password")} *</Label>
          <InputRow
            placeholder={I18n.get("New Password")}
            theme={theme}
            type="password"
            key="password"
            name="password"
            autoComplete="off"
            onChange={this.handleInputChange}
          />
        </FormRow>
      </div>
    );
  }

  showComponent(theme) {
    const { hide, authData = {} } = this.state;
    if (hide && hide.includes(ForgotPassword)) {
      return null;
    }
    return (
      <div>
        {process.env.REACT_APP_STAGE === "internal_dev" ? (
          <Image
            className="mt-5"
            style={{
              margin: "auto",
              display: "block",
            }}
            src={Icons.xailientLoginImage.toString()}
            alt="homepage"
            width={146}
            height={50}
          />
        ) : (
          <div
            style={{
              textAlign: "center",
              margin: "auto",
              display: "block",
            }}
            className="mt-5 logo-icon"
          >
            <Image
              id="brand"
              src={Icons.xailientOrchestraitLogo.toString()}
              alt="homepage"
              style={{ marginLeft: "15px", marginTop: "50px" }}
            />
            <Image
              id="brand-thumbnail"
              src={Icons.Xlogo.toString()}
              alt="homepage"
              style={{
                marginLeft: "15px",
                marginTop: "30px",
                display: "none",
              }}
            />
          </div>
        )}

        <FormSection theme={theme}>
          {this.state.status && (
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                textTransform: "uppercase",
                color: "secondary",
                margin: "0 auto",
                display: "block",
              }}
            >
              <Spinner
                type="grow"
                style={{
                  color: "secondary",
                  margin: "0 auto",
                  display: "block",
                }}
              />
              <span>Loading..</span>
            </div>
          )}

          <div>
            <SectionHeader
              theme={theme}
              style={{ textAlign: "center" }}
              //data-test={auth.forgotPassword.headerSection}
            >
              <b>{I18n.get("Reset Password")}</b>
              <br />

              {this.state.delivery || authData.username ? (
                <Label
                  style={{
                    marginTop: "10px",
                    textAlign: "justify",
                    fontSize: "12px",
                    color: "#444444",
                    fontFamily: "Inter",
                  }}
                >
                  Enter the verification code and your new password for your
                  xailient account.
                </Label>
              ) : (
                <Label
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    fontSize: "14px",
                    color: "#000000",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                  }}
                >
                  You can reset your password here. An email will be sent to you
                  afterwards.
                </Label>
              )}
              <br />
            </SectionHeader>
            {this.state.msg && (
              <CustomAlert
                message={this.state.msg}
                theme="danger"
                style={{ width: "390px" }}
              />
            )}
            <SectionBody theme={theme}>
              {this.state.delivery || authData.username
                ? this.submitView()
                : this.sendView()}
            </SectionBody>

            <SectionFooter theme={theme}>
              <div>
                {this.state.delivery || authData.username ? (
                  <ButtonRow
                    theme={theme}
                    onClick={this.submit}
                    // data-test={auth.forgotPassword.submitButton}
                  >
                    {I18n.get("Submit")}{" "}
                    {this.state.loader && (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        color="light"
                      />
                    )}
                  </ButtonRow>
                ) : (
                  <ButtonRow
                    theme={theme}
                    onClick={this.send}

                    //data-test={auth.forgotPassword.sendCodeButton}
                  >
                    {I18n.get("Continue")}{" "}
                    {this.state.loader && (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        color="light"
                      />
                    )}
                  </ButtonRow>
                )}
              </div>

              <br />

              <div style={{ width: "390px", textAlign: "center" }}>
                {this.state.delivery || authData.username ? (
                  <Link
                    theme={theme}
                    onClick={this.send}
                    //data-test={auth.forgotPassword.resendCodeLink}
                  >
                    {I18n.get("Resend Code")} <br />
                  </Link>
                ) : (
                  <>
                    <p className="reset-links">
                      Back to
                      <span className="redirection-link">
                        <Link
                          theme={theme}
                          onClick={() => this.changeState("signIn")}
                          //data-test={auth.forgotPassword.backToSignInLink}
                        >
                          {I18n.get("Login")}
                        </Link>
                      </span>
                      {/* or
                      <span className="redirection-link">
                        <Link
                          theme={theme}
                          style={{ color: "#6544d0" }}
                          onClick={() => {
                            this.setState({ msg: null });
                            this.changeState("signUp");
                            this.setState({ isChecked: false });
                          }}
                        >
                          {"Register"}
                        </Link>
                      </span> */}
                    </p>
                  </>
                )}
              </div>
            </SectionFooter>
          </div>
        </FormSection>
      </div>
    );
  }
}