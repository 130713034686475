import React from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';

export default function PopupContent({ tableHeads, handleChange }) {
  return (
    <div className="example">
      {Object.keys(tableHeads).map((tableHead) => (
        <div key={tableHead} className="d-flex align-center mt-2 text-capitalize">
          {tableHead !== 'id' && tableHead !== 'Select' && (
            <Switch
              onChange={(e) => {
                handleChange(tableHead, e);
              }}
              checked={tableHeads[tableHead].active}
              className="react-switch"
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              height={20}
              width={48}
              role={tableHead}
            />
          )}
          {tableHead === 'id' && (
            <Switch
              onChange={(e) => {
                handleChange(tableHead, e);
              }}
              checked={tableHeads[tableHead].active}
              className="react-switch"
              onColor="#86d3ff"
              onHandleColor="#808080"
              handleDiameter={20}
              height={20}
              width={48}
              role={tableHead}
              disabled
            />
          )}
          {/* {tableHead === "Select" && (
              <Switch
                onChange={(e) => {
                  handleChange(tableHead, e);
                }}
                checked={tableHeads[tableHead].active}
                className="react-switch"
                onColor="#86d3ff"
                onHandleColor="#808080"
                handleDiameter={20}
                height={20}
                width={48}
                disabled
              />
            )} */}

          <div className="ms-2 th-name">{tableHead}</div>
        </div>
      ))}
    </div>
  );
}

PopupContent.propTypes = {
  tableHeads: PropTypes.oneOfType([PropTypes.object]),
  handleChange: PropTypes.func,
};

PopupContent.defaultProps = {
  tableHeads: '',
  handleChange: '',
};
