/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Button,
  ListGroup,
  InputGroup,
  Form,
  Row,
  Col,
  Image,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import useSDKApiRequest from '../../helper/sdk';
import settings from '../../../config/config';
import CustomPagination from '../../../components/pagination/pagination';
import search from '../../../components/search/search';
import CustomSpinner from '../../../components/Spinner/customSpinner';
import Icons from '../../../components/icons/icons';
import { Requests } from '../../request/request';
import axios from 'axios';
import {downloadSDKFile} from './downloadSdk'
import { TimeStampTodateConverter } from '../../../components/timestamp/unix_to_datetime_converter';
import CustomAlert from "../../../components/alert/alert";
import { Spinner } from "reactstrap";
import useToken from "../../../views/account/token";

function ViewCustomerSdk(props) {

  const [token, setToken] = useState(props.refreshedIdToken);
  const url = settings.sdk;
  const { sdkError, isSdkLoaded, sdkItems } = useSDKApiRequest(url, token, 'customer');
  const [failure, setFailure] = useState(false);
  const [selectedSDKId, setSelectedSDKId] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [spin, setSpin] = useState(false);
  const [generateNewTokenAPI] = useToken();

  const isSubscribed = useRef(true);
  let searchResults = null;
  // for pagination
  const [currentPage, setCurrentPage] = useState(0);

  const getRefreshedIDToken = async (props) => {
    const refreshedIDToken = await generateNewTokenAPI(props);
    return refreshedIDToken
};

  const dataPerPage = 6;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // for searchbar
  const [searchValue, setSearchValue] = useState('');
  const handleSearchInputChanges = (e) => {
    setSearchValue(e.target.value);
  };

  const searchSDKs = (sdkList, searchInput) => {
    const response = search(sdkList, searchInput).slice(
      currentPage * dataPerPage,
      (currentPage + 1) * dataPerPage,
    );
    return response;
  };
  searchResults = Object.keys(sdkItems).length > 0 && searchSDKs(sdkItems, searchValue);

  function handleSDKDownload(id,token){
    setSuccessMessage(null);
    setSpin(true)
    setFailure(false)
    if (id) {
      var params = "id=" + id + "&__include_urls__=True";
        Requests(settings.sdk, "GET", params, "query", token)
         .then(async function (res){
           if (res && Object.keys(res).length > 0 && !res.message) {
             var sdkDetails =
             res[0] && "buildInfo" in res[0] && res[0].buildInfo;
             console.log('sdkDetails',sdkDetails)
             if (sdkDetails) {
               var sdkPathDetail = Object.values(sdkDetails);
               var sdkUrl = sdkPathDetail[0].cppSDKURL;
               const fileName = sdkPathDetail[0].cppSDKPath;
               await axios
                 .get(sdkUrl, {
                   responseType: "blob",
                 })
                 .then(function(response){
                  console.log("Download SDK file:", response);
                  if (response.status === 200) {
                   downloadSDKFile(fileName, response.config.url);
                   setSpin(false)
                   setSuccessMessage(`Successfully downloaded the SDK file, ${id}`);
                  }
                  });
             }
           }
           else if(res.message === "The incoming token has expired"){
            getRefreshedIDToken(props)
            .then(newtoken => {
              setToken(newtoken);
              if(newtoken){
              handleSDKDownload(id,newtoken);
              }
            })
            .catch(e => {
              console.log(e);
            }); 
           }
         })
         .catch((error) => {
           console.log(error);
           setSpin(false)
           setSuccessMessage(null);
           setFailure(true)
         });
    }
  };

  function checkSDKURL(sdk){
    let platform = Object.keys(sdk.buildInfo)[0]
    if (sdk.buildInfo[platform].cppSDKPath){
      return true
    }
    return false
  }

  useEffect(() => {
    if (props.id && props.id !== ':id') {
      setSearchValue(props.id);
      searchResults = Object.keys(sdkItems).length > 0 && searchSDKs(sdkItems, props.id);
    } else {
      setSearchValue('');
      searchResults = null;
    }
    return (() => {
      isSubscribed.current = false;
    });
    // eslint-disable-next-line
  }, [])

  return (
    <Row className="CustomRow">
      <Col>
        <Card>
          <Card.Body>
            <div className="d-flex align-items-center">
              <Card.Title className="custom-card-title">
                Download SDK
              </Card.Title>
              <div className="ml-auto d-flex no-block align-items-center">
                <div className="dl" />
              </div>
            </div>
            <hr />
            {isSdkLoaded &&
              Object.keys(searchResults).length > 0 &&
              (successMessage || failure) && (
                <CustomAlert
                  message={
                    successMessage
                      ? successMessage
                      : failure
                      ? "Failed to download SDK. Please try again later."
                      : null
                  }
                  theme={successMessage ? "success" : failure ? "danger" : null}
                  image={
                    successMessage
                      ? Icons.CheckMark.toString()
                      : Icons.WarningRed
                  }
                  style={{ textAlign: "start" }}
                />
              )}
            <Card.Text className="custom-card-text">
              Xailient(s) latest FR SDK to integrate your camera with our
              dashboard.
            </Card.Text>

            <div className="top-menu">
              {isSdkLoaded &&
                (Object.keys(searchResults).length > 0 ||
                  Object.keys(sdkItems).length > 0) && (
                  <InputGroup className="search-bar">
                    <Form.Control
                      value={searchValue}
                      onChange={handleSearchInputChanges}
                      type="text"
                      className="form-control-sm"
                      placeholder="Search"
                    />
                  </InputGroup>
                )}
              <div className="customised-configuration">
                {sdkItems && sdkItems.length > 0 && (
                  <CustomPagination
                    size="lg"
                    previous={() => paginate(currentPage - 1)}
                    next={() => paginate(currentPage + 1)}
                    currentPage={currentPage}
                    increment={`${currentPage + 1} of ${Math.ceil(
                      sdkItems.length / dataPerPage
                    )}`}
                    pagesCount={Math.ceil(sdkItems.length / dataPerPage)}
                  />
                )}
              </div>
            </div>
            {isSdkLoaded && Object.keys(searchResults).length > 0 ? (
              <ListGroup as="ol" numbered variant="flush">
                {searchResults.map((sdkItem, i) => {
                  if (sdkItem && sdkItem.buildInfo && checkSDKURL(sdkItem))
                    return <ListGroup.Item
                        as="li"
                        key={i}
                        className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-1 me-auto">
                        <div className="custom-list fw-bold card-heading">{`${sdkItem.id} v.${sdkItem.version}`}</div>
                        <ListGroup className="custom-card-text">
                          {sdkItem.buildInfo &&
                          Object.keys(sdkItem.buildInfo).map(
                              (platform, idx) => (
                                  <Card.Text
                                      key={idx}
                                      className="mt-1 mb-1 custom-list custom-card-text"
                                  >
                                    {platform}{" "}
                                  </Card.Text>
                                  // <Form className="custom-list" key={idx}>
                                  //   <Form.Group className="mt-2 mb-2" controlId={idx}>
                                  //     <Form.Check
                                  //       type="radio"
                                  //       name={sdkItem.id}
                                  //       value={platform}
                                  //       onChange={(e) => {
                                  //         setSelectedSDKItem({
                                  //           id: sdkItem.id,
                                  //           version: sdkItem.version,
                                  //           platform: e.target.value,
                                  //         });
                                  //         setSelectedPlatform({
                                  //           [e.target.name]: e.target.value,
                                  //         });
                                  //       }}
                                  //       checked={
                                  //         platform === selectedPlatform[sdkItem.id]
                                  //       }
                                  //       id={idx}
                                  //       label={platform}
                                  //     />
                                  //   </Form.Group>
                                  // </Form>
                              )
                          )}
                        </ListGroup>
                        <Card.Text className="custom-list custom-card-text">
                          Last Updated{" "}
                          {sdkItem.creationDate &&
                          TimeStampTodateConverter(sdkItem.creationDate)}
                        </Card.Text>
                      </div>
                      <Button
                          variant="primary"
                          size="sm"
                          type="submit"
                          className="mt-3"
                          onClick={() => {
                            setSelectedSDKId(sdkItem.id);
                            handleSDKDownload(sdkItem.id,token);
                          }}
                      >
                        Download{" "}
                        {selectedSDKId === sdkItem.id && spin ? (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                color="light"
                            />
                        ) : (
                            <Image
                                src={Icons.DownloadIcon.toString()}
                                alt="download"
                            />
                        )}
                      </Button>
                    </ListGroup.Item>
                })}
              </ListGroup>
            ) : !sdkError &&
            !isSdkLoaded &&
            !(
              searchValue &&
              Array.isArray(searchResults) &&
              searchResults.length === 0
            ) ? (
              <div
                className="mt-4"
                style={{
                  margin: "auto",
                  verticalAlign: "middle",
                  display: "block",
                }}
              >
                <CustomSpinner />
              </div>
            ) : (
              (sdkError ||
                (searchValue &&
                  Array.isArray(searchResults) &&
                  searchResults.length === 0)) && (
                <Card.Title
                  className="mt-4"
                  style={{ textAlign: "center", color: "gray" }}
                >
                  <Image
                    style={{
                      margin: "0 auto",
                      display: "block",
                    }}
                    src={Icons.emptyData.toString()}
                    alt=""
                  ></Image>
                  {searchValue &&
                  Array.isArray(searchResults) &&
                  searchResults.length === 0 ? (
                    <>
                      <Card.Text>No Results.</Card.Text>{" "}
                      <Card.Text>There are no matching SDKs found. </Card.Text>
                    </>
                  ) : (
                    sdkError && <div>{sdkError} </div>
                  )}
                </Card.Title>
              )
            )}
            {/* {Object.keys(sdkItems).length > 0 &&
              Object.keys(searchResults).length === 0 && (
                <Card.Title style={{ textAlign: "center", color: "gray" }}>
                  <Image
                    style={{
                      margin: "0 auto",
                      display: "block",
                    }}
                    id="myImageID"
                    src={Icons.emptyData.toString()}
                    alt="empty"
                  />

                  <span>No Results Found!</span>
                </Card.Title>
              )} */}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

ViewCustomerSdk.propTypes = {
  refreshedIdToken: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  id: PropTypes.string,
  // version: PropTypes.number,
};

ViewCustomerSdk.defaultProps = {
  refreshedIdToken: '',
  id: '',
  // version: 0,
};

export default ViewCustomerSdk;
