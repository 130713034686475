import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

function CustomAlert({
  theme, message, image, style, button, buttonMsg,
}) {
  return (
    <Alert variant={theme} style={{ ...style }}>
      {image && <span className="pr-4"><img src={image} alt="" className="timer" /></span>}
      {' '}
      <span id="alertMessage" className="alertmsg">{`${message}`}</span>
      {button
      && (
      <>
        <div className="d-flex justify-content-end" />
        <br />
        <Button
          variant="warning"
          onClick={() => window.location.reload()}
        >
          {buttonMsg}
        </Button>
      </>
      )}
    </Alert>
  );
}
CustomAlert.propTypes = {
  theme: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  image: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
  button: PropTypes.string,
  buttonMsg: PropTypes.string,
};
CustomAlert.defaultProps = {
  image: '',
  style: { textAlign: 'center' },
  button: '',
  buttonMsg: '',
};

export default CustomAlert;
