/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Col,
  Card,
  Button,
  Form,
  Row,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { Requests } from "../../../request/request";
import settings from "../../../../config/config";
import Icons from "../../../../components/icons/icons";
import CustomSpinner from "../../../../components/Spinner/customSpinner";
import useIsMountedRef from "../../../../views/helper/isMounted";
import { CustomTable } from "../../../../components/table/custom_table";
import BackendPagination from "../../../../components/pagination/backendPagination";

const initialHeader = [
  "Xailient Device ID",
  "Device ID",
  "Device Status",
  "Hardware Name",
  "Last Updated",
];

function ViewDevices(props) {
  const { refreshedIdToken } = props;
  const [token] = useState(refreshedIdToken);
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(-1);
  const [deviceID, setDeviceID] = useState(null);
  const isMountedRef = useIsMountedRef();
  const [activeFilter, setActiveFilter] = useState(false);
  const [tableheader, setTableheader] = useState(initialHeader);
  const [evaluatedKeys, setEvaluateKeys] = useState([])
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null)
  const [initialDevices, setInitialDevices] = useState([])
  const [pageLimit, setPageLimit] = useState(10)

  const currentData = devices

  useEffect(() => {
    callDeviceAPI(`pageLimit=${pageLimit}`);
  }, [isMountedRef]);

  function next(){
    if (lastEvaluatedKey && !(lastEvaluatedKey in evaluatedKeys)) {
      evaluatedKeys.push(lastEvaluatedKey)
      setCurrentPage(currentPage + 1)
      setDevices([])
      setLoading(true);
      let params = `exclusiveStartKey=${encodeURIComponent(JSON.stringify(evaluatedKeys[currentPage]))}&pageLimit=${pageLimit}`
      callDeviceAPI(params)
    }  
    if (activeFilter === true && lastEvaluatedKey && !(lastEvaluatedKey in evaluatedKeys)) {
      evaluatedKeys.push(lastEvaluatedKey)
      setCurrentPage(currentPage + 1)
      let params = `exclusiveStartKey=${encodeURIComponent(JSON.stringify(evaluatedKeys[currentPage]))}&pageLimit=${pageLimit}`
      reloadPage(`q=${encodeURIComponent(JSON.stringify({"activated":true}))}&${params}&pageLimit=${pageLimit}`);
      } else{
        let params = `exclusiveStartKey=${encodeURIComponent(JSON.stringify(evaluatedKeys[currentPage]))}&pageLimit=${pageLimit}`
        setCurrentPage(0)
        setLoading(true);
        callDeviceAPI(params)
      }  
  }

  function previous() {
    setDevices([])
    setLoading(true);
    if (currentPage - 1 >= 0) {
      let params = `exclusiveStartKey=${encodeURIComponent(JSON.stringify(evaluatedKeys[currentPage-1]))}&pageLimit=${pageLimit}`
      callDeviceAPI(params)
      setCurrentPage(currentPage - 1)
      if (activeFilter === true) {
        let params = `exclusiveStartKey=${encodeURIComponent(JSON.stringify(evaluatedKeys[currentPage-1]))}&pageLimit=${pageLimit}`
        reloadPage(`q=${encodeURIComponent(JSON.stringify({"activated":true}))}&${params}&pageLimit=${pageLimit}`);
      }
    } else {
      setCurrentPage(0)
      setDevices(initialDevices)
      setLoading(false);
    }  
  }

  function reloadPage(params) {
    setLastEvaluatedKey(null)
    setEvaluateKeys([])
    setCurrentPage(-1)
    setDevices([]);
    setLoading(true);
    callDeviceAPI(params);
 
  }
  function callDeviceAPI(params) {
    Requests(settings.device, "GET", params, "query", token)
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setDevices(data.items);
          setLoading(false);
          if(evaluatedKeys.length === 0){
            setInitialDevices(data.items)
            setCurrentPage(currentPage + 1)
          }
          if (data.lastEvaluatedKey) {
            setLastEvaluatedKey(data.lastEvaluatedKey)
          }
          else{
            setLastEvaluatedKey(null)
          }

        }
      })
      .catch((error) => {
        setDevices([]);
        setLoading(false);
        return null;
      });
  }

  function activateAndDeactivateDevice(url) {
    setLoading(true);
    Requests(
      url,
      "POST",
      JSON.stringify({
        xailientDeviceID: deviceID,
        location: {
          countryCode: "",
          postalCode: "",
        },
      }),
      "event_body",
      token
    )
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setDeviceID(null);
          setLoading(false);
          callDeviceAPI("");
        } else {
          setDeviceID(null);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        return null;
      });
  }

  function filterDevices() {
    if (activeFilter) {
      setActiveFilter(false);
      document.getElementById("activeFilterBtn").classList.value =
        "shadow-none btn btn-outline-dark btn-sm btn-hover-color";
      reloadPage(`pageLimit=${pageLimit}`);
    } else {
      setActiveFilter(true);
      document.getElementById("activeFilterBtn").classList.value =
        "shadow-none btn btn-primary btn-sm";
        reloadPage(`q=${encodeURIComponent(JSON.stringify({"activated":true}))}&pageLimit=${pageLimit}`);    
    }
  }

  return (
    <div className="mt-4">
      <Card>
        <Card.Body style={{ overflow: "auto" }}>
          <div className="d-flex align-items-center">
            <Col>
              <Row className="justify-content-between align-items-center">
                <Col className="pl-0" md={4} xs={12}>
                  <Card.Title className="custom-card-title">
                    Devices ({devices.length})
                  </Card.Title>
                </Col>

                <Col className="text-right pr-0" md={4} xs={12}>
                  <Row>
                    <Col>
                      {/* <Button
                        variant="outline-dark"
                        className="rounded-left mr-2"
                      >
                        {" "}
                        <img
                          alt="Reload"
                          className="icon-button"
                          src={Icons.Reload}
                          onClick={() => {
                            reloadPage(`pageLimit=${pageLimit}`);
                          }}
                        />
                      </Button> */}

                      {/* <Dropdown as={ButtonGroup}>
                        <Button variant="primary" className="rounded-left px-3">
                          Actions
                        </Button>
                        <Dropdown.Toggle
                          split
                          variant="primary"
                          className="border-left"
                          id="dropdown-split-basic"
                        />
                        <Dropdown.Menu className="border-0 rounded-right">
                          <Dropdown.Item
                            className="small primary"
                            href="#/action-1"
                          >
                            Remove
                          </Dropdown.Item>
                          {deviceID && (
                            <>
                             
                              <Dropdown.Item
                                className="small primary"
                                onClick={() =>
                                  activateAndDeactivateDevice(
                                    settings.deactivateDevice
                                  )
                                }
                              >
                                Deactivate Device
                              </Dropdown.Item>
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2 align-items-center justify-content-between">
                {/* <Col className="pl-0" md={3} xs={12}>
                  <Form.Control
                    type="text"
                    id="searchDevice"
                    placeholder="Filter..."
                  />
                </Col> */}
                <Col>
                  <Button
                    className="shadow-none btn-hover-color"
                    variant="outline-dark"
                    id="activeFilterBtn"
                    size="sm"
                    onClick={() => filterDevices()}
                  >
                    Activated Devices
                  </Button>
                </Col>
                <Col md={2} xs={12} className="pr-0 mr-18 text-right">
                  <div className="ml-4">
                    <BackendPagination next={next} previous={previous} currentPage={currentPage +1}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </div>

          <CustomTable
            tableheader={tableheader}
            data={currentData}
            viewpage={"devices"}
            token={token}
          />

          {!deviceID && loading && (
            <div className="text-center">
              <div
                className="mt-4"
                style={{
                  margin: "auto",
                  verticalAlign: "middle",
                  display: "block",
                }}
              >
                <CustomSpinner />
              </div>
            </div>
          )}
          <div className="text-right">
            <small>{`page ${currentPage+1}`}</small>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

ViewDevices.propTypes = {
  refreshedIdToken: PropTypes.string,
};

ViewDevices.defaultProps = {
  refreshedIdToken: "",
};

export default ViewDevices;