/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Requests } from "../../../request/request";
import settings from "../../../../config/config";
import { Spinner } from "reactstrap";
import { TimeStampTodateConverter } from "../../../../components/timestamp/unix_to_datetime_converter";
import Icons from "../../../../components/icons/icons";
import { Link } from "react-router-dom";
import CustomSpinner from "../../../../components/Spinner/customSpinner";

export const FrDetail = (props, deviceID) => {
  const { refreshedIdToken } = props;
  const [token] = useState(refreshedIdToken);
  const id = window.location.href.split("/")[5];

  const [frquery, setFrquery] = useState([]);
  const [frID, setfrID] = useState(null);
  const [isLocated, setisLocated] = useState(false);
  const [imagePath, setimagePath] = useState([]);
  const [loading, setLoading] = useState(true);
  const [s3OutputLocation, sets3OutputLocation] = useState("");
  const [name, setname] = useState("");
  const [imageCount, setimageCount] = useState("");
  const [versions, setversions] = useState("");
  const [date, setdate] = useState("");
  useEffect(() => {
    let param = `id=${id}`;
    Requests(settings.frIdentity, "GET", param, "query", token)
      .then((data) => {
        setLoading(false);
        setFrquery(data);
        let selectedArray = data[0];
        let s3OutputLocation = selectedArray.s3OutputLocation;

        let imagePath = selectedArray.imagePaths;

        if (s3OutputLocation && imagePath) {
          setisLocated(true);
          setimagePath(imagePath);
          sets3OutputLocation(s3OutputLocation);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  window.addEventListener("beforeunload", () =>
    localStorage.removeItem("idstorage")
  );
  let previousid = window.localStorage.getItem("idstorage");

  useEffect(() => {
    let frqueryitems = frquery[0];
    if (frqueryitems) {
      let fridentityname = frqueryitems.identityName;
      setname(fridentityname);
      let frimagepaths = frqueryitems.imagePaths.length;
      setimageCount(frimagepaths);
      setversions(frqueryitems.version);
      let frcreationdate = frqueryitems.creationDate;
      setdate(frcreationdate);
    }
  }, [frquery]);

  return (
    <div>
      <div className="mt-4">
        <Link to={`/customer/device/${previousid}`}>
          <span>
            <img alt="menu-icons" src={Icons.ChevronLeft} />
            <label className="text-black-50 link-cursor-pointer font-14">
              {" "}
              Identities
            </label>
          </span>
        </Link>
        <h4 className="mb-4 ml-2 mt-0">
          <b>Fr Details</b>
        </h4>
      </div>
      <Card>
        <Row>
          <Col md={3}>
            <Col className="ml-2 pl-2 pr-2">
              <Col className="mt-2 pl-0">
                <small className="text-black-50 ">
                  <b>Identity Name</b>
                </small>
                <br></br>
                <label>
                  <small className="font-10">{name}</small>
                </label>
              </Col>

              <Col className="mt-2 pl-0">
                <small className="text-black-50 ">
                  <b>Iamges Count</b>
                </small>
                <br></br>
                <label>
                  <small className="font-10">{imageCount}</small>
                </label>
              </Col>

              <Col className="mt-2 pl-0">
                <small className="text-black-50 ">
                  <b>Version</b>
                </small>
                <br></br>
                <label>
                  <small className="font-10">
                    {versions}
                  </small>
                </label>
              </Col>

              <Col className="mt-2 pl-0">
                <small className="text-black-50 ">
                  <b>Date Added on</b>
                </small>
                <br></br>
                <label>
                  <small className="font-10">
                    {TimeStampTodateConverter(date)}
                  </small>
                </label>
              </Col>

              {/*<Col className="mt-2 pl-0">*/}
              {/*  <small className="text-black-50 ">*/}
              {/*    <b>Last update</b>*/}
              {/*  </small>*/}
              {/*  <br></br>*/}
              {/*  <label>*/}
              {/*    <small className="font-10">17 Aug 2022</small>*/}
              {/*  </label>*/}
              {/*</Col>*/}

              {/*<Col className="mt-2 pl-0">*/}
              {/*  <small className="text-black-50 ">*/}
              {/*    <b>Creation Date</b>*/}
              {/*  </small>*/}
              {/*  <br></br>*/}
              {/*  <label>*/}
              {/*    <small className="font-10">17 Aug 2022</small>*/}
              {/*  </label>*/}
              {/*</Col>*/}
            </Col>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
