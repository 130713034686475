/* eslint-disable*/
import VerifyUser from '../views/account/authenticate_user';
import Icons from '../components/icons/icons';

function DefaultThemeRoutes(invalidUserType) {
  return (
    [
      {
        path: '/dashboard',
        component: VerifyUser,
        icon: invalidUserType ? Icons.SDK : '',
        name: invalidUserType ? 'Dashboard' : '',
      },
      {
        path: '/',
        pathTo: '/dashboard',
        name: invalidUserType ? 'Dashboard' : '',
        redirect: true,
      },
    ]);
}
export default DefaultThemeRoutes;
