import React from 'react';
import PropTypes from 'prop-types';

function Checkbox({
  type, name, checked, disabled, onChange,
}) {
  return (
    // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
    <input className="center" type={type} name={name} data-testid="checkbox" role="checkbox" defaultChecked={checked} disabled={disabled} onChange={onChange} />
  );
}
Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
Checkbox.defaultProps = {
  type: 'checkbox',
  checked: false,
  disabled: false,
};

export default Checkbox;
