/*eslint-disable*/
import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { Requests } from '../request/request';
import settings from '../../config/config';
import CustomAlert from '../../components/alert/alert';
import Icons from '../../components/icons/icons';

function CreateCompanies(props) {
  const [formData, setFormData] = useState({
    name: '',
    details: '',
  });

  const { refreshedIdToken } = (props);
  const [token] = useState(refreshedIdToken);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [spin, setSpin] = useState(false);
  const [validated, setValidated] = useState(false);
  const [companyName, setCompanyName] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(() => ({
      ...formData,
      [name]: value,
    }));
  };

  const createCompany = (companyAPI, formDetails) => {
    Requests(companyAPI, 'POST', JSON.stringify(formDetails), 'event_body', token)
      .then((data) => {
        console.log(data.status, 'data');
        if (data && data.statusCode !== 400) {
          setSpin(false);
          setValidated(false);
          setSuccess(true);
          setFormData({
            name: '',
            details: '',
          });
        }
        if (data.statusCode === 400) {
          setSpin(false);
          setValidated(false);
          setError(`An account with name ${formDetails.name} already exists.`);
        }
        
      })
      .catch((err) => {
        setSpin(false);
        setValidated(false);
        setError(
          'Oops, Server Error. Please try again later. If error persist please contact support@xailient.com ',
        );
      });
  };

  const handleSubmit = (e) => {
    setError(false);
    setSuccess(false);

    e.preventDefault();
    e.stopPropagation();

    setValidated(true);

    if (formData && formData.name) {
      setSpin(true);
      const companyAPI = settings.company;
      setCompanyName(formData.name);
      const formDetails = {
        name: formData.name,
        details: {
          description: formData.details,
        },
      };
      createCompany(companyAPI, formDetails);
    } else {
      setValidated(true);
    }
  };

  return (
    <div>
      <div className="content mt-4">
        <div>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <Card.Title className="custom-card-title">
                  Create new company
                </Card.Title>
                <div className="ml-auto d-flex no-block align-items-center">
                  <div className="dl">
                    <Link to="/viewcompanies">
                      <Button
                        type="submit"
                        id="view-users-btn"
                        variant="primary"
                        className="mb-2"
                      >
                        View Companies
                        {' '}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <hr />
              <Form
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
                id="validate"
              >
                {success && (
                  <CustomAlert
                    message={`Success, "${companyName}" created successfully.`}
                    theme="success"
                    image={Icons.CheckMark}
                    style={{ textAlign: 'inherit' }}
                  />
                )}
                {error && (
                  <CustomAlert
                    message={error}
                    theme="danger"
                    image={Icons.Alert}
                    style={{ textAlign: 'inherit' }}
                  />
                )}

                <Form.Group className="mt-3 mb-3">
                  <Form.Label style={{ color: '#333333' }}>
                    Company Name *
                  </Form.Label>
                  <Form.Control
                    placeholder="Company Name"
                    required
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter company name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-3 mb-3">
                  <Form.Label style={{ color: '#333333' }}>
                    Details (Optional)
                  </Form.Label>
                  <Form.Control
                    placeholder="Company Details"
                    name="details"
                    value={formData.details}
                    onChange={handleChange}
                  />
                </Form.Group>

                <br />

                <Button
                  type="submit"
                  variant="primary"
                  style={{ margin: '0 auto', display: 'block' }}
                  id="create-users-btn"
                >
                  Submit
                  {' '}

                  {spin && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      color="light"
                    />
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

CreateCompanies.propTypes = {
  refreshedIdToken: PropTypes.string,
};

CreateCompanies.defaultProps = {
  refreshedIdToken: '',
};

export default CreateCompanies;
