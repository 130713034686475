/*eslint-disable*/
import React, { useState,useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Card,
  Nav, Navbar, NavItem,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Auth } from 'aws-amplify';
import Icons from '../icons/icons';
import DashboardHeader from '../header/dashboard_header';
import { Requests } from "../../views/request/request";
import settings from "../../config/config";


function DashboardSidebar(props) {
  const properties = props;
  /*--------------------------------------------------------------------------------*/
  /* To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
  /*--------------------------------------------------------------------------------*/
  const expandLogo = () => {
    if ((document.getElementById('main-wrapper').classList.contains('mini-sidebar'))) {
      document.getElementById('logobg').classList.toggle('expand-logo');
      document.getElementById('brand-thumbnail').style.display = 'none';
      document.getElementById('brand').style.display = 'initial';
    }
  };

  const CloseLogo = () => {
    if ((document.getElementById('main-wrapper').classList.contains('mini-sidebar'))) {
      document.getElementById('logobg').classList.toggle('expand-logo');
      document.getElementById('brand-thumbnail').style.display = 'initial';
      document.getElementById('brand').style.display = 'none';
    }
    const className = document.querySelector("#main-wrapper").className;
    if (className === "mini-sidebar") {
      document.querySelector(".signOut").style.padding = "3px";
    }
    if (className === "mini-sidebar") {
      document.querySelector(".logout-btn").style.margin = "0px 5px 0px 20px";
    }
  };

  window.addEventListener("resize", (event) => {
    const className = document.querySelector("#main-wrapper").className;
    if (className === "mini-sidebar") {
      document.querySelector(".signOut").style.padding = "5px";
      document.querySelector(".logout-btn").style.margin = "0px 5px 0px 20px";
    }
  })


  
  /*--------------------------------------------------------------------------------*/
  /* Verifies if routeName is the one active (in browser input)                      */
  /*--------------------------------------------------------------------------------*/

  const activeRoute = (routeName) => (properties.location.pathname.indexOf(routeName) > -1 ? 'selected' : '');

  const [user, setUser] = useState('');
  const [companyName, setCompanyName] = useState(null)

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authenticatedUser) => {
        if(authenticatedUser.attributes && authenticatedUser.attributes['custom:firstName'] && authenticatedUser.attributes['custom:lastName']){
        setUser(authenticatedUser.attributes['custom:firstName'] + ' ' + authenticatedUser.attributes['custom:lastName']);
        }else{
          setUser(authenticatedUser.attributes.email)
        }
        fetchUser(authenticatedUser.attributes.email,authenticatedUser.signInUserSession.idToken.jwtToken )

      })
      .catch((err) => console.log(err));
  }, []);

  const fetchUser = (customerID,token) => {
    Requests(settings.user, "GET", "customerID="+encodeURIComponent(customerID), "query", token).then(
      (data) => {
        if(data && data[0] && data[0].companyID){
        var companyID = data[0].companyID;
        Requests(settings.company, "GET", "id="+companyID, "query", token).then(
          (data) => {
            if(data && data[0] && data[0].name){
              setCompanyName(data[0].name)
              window.localStorage.setItem("companyName", data[0].name);
              window.localStorage.setItem("companyID", data[0].id);
            }else{
              setCompanyName(null)
              return null;
            }
          }
        ).catch((e) => {
          return null;
        });
        }else{
          setCompanyName(null)
          return;
        }
      }
    );
  }

  const signOut = async () => {
    try {
      localStorage.clear();
      document.body.style.backgroundColor = 'white';
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log('error signing out: ', error);
      if (error.code === 'NotAuthorizedException') {
        Auth.signOut();
      }
    }
  };

  let name = {user}
  let namevalue = Object.values(name).toString()
  let firstname = namevalue.substring(0, namevalue.indexOf(' '));
  let lastname = namevalue.substring(namevalue.indexOf(' ') ).toString();
  let initial = lastname.charAt(1).toUpperCase()
 
  return (

    <>
      <DashboardHeader {...props}/>
      <aside
        className="left-sidebar"
        id="sidebarbg"
        data-sidebarbg="skin2"
        onMouseEnter={expandLogo.bind(null)}
        onMouseLeave={CloseLogo.bind(null)}
        // onMouseUp={shrinkLogo.bind(null)}
      >
        {' '}
        <br />
        <br />
        <div className="scroll-sidebar">
          <PerfectScrollbar className="sidebar-nav">
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar Menus will go here                                                */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav id="sidebarnav">
              {properties.routes.map((prop, index) => {
                const key = index + 1;
                if (prop.redirect || prop.submenu) {
                  return null;
                }
                return (
                  <li
                    className={`${
                      activeRoute(prop.path)
                      + (prop.pro ? ' active active-pro' : '')
                    } sidebar-item`}
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="sidebar-link"
                      activeClassName="active"
                    >
                      {prop.icon && <img alt="menu-icons" src={prop.icon} />}
                      {' '}
                      <span className="hide-menu menu" id={`${prop.name}`}>
                        {' '}
                        {` ${prop.name}`}
                      </span>
                    </NavLink>
                  </li>
                );
              })}
            </Nav>
           
            <Navbar className="top-navbar" light expand="md">
              <Nav className="float-left" navbar>
                <NavItem
                  className="signOut"
                >
                  <span className="signOut-icon"  id="signOut-btn">
                    <img
                      src={Icons.profilephoto}
                      alt="user"
                      className="rounded-circle"
                      width="31"
                    />
                  </span>

                  <span
                    className=" userInfo profile"
                  >
                    <div className="hide-menu menu text-overflow">{firstname}&nbsp;{initial} </div>
                    {' '}
                    <br />
                    
                    {' '}
                    <span id ="company-name"className=" hide-menu menu" style={{ cursor: 'pointer' }}>{ companyName}</span>
                  </span>
                </NavItem>
                
              </Nav>
             
            </Navbar>

            <span id ="company-name" className='logout-btn' style={{ cursor: 'pointer' }} onClick={() => signOut()}>Logout</span>  
     
            <br />
          </PerfectScrollbar>
          
        </div>
      </aside>
    </>
  );
}
export default DashboardSidebar;