/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'react-bootstrap';

function CustomButton({ children, onClick, variant }) {
  return (
    <Button onClick={onClick} size="sm" variant={variant} type="button" data-testid="button">
      {children}
    </Button>
  );
}
export default CustomButton;
