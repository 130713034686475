/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { TimeoutModal } from "./timeout_modal";
import { addEventListeners, removeEventListeners } from "./event_listener";
import settings from "../../config/config"

export const TimeoutLogic = () => {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  //open the modal after 15 minutes of inactivity
  useEffect(() => {
    const openAutoLogoutModal = () =>
      setTimeout(() => {
        setWarningModalOpen(true);
      }, 900000);

   // auto logout after 10000ms of opening the modal
    const autoLogoutUser = () =>
      setTimeout(async () => {
        window.location.href = `${settings.URL}`;
        try {
          localStorage.clear();
          document.body.style.backgroundColor = "white";
          await Auth.signOut({ global: true });
        } catch (error) {
          console.log("error signing out: ", error);
          if (error.code === "NotAuthorizedException") {
            Auth.signOut();
          }
        }
      }, 10000);

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
        timeout = openAutoLogoutModal();
      }
    };

    let timeout = isWarningModalOpen ? autoLogoutUser() : openAutoLogoutModal();
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen]);
  return (
    <div>
      {isWarningModalOpen && (
        <TimeoutModal
          isOpen={isWarningModalOpen}
          onRequestClose={() => setWarningModalOpen(false)}
        />
      )}
    </div>
  );
};
