/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Form, Button, Card, Col, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Requests } from "../request/request.js";
import settings from "../../config/config";
import { Spinner } from "reactstrap";
import CustomAlert from "../../components/alert/alert";
import Icons from "../../components/icons/icons";
import { stringify } from "query-string";
import useIsMountedRef from "../../views/helper/isMounted";

const CreateUsers = (props) => {
  const [formData, setFormData] = useState({
    userType: "Customer",
    roleID: "",
    companyID: props.inviteTeamMember
      ? window.localStorage.getItem("companyID")
      : "",
    customerID: "",
    firstName: "",
    lastName: "",
  });
  let token = props.refreshedIdToken;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [spin, setSpin] = useState(false);
  const [validated, setValidated] = useState(false);
  const [roles, setRoles] = useState([]);
  const [userType, setUserType] = useState("Customer");
  const [customerID, setCustomerID] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [xailientID, setXailientID] = useState("");
  const isMountedRef = useIsMountedRef();
  const [getUserTypeForRole, setGetUserTypeForRole] = useState(null);
  const [rolesList1, setRolesList1] = useState([]);
  const [rolesList2, setRolesList2] = useState([]);
  const [rolesList3, setRolesList3] = useState([]);

  const handleChange = (e, userType) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const filterXailientCompanyID = (companies) => {
    companies.map((company) => {
      if (company.name === "Xailient") {
        setXailientID(company.id);
      }
    });
  };

  const setXailientCompanyID = (userType) => {
    if (userType === "Xailient") {
      formData.companyID = xailientID;
      formData.userType = "Xailient";
    } else if (userType === "XailientAdmin") {
      formData.companyID = xailientID;
      formData.userType = "XailientAdmin";
    } else {
      formData.companyID = "";
      formData.userType = "Customer";
    }
  };
  //role type 1
  useEffect(() => {
    Requests(
      settings.role,
      "GET",
      `q=${encodeURIComponent(JSON.stringify({ userType: "Xailient" }))}`,
      "query",
      token
    )
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setRolesList1(data);
        }
      })
      .catch((e) => {
        return null;
      });
  }, [isMountedRef]);

  //role type 2
  useEffect(() => {
    Requests(
      settings.role,
      "GET",
      `q=${encodeURIComponent(JSON.stringify({ userType: "Customer" }))}`,
      "query",
      token
    )
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setRolesList2(data);
        }
      })
      .catch((e) => {
        return null;
      });
  }, [isMountedRef]);

  //role type 3
  useEffect(() => {
    Requests(
      settings.role,
      "GET",
      `q=${encodeURIComponent(JSON.stringify({ userType: "XailientAdmin" }))}`,
      "query",
      token
    )
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setRolesList3(data);
        }
      })
      .catch((e) => {
        return null;
      });
  }, [isMountedRef]);

  //companies
  useEffect(() => {
    Requests(settings.company, "GET", "", "query", token)
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setCompanies(data);
          filterXailientCompanyID(data);
        }
      })
      .catch((e) => {
        return null;
      });
  }, [isMountedRef]);

  const createUserAccount = (userAPI, formData, token) => {
    Requests(userAPI, "POST", JSON.stringify(formData), "event_body", token)
      .then((data) => {
        if (data && data.statusCode !== 400 && !data.message) {
          setSpin(false);
          setValidated(false);
          setSuccess(true);
          resetForm();
          setFormData({
            userType: "",
            roleID: "",
            companyID: "",
            customerID: "",
            firstName: "",
            lastName: "",
          });
        }
        if (data.message) {
          setSpin(false);
          setValidated(false);
          setError("Access Denied! Please contact support@xailient.com");
        }
        if (data.statusCode === 400) {
          setSpin(false);
          setValidated(false);
          setError(
            `An account with Email ${formData.customerID} already exists.`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setSpin(false);
        setValidated(false);
        setError(
          "Oops, Server Error. Please try again later. If error persist please contact support@xailient.com "
        );
      });
  };

  const handleUserTypeChange = (userType) => {
    setUserType(userType);
    setXailientCompanyID(userType);
  };
  const resetForm = () => {
    setUserType("Customer");
  };

  const handleSubmit = (e) => {
    setError(false);
    setSuccess(false);

    e.preventDefault();
    e.stopPropagation();

    setValidated(true);

    if (
      (formData &&
        formData.userType &&
        formData.companyID &&
        formData.customerID &&
        formData.roleID,
      formData.firstName,
      formData.lastName)
    ) {
      setSpin(true);
      var userAPI = settings.user;
      setCustomerID(formData.customerID);
      createUserAccount(userAPI, formData, token);
    } else {
      setValidated(true);
      return;
    }
  };

  let useremail = props.username;
  useEffect(() => {
    Requests(
      settings.user,
      "GET",
      "customerID=" + encodeURIComponent(useremail),
      "query",
      token
    )
      .then((data) => {
        setGetUserTypeForRole(data[0].userType);
      })
      .catch((e) => {
        setError(
          "Oops, Server Error.  Please try again later. If error persist please contact support@xailient.com  "
        );
      });
  });
  return (
    <div>
      <div className="content mt-4">
        <div>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <Card.Title className="custom-card-title">
                  Create New {props.inviteTeamMember ? "Team Member" : "User"}
                </Card.Title>
                <div className="ml-auto d-flex no-block align-items-center">
                  <div className="dl">
                    <Link to={"/viewusers"}>
                      <Button
                        type="submit"
                        id="view-users-btn"
                        variant="primary"
                        className="mb-2"
                      >
                        View {props.inviteTeamMember ? "Team Members" : "Users"}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <hr />
              <Form
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
                id="validate"
              >
                {success && (
                  <CustomAlert
                    message={`Success, An account with Email "${customerID}" has been created successfully.`}
                    theme="success"
                    image={Icons.CheckMark}
                    style={{ textAlign: "inherit" }}
                  />
                )}
                {error && (
                  <CustomAlert
                    message={error}
                    theme="danger"
                    image={Icons.Alert}
                    style={{ textAlign: "inherit" }}
                  />
                )}
                {!props.inviteTeamMember && (
                  <>
                    <Form.Group>
                      <Form.Label>User Type</Form.Label>
                      <Form.Control
                        as="select"
                        required
                        multiple={false}
                        value={userType}
                        onChange={(e) => handleUserTypeChange(e.target.value)}
                        name="userType-users"
                      >
                        <option value="">Choose</option>
                        <option name={"userType"} value="Xailient">
                          Xailient
                        </option>
                        <option name={"userType"} value="Customer">
                          Customers
                        </option>
                        <option name={"userType"} value="XailientAdmin">
                          Xailient Admin
                        </option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="mt-3 mb-3">
                      <Form.Label style={{ color: "#333333" }}>
                        Company Name
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name={"companyID"}
                        required
                        multiple={false}
                        value={formData.companyID}
                        onChange={handleChange}
                        disabled={
                          userType === "Xailient" ||
                          userType === "XailientAdmin"
                        }
                      >
                        <option value="">Select Company</option>
                        {companies.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please enter company name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}

                <Form.Group className="mt-3 mb-3">
                  <Form.Label style={{ color: "#333333" }}>
                    First Name
                  </Form.Label>
                  <Form.Control
                    placeholder="First Name"
                    required
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a first name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-3 mb-3">
                  <Form.Label style={{ color: "#333333" }}>
                    Last Name
                  </Form.Label>
                  <Form.Control
                    placeholder="Last Name"
                    required
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a last name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-3 mb-3" controlId="validateUsername">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder={"Email"}
                    key="email"
                    name="customerID"
                    type="email"
                    pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?" // RFC 5322
                    onChange={handleChange}
                    data-testid="emailInput"
                    value={formData.customerID}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Select Role</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      as="select"
                      name={"roleID"}
                      required
                      multiple={false}
                      value={formData.roleID}
                      onChange={handleChange}
                    >
                      <option>Select Role</option>

                      {formData.userType === "Customer" &&
                        rolesList2.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      {formData.userType === "Xailient" &&
                        rolesList1.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      {formData.userType === "XailientAdmin" &&
                        rolesList3.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                    {!props.inviteTeamMember &&
                    getUserTypeForRole === "XailientAdmin" ? (
                      <InputGroup.Text
                        style={{ cursor: "pointer" }}
                        onClick={() => (window.location.href = "/manageroles")}
                        id="inputGroupPrepend"
                      >
                        Create new role
                      </InputGroup.Text>
                    ) : null}
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please select role.
                  </Form.Control.Feedback>
                </Form.Group>
                <br />

                <Button
                  type="submit"
                  variant="primary"
                  style={{ margin: "0 auto", display: "block" }}
                  id="create-users-btn"
                  disabled={
                    !userType ||
                    !formData.companyID ||
                    !formData.firstName ||
                    !formData.lastName ||
                    !formData.customerID ||
                    !formData.roleID
                  }
                >
                  Submit {""}
                  {spin && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      color="light"
                    />
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CreateUsers;
