/*eslint-disable*/
import React from "react";
import Modal from "react-modal";
import { Button } from "react-bootstrap";
import settings from "../../config/config"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "100%",
    padding: "50px",
    fontSize: "20px",
  },
};

export const TimeoutModal = ({ isOpen, onRequestClose }) => {
  const onLogOffCall = async () => {
    window.location.href = `${settings.URL}`;
    try {
      localStorage.clear();
      document.body.style.backgroundColor = "white";
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log("error signing out: ", error);
      if (error.code === "NotAuthorizedException") {
        Auth.signOut();
      }
    }
  };
  return (
    <div>
      <Modal isOpen={isOpen} 
      style={customStyles}
      >
        <h5>
          Session Timeout
          <br />
          <br />
          <span className="custom-card-text">
            You're being timed out due to inactivity. Please choose to stay
            signed in or to logoff. Otherwise, you will be logged off
            automatically
          </span>
        </h5>
        <br />
        <Button
          variant="primary"
          onClick={() => {
            onLogOffCall();
            onRequestClose();
          }}
        >
          Log off
        </Button>{" "}
        &nbsp;&nbsp;
        <Button onClick={onRequestClose}>Stay Logged In</Button>
      </Modal>
    </div>
  );
};