/*eslint-disable*/
/*
Check whether the user has authorised to access XTP console by fetching user details
*/

import React, { useRef,useEffect, useState } from "react";
import Amplify, { Auth } from "aws-amplify";
import ViewDataset from "../internal_console/dataset/view";
import AddDataset from "../internal_console/dataset/add";
import Label from "../internal_console/dataset/label";
import AWS from "aws-sdk";
import awsconfig from "../../config/aws-exports";
import ViewModel from '../internal_console/model/view';
import ViewSdk from '../internal_console/sdk/view';
import MultiStepModel from '../internal_console/model/train/index.js'
import ViewCustomerSdk from '../customer_dashboard/sdk/view';
import ViewRoles from "./view_roles";
import {ViewUsers} from "./view_users";
import {CreateRoles} from './create_roles'
import CreateUsers from './create_users'
import CreateCompanies from "./create_companies";
import ViewCompanies from './view_companies';
import ViewDevices from "../customer_dashboard/fr_management/device/view";
import {
  Card,
  Image
} from "react-bootstrap";
import Icons from "../../components/icons/icons";
import SwaggerApp from "../../views/swagger/index";
import DeviceDetail from "../customer_dashboard/fr_management/device/detail";
import { FrDetail } from "../customer_dashboard/fr_management/device/frDetail";
import { CreateTeamMember } from "./create_teamMember";
import { ViewTeamMember } from "./view_teamMember";


const VerifyUser = (props) => {
  const [user_details, setUserDetails] = useState(null);
  const [refreshed_id_token, setRefreshedIdToken] = useState("");
  const [path] = useState(props.match.path);
  const [id] = useState(props.match.params.id ? props.match.params.id : null);
  const isSubscribed = useRef(true);
  const REFRESH_INTERVAL = 50 * 60 * 1000;
  const MIN_REFRESH_INTERVAL = 30 * 1000;
  var refreshTimeout;

  const initCredentials = async () => {
    try {
      const credentials = await Auth.currentUserCredentials();
      AWS.config.region = 'us-east-1'
      AWS.config.credentials = credentials;
      AWS.config.correctClockSkew = true;
      AWS.config.httpOptions.timeout = 0;
      return credentials;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    Amplify.configure({
      ...awsconfig,
      authenticationFlowType: "USER_PASSWORD_AUTH",
    });

    Auth.currentAuthenticatedUser()
    .then((user) => {
      if(user && user.signInUserSession.idToken.jwtToken){
      setRefreshedIdToken(user.signInUserSession.idToken.jwtToken)
      setUserDetails(user)
      }
    })
    .catch(() => console.log("Not signed in"));

    if (initCredentials && isSubscribed.current) {
      scheduleCredentialsRefresh();
    }

    return () => {
      isSubscribed.current = false;
      clearTimeout(refreshTimeout);
    };
    // eslint-disable-next-line
  }, []);


  const scheduleCredentialsRefresh = (interval = REFRESH_INTERVAL) => {
    refreshTimeout = setTimeout(async () => {
      try {
      const cognitoUser = await Auth.currentAuthenticatedUser({bypassCache: true});
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        const { idToken} = session;
        if(idToken && idToken.jwtToken){          
          let userIdToken = cognitoUser.signInUserSession.idToken.jwtToken;
          let token =
          userIdToken && (idToken.jwtToken !== userIdToken)
              ? idToken.jwtToken
              : userIdToken;
          setRefreshedIdToken(token)
        }
        if (err && refreshTimeout) {
          scheduleCredentialsRefresh(
            err ? MIN_REFRESH_INTERVAL : REFRESH_INTERVAL
          );
        }
      });
      }catch(e){
        console.log('Unable to refresh Token', e);
        if (refreshTimeout) {
          scheduleCredentialsRefresh(
            e ? MIN_REFRESH_INTERVAL : REFRESH_INTERVAL
          );
        }
      }
    }, interval);
  };

    return (
      <div>
        {user_details && path === "/xailient/dataset" ? (
          <ViewDataset
            {...user_details}
            refreshedIdToken={refreshed_id_token}
          />
        ) : user_details && path === "/xailient/add-dataset" ? (
          <AddDataset {...user_details} refreshedIdToken={refreshed_id_token} />
        ) : user_details && path === "/xailient/label" ? (
          <Label {...user_details} refreshedIdToken={refreshed_id_token} />
        ) : user_details && path === "/xailient/model" ? (
          // <div>View Model</div>
          <ViewModel {...user_details} refreshedIdToken={refreshed_id_token} />
        ) : user_details && path === "/sdk" ? (
          <ViewSdk {...user_details} refreshedIdToken={refreshed_id_token} />
        ) : user_details && path === "/xailient/train" ? (
          // <div>Train New Model</div>
          <MultiStepModel
            {...user_details}
            refreshedIdToken={refreshed_id_token}
          />
        ) : user_details && path === "/customer/sdk/:id" ? (
          // <div>Train New Model</div>
          <ViewCustomerSdk
            {...user_details}
            id={id}
            refreshedIdToken={refreshed_id_token}
          />
        ) : user_details && path === "/manageroles" ? (
          <CreateRoles
            {...user_details}
            id={id}
            refreshedIdToken={refreshed_id_token}
          />
        ) : user_details && path === "/manageusers" ? (
          <CreateUsers
            {...user_details}
            refreshedIdToken={refreshed_id_token}
          />
        ) : user_details && path === "/inviteTeamMember" ? (

          <CreateTeamMember
          {...user_details}
            inviteTeamMember="true"
            refreshedIdToken={refreshed_id_token}
          />
        ) : user_details && path === "/viewusers" ? (
          <ViewUsers viewTeamMembers={window.localStorage.getItem('userType') === "customer" && window.localStorage.getItem('permission').includes('Invite Team Member') && "true"} {...user_details} refreshedIdToken={refreshed_id_token} />
          ): user_details && path === "/viewteamMember" ? (
            <ViewTeamMember
            viewTeamMembers={window.localStorage.getItem('userType') === "customer" && window.localStorage.getItem('permission').includes('Invite Team Member') && "true"} {...user_details} refreshedIdToken={refreshed_id_token}
            />
          ) : user_details && path === "/viewroles" ? (
          <ViewRoles
            {...user_details}
            refreshedIdToken={refreshed_id_token}/>
          ) : user_details && path === "/managecompany" ? (
            <CreateCompanies
              {...user_details}
              refreshedIdToken={refreshed_id_token}
            />
          ) : user_details && path === "/viewcompanies"?(
            <ViewCompanies
              {...user_details}
              refreshedIdToken={refreshed_id_token}/>
            )
            : user_details && path === "/customer/frmanagement" ? (
            <ViewDevices
              {...user_details}
              refreshedIdToken={refreshed_id_token}/>
            ) : path === "/swaggerPage" ? (
              <SwaggerApp
                {...user_details}
                refreshedIdToken={refreshed_id_token}
                />
              )
            : user_details && path === "/customer/device/:id" ? (
            <DeviceDetail
              {...user_details}
              refreshedIdToken={refreshed_id_token}/>
            )  :user_details && path === "/customer/frdetail/:id" ? (
              <FrDetail
                {...user_details}
                refreshedIdToken={refreshed_id_token}/>
              )  : (
          user_details &&
          path === "/dashboard" && (
            <div className="card-body">
              {" "}
              <Card.Title style={{ textAlign: "center", color: "gray" }}>
                <Image
                  style={{
                    margin: "0 auto",
                    display: "block",
                  }}
                  src={Icons.emptyData.toString()}
                  alt=""
                ></Image>
                No results found
              </Card.Title>
            </div>
          )
        )}
      </div>
    );
  
}

export default VerifyUser;
