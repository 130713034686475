const cognitoUrl = 'https://cognito-idp.us-east-1.amazonaws.com/';
let backendEndpoint = 'https://7j00njfixh.execute-api.us-east-1.amazonaws.com/dev';
let network = 'xailient-networks-s3-bucket-orchestrait-dev';
let consoleUrl = 'https://dev-orchestrait.xailient.com';

if (process.env.REACT_APP_STAGE === 'staging') {
  consoleUrl = 'https://staging-orchestrait.xailient.com';
  backendEndpoint = 'https://1a3kh133rd.execute-api.us-east-1.amazonaws.com/staging';
  network = 'xailient-networks-s3-bucket-orchestrait-staging';
}

if (process.env.REACT_APP_STAGE === 'prod') {
  consoleUrl = 'https://orchestrait.xailient.com';
  backendEndpoint = 'https://t3q4ezoo1h.execute-api.us-east-1.amazonaws.com/prod';
  network = 'xailient-networks-s3-bucket-orchestrait-prod';
}

const settings = {
  region: 'us-east-1',
  networkName: `${network}`,
  URL: `${consoleUrl}`,
  updateTokenAPI: `${cognitoUrl}`,
  dataset: `${backendEndpoint}/dataset`,
  freezeDataset: `${backendEndpoint}/freezeDataset`,
  publicDataset: `${backendEndpoint}/publicDataset`,
  freezePublicDataset: `${backendEndpoint}/freezePublicDataset`,
  model: `${backendEndpoint}/model`,
  buildModel: `${backendEndpoint}/buildModel`,
  network: `${backendEndpoint}/network`,
  sharing: `${backendEndpoint}/sharing`,
  releaseSDK: `${backendEndpoint}/releaseSDK`,
  trainNetwork: `${backendEndpoint}/trainNetwork`,
  sdk: `${backendEndpoint}/sdk`,
  buildLegacySdk: `${backendEndpoint}/buildLegacySDK`,
  device: `${backendEndpoint}/device`,
  camera: `${backendEndpoint}/camera`,
  evaluationjob: `${backendEndpoint}/evaluationjob`,
  evaluationresult: `${backendEndpoint}/evaluationResult`,
  role: `${backendEndpoint}/role`,
  permission: `${backendEndpoint}/permission`,
  user: `${backendEndpoint}/user`,
  company: `${backendEndpoint}/company`,
  activateDevice: `${backendEndpoint}/activateDevice`,
  deactivateDevice: `${backendEndpoint}/deactivateDevice`,
  frIdentity: `${backendEndpoint}/frIdentity`,
};

export default settings;
