import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';

function CustomPagination({
  previous,
  next,
  currentPage,
  pagesCount,
  increment,
  size,
}) {
  const customStyle = {
    customNextLink: {
      paddingLeft: '5px',
    },
    customPreviousLink: {
      paddingRight: '5px',
    },
    // customFloat: {
    //   float: "right",
    // },
  };

  return (
    <div style={{ margin: 'auto', whiteSpace: 'pre' }}>
      <ul className={`pagination-${size}`} style={customStyle.customFloat}>
        <Pagination aria-label="Page navigation example">
          <PaginationItem
            style={customStyle.customPreviousLink}
            disabled={currentPage <= 0}
          >
            <PaginationLink onClick={previous} previous href="#" />
          </PaginationItem>
          {increment}
          <PaginationItem
            style={customStyle.customNextLink}
            disabled={currentPage >= pagesCount - 1}
          >
            <PaginationLink onClick={next} next href="#" />
          </PaginationItem>
        </Pagination>
      </ul>
    </div>
  );
}

CustomPagination.propTypes = {
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  increment: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default CustomPagination;
