/*eslint-disable*/
import React, { useState, useEffect,useRef } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Requests } from "../../request/request";
import settings from "../../../config/config";
import { Spinner } from "reactstrap";
import useToken from "../../account/token";

export const DatasetProperties = (props) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noStats, setNoStats] = useState(false);
  const isSubscribed = useRef(false);
  const [generateNewTokenAPI] = useToken();

  const getRefreshedIDToken = async (props) => {
      const refreshedIDToken = await generateNewTokenAPI(props);
      return refreshedIDToken
  };

  const callRefreshToken = (props,isSubscribed) => {
    getRefreshedIDToken(props)
    .then(new_token => {
      getStatData(new_token,isSubscribed);
    })
    .catch(e => {
      console.log(e);
    });
  }


  const getStatData = (new_token,isSubscribed) => {
    let api = settings.dataset;
    let params = "?id=" + props.id;

    Requests(api, "GET", params, "query", new_token)
      .then((data) => {
        console.log(data,'Dataset Properties Response',data[0].status)
        if (data[0].status === "SUCCESS" && !(isSubscribed.current)) {
          setLoading(true);
          setStats(data);
        }else{
          setLoading(false);
          setNoStats(true);
        }
      })
      .catch((err) => {
        console.log(err, "eerr");
        setLoading(false);
        setNoStats(true);
      });
  };

  useEffect(() => {
    if(props){
      callRefreshToken(props,(isSubscribed.current = false))
    }
    return () => (isSubscribed.current = true);
    // eslint-disable-next-line
  }, []);

  let dataset = stats.map((row, i) => row.datasetProperties);
  let instances = dataset.map((row, i) => row.minMaxInstancesPerImage);

  return (
    <div>
      {loading ? (
        <div className="stats-header">
          <Table bordered responsive className="custom_table_style">
            <thead>
              <tr>
                <th>Mean No of Classes Per Image</th>
                <th>Total Number of Classes</th>
                <th>No of Images With Positive Labels</th>
                <th>No of Instances</th>
                <th>Images With All Class (%)</th>
                <th>Images With One Class (%)</th>
                <th>Min/Max/Mean Instances per Image</th>
              </tr>
            </thead>

            <tbody>
              {dataset.map((row, i) => (
                <tr key={i}>
                  <>
                    <td>
                      {Number(row.averageNumberClassesPerImage).toFixed()}
                    </td>
                    <td>{row.numClasses}</td>
                    <td>{row.numImages}</td>
                    <td>{row.numInstances}</td>
                    <td>
                      {Number(row.percentImagesWithAllClass).toFixed(2) + "%"}
                    </td>
                    <td>{Number(row.percentImagesWithOneClass).toFixed(2)}</td>
                    <td>
                      {instances.map((row, i) => (
                        <ul key={i}>
                          <>
                            <p>Max:{row.max}</p>
                            <p>Min:{row.min}</p>
                            <p>Mean: {Number(row.mean).toFixed(2)}</p>
                          </>
                        </ul>
                      ))}
                    </td>
                  </>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : !noStats ? (
        <div className="data-loading">
          <Spinner type="grow" className="statistics-spinner" />
          <span className="spinner-span">
            Please wait... <br /> Your dataset statistics is loading.
          </span>
        </div>
      ) : (
        <Row>
          <Col>
            <Card.Body className="cardBorder">
              <Card.Text className="emptyData">
                No Statistics found ! <br />
                <span className="empty-data-span">
                  {" "}
                  Please update your label file to view the latest Statistics.{" "}
                </span>
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
      )}
    </div>
  );
};
