import React from 'react';

/* Sort function which is reused in AI Models/Datasets/Devices page in both asc and desc order */
const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((b, a) => {
        if (a[sortConfig.key] && b[sortConfig.key] && sortConfig.flag) {
          const dateA = a[sortConfig.key];
          const dateB = b[sortConfig.key];
          const date1 = dateA.split(',')[0];
          const date2 = dateB.split(',')[0];

          const dateCheck1 = date1.split('/').reverse().join();
          const dateCheck2 = date2.split('/').reverse().join();

          if (dateCheck2 < dateCheck1) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          if (dateCheck2 > dateCheck1) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          return 0;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    } else {
      const icon = 'ascendingIcon';
      const direction = 'ascending';
      setSortConfig({ direction, icon });
    }

    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key, flag = false) => {
    let direction = 'descending';
    let icon = 'descendingIcon';
    if (
      sortConfig
      && sortConfig.key === key
      && sortConfig.direction === 'descending'
    ) {
      direction = 'ascending';
      icon = 'ascendingIcon';
    }
    setSortConfig({
      key, direction, icon, flag,
    });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default useSortableData;
