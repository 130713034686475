/* eslint-disable no-unused-vars */
import React, {
  useState, useCallback, useEffect, useRef, Suspense,
} from 'react';
import '../../assets/scss/internal.css';
import { Table, Image } from 'react-bootstrap';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import Checkbox from '../checkbox/checkbox';
import CustomAlert from '../alert/alert';
import Icons from '../icons/icons';
import { TimeStampTodateConverter } from '../timestamp/unix_to_datetime_converter';
import useSortableData from './custom_sort';
import settings from '../../config/config';
import Popup from '../popups/popup';
import { Requests } from '../../views/request/request';
import BuildSDKDialog from '../../views/internal_console/model/sdk/buildSDKDialog';
import Button from '../button/button';

const createHeaders = (headers) => headers.map((item) => ({
  text: item,
  ref: useRef(),
}));

function ResizeableTables({
  minCellWidth,
  data,
  error,
  loading,
  type,
  checked,
  onChange,
  disableCheckbox,
  user,
  tableHeadsObject,
  domainObject,
  showError,
  setshowError,
  selectedRow,
  viewMetrics,
  handleStatisticsDisplay,
  setSelectedRow,
}) {
  // const [tableHeight, setTableHeight] = useState('auto');
  const [activeIndex, setActiveIndex] = useState(null);
  // const tableElement = useRef(null);
  const [modelName, setModelName] = useState(null);
  const headers = Object.keys(tableHeadsObject);
  const columns = createHeaders(headers);
  const [deletePopup, setDeletePopup] = useState(false);
  const [releaseSdkPopup, setReleaseSdkPopup] = useState(false);
  const [buildSDKDialog, setBuildSDKDialog] = useState(false);
  const [spin, setSpin] = useState(false);
  const [itemData, setItemData] = useState({});
  let itemIndex = 0;
  const token = user.signInUserSession && user.signInUserSession.idToken.jwtToken;
  // eslint-disable-next-line no-unused-vars
  const [selectedModels, setSelectedModels] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedItems, setSelectedItems] = useState([]);
  const [successMsg, setSuccessMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  // useEffect(() => {
  //   if (tableElement.current) {
  //     setTableHeight(tableElement.current.offsetHeight);
  //     if (type === 'choose_dataset_table') {
  //       tableElement.current.style.gridTemplateColumns = '2fr 3fr 3fr ';
  //     }
  //   }
  // }, []);

  const mouseDown = (index) => {
    setActiveIndex(index);
    // setTableHeight(tableElement.current.offsetHeight);
  };

  const mouseMove = useCallback(
    (e) => {
      // const gridColumns = columns.map((col, i) => {
      //   if (!tableHeadsObject[col.text]?.active) return;

      //   if (i === activeIndex) {
      //     let extra = 245;
      //     if (document.querySelector('.mini-sidebar')) {
      //       extra = 60;
      //     }
      //     const width = e.clientX - col.ref.current.offsetLeft - extra;
      //     // setTableHeight(tableElement.current.offsetHeight);

      //     if (width >= minCellWidth) {
      //       return `${width}px`;
      //     }
      //   }
      //   return `${col.ref.current.offsetWidth}px`;
      // });

      // tableElement.current.style.gridTemplateColumns = `${gridColumns.join(
      //   ' ',
      // )}`;
      // tableElement.current.style.width = '100vw';
    },
    [activeIndex, columns, minCellWidth],
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  useEffect(() => {
    mouseMove();
  }, [tableHeadsObject]);

  // for sorting
  const { items, requestSort, sortConfig } = useSortableData(data);

  function getClassNamesFor(value) {
    if (!sortConfig) {
      return;
    }
    return (sortConfig.key === value) ? sortConfig.direction : undefined;
  }

  function handleDeletePopup(modelData, index) {
    setItemData(modelData);
    setDeletePopup(true);
    itemIndex = index;
  }

  function handleReleaseSDK(e, sdkData) {
    e.preventDefault();
    setErrorMsg(null);
    setSuccessMsg(null);
    setSpin(false);
    setItemData(null);
    setReleaseSdkPopup(true);
    setItemData(sdkData);
  }

  function PublishSdktoCustomers(e, request) {
    e.preventDefault();
    setSpin(true);
    setErrorMsg(null);
    setSuccessMsg(null);
    const url = settings.releaseSDK;
    Requests(url, 'POST', JSON.stringify({ sdkID: request.id, sdkVersion: request.version }), 'event_body', token)
      .then((res) => {
        console.log(res, 'response');
        if (res) {
          setSpin(false);
          setSuccessMsg('SDK has been released successfully');
          setErrorMsg(null);
        } else {
          setSpin(false);
          setSuccessMsg('');
          setErrorMsg('Internal Server Error, Please try again later!');
        }
      })
      .catch((err) => {
        console.log('Unable to release SDK: ', err);
        setSpin(false);
        setErrorMsg('Internal Server Error, Please try again later!');
        return null;
      });
  }

  /* A model has an array of networks and this function generates an URL with all
  the network IDs and versions as query parameters */
  function generateNetworkParams(modelData) {
    const { networks } = modelData;
    let params = '';
    networks.forEach((network) => {
      params = `${params}id=${network[0]}&version=${network[1]}&`;
    });
    return `?${params}`;
  }

  // generic delete function for any item that needs to get deleted from the table
  function deleteItem(url, params) {
    setSpin(true);
    items.splice(itemIndex, 1);
    Requests(url, 'DELETE', params, 'query', token)
      .then((res) => {
        if (res) {
          setSpin(false);
          setDeletePopup(false);
        }
      })
      .catch((err) => {
        console.log('Cannot delete item: ', err);
        setDeletePopup(false);
        setSpin(false);
      });
  }

  // networks need to be deleted before deleting the model
  function deleteNetwork(networkUrl, networkParams, modelUrl, modelParams) {
    setSpin(true);
    Requests(networkUrl, 'DELETE', networkParams, 'query', token)
      .then(() => {
        deleteItem(modelUrl, modelParams);
      })
      .catch(() => {});
  }

  // a function to handle the deletion process based on the domain object eg: model, dataset
  function handleDeleteSubmit(modelData) {
    if (domainObject === 'model') {
      const modelUrl = settings.model;
      const modelParams = `?id=${modelData.id}&version=${modelData.version}`;
      const networkUrl = settings.network;
      const networkParams = generateNetworkParams(modelData);
      deleteNetwork(networkUrl, networkParams, modelUrl, modelParams);
    } else if (domainObject === 'dataset') {
    //  call the deleteItem function by passing the delete dataset API and dataset data
      const datasetApi = settings.dataset;
      const datasetParams = `?id=${modelData.id}`;
      deleteItem(datasetApi, datasetParams);
    } else if (domainObject === 'sdk') {
      const sdkUrl = settings.sdk;
      const sdkParams = `?id=${modelData.id}&version=${modelData.version}`;

      deleteItem(sdkUrl, sdkParams);
    }
  }

  // for displaying error details

  const handlerrorDetails = (row) => {
    if (showError && row.name === showError.name) {
      setshowError(null);
    } else if (row) {
      setshowError(row);
    } else {
      setshowError(null);
    }
  };
  // const handleSelectedButton = () => {
  //   if (showError || selectedRow) {
  //     setshowError(null);
  //   } else {
  //     setshowError(showError);
  //   }
  // };

  // displaying metrics
  const handleCheckboxChecked = (e, tableItem) => {
    if (e.target.checked) {
      selectedItems.push(tableItem);
    } else {
      selectedItems.forEach((list, index) => {
        if (list === tableItem) {
          selectedItems.splice(index, 1);
        }
      });
    }

    if (selectedItems.length === 1) {
      setSelectedRow(selectedItems[0]);
      handleStatisticsDisplay(
        selectedItems[0].id,
        selectedItems[0].name ? selectedItems[0].name : '',
        selectedItems[0].version,
        selectedItems[0].tags,
        selectedItems[0].notes,
        selectedItems[0],
      );
    } else {
      setSelectedRow(null);
    }
  };

  function populateSelectedItems(e, tableItem) {
    if (domainObject === 'model') {
      if (e.target.checked) {
        selectedModels.push(tableItem.id);
      } else {
        selectedModels.forEach((modelID, index) => {
          if (modelID === tableItem.id) {
            selectedModels.splice(index, 1);
          }
        });
      }
      viewMetrics(selectedModels, tableItem);
    }
  }

  return (
    <div>
      <Table className="table">
        <thead>
          <tr>
            {columns.map(
              ({ ref, text }, i) => tableHeadsObject[text].active && (
              <th
                ref={ref}
                key={text}
                onClick={() => requestSort(text)}
                className={getClassNamesFor(text)}
              >
                <span>
                  {text ? text[0].toUpperCase() + text.slice(1) : ''}
                  {text !== 'Select'
                        && text !== 'Delete'
                        && text !== 'UpdateLabel'
                        && text !== 'Stats' && (
                          <Image
                            src={
                              sortConfig && sortConfig.icon === 'ascendingIcon'
                                ? Icons.SortDescending.toString()
                                : Icons.SortAscending.toString()
                            }
                            alt="sort"
                            className="sorting-icon"
                          />
                  )}
                </span>
                <div
                  // style={{ height: tableHeight }}
                  onMouseDown={() => mouseDown(i)}
                  className={`resize-handle ${
                    activeIndex === i ? 'active' : 'idle'
                  }`}
                />
              </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {loading
            && items.map((tableItem, index) => (
              <tr key={index}>
                {headers.map(
                  (tableHeader, i) => tableHeadsObject[tableHeader]?.active && (
                  <td className="text-overflow" key={i}>
                    {tableHeader === 'id' ? (
                      <Button size="sm" variant="link">
                        {tableItem.id}
                      </Button>
                    ) : tableHeader === 'creationDate' ? (
                      TimeStampTodateConverter(tableItem.creationDate)
                    ) : tableHeader === 'Select'
                          && (type === 'choose_dataset_table') ? (
                            <Checkbox
                              name={tableItem.id}
                              checked={checked[tableItem.id]}
                              onChange={(e) => onChange(e, 'Custom', tableItem)}
                              disabled={!!disableCheckbox}
                            />
                      ) : tableHeader === 'Select' ? (
                        <Checkbox
                          name={tableItem.id}
                          disabled={
                              !!(selectedRow
                              && selectedRow.name !== tableItem.name)
                            }
                          onChange={(e) => {
                            if (domainObject === 'model') {
                              populateSelectedItems(e, tableItem);
                              return;
                            }

                            handleCheckboxChecked(e, tableItem);
                            if (
                              !showError
                                || (showError
                                  && showError.name !== tableItem.name)
                            ) {
                              handlerrorDetails(tableItem);
                            }
                          }}
                        />
                      ) : tableHeader === 'Delete' ? (
                        <Button
                          name={tableItem.id}
                          size="sm"
                          variant="light"
                          onClick={() => handleDeletePopup(tableItem, index)}
                        >
                          <i title={`delete_${tableItem.id}`} className="fas fa-trash-alt" />
                        </Button>
                      ) : tableHeader === 'buildInfo' ? (
                        <ul>
                          {Object.keys(tableItem.buildInfo).map((val) => (
                            <li key={val}>{val}</li>
                          ))}
                        </ul>
                      ) : tableHeader === 'Models' ? (
                        <span>
                          {tableItem.models.map((model) => {
                            const params = `id=${model[0]}&version=${model[1]}`;
                            Requests(
                              settings.model,
                              'GET',
                              params,
                              'query',
                              token,
                            ).then((response) => {
                              if (
                                response
                                  && response[0].name
                                  && response[0]
                              ) {
                                setModelName(response[0].name);
                              } else {
                                setModelName(null);
                                return;
                              }
                              if (
                                response.message
                                  === 'The incoming token has expired'
                              ) {
                                window.location.reload(true);
                              }
                            });
                            return (
                              <div key={model} id="model">
                                {modelName}
                              </div>
                            );
                          })}
                        </span>
                      ) : tableHeader === 'Publish'
                          && domainObject === 'sdk' ? (
                            <Button
                              size="sm"
                              disabled={tableItem.status !== 'FAILURE' && (tableItem.isShared === true || successMsg === 'SDK has been released successfully') ? 'true' : tableItem.status !== 'FAILURE' ? 'true' : 'false'}
                              variant={tableItem.status !== 'FAILURE' && (tableItem.isShared === true || successMsg === 'SDK has been released successfully') ? 'success' : tableItem.status !== 'FAILURE' ? 'warning' : ''}
                              // eslint-disable-next-line max-len
                              onClick={(e) => ((tableItem.isShared !== true && tableItem.status !== 'FAILURE' && successMsg !== 'SDK has been released successfully') ? handleReleaseSDK(e, tableItem) : null)}
                            >
                              {tableItem.isShared === true ? 'SDK Released' : (tableItem.status !== 'FAILURE' && successMsg === 'SDK has been released successfully') ? 'SDK Released' : (tableItem.status !== 'FAILURE' && tableItem.isShared !== true) ? 'Publish SDK' : null}
                            </Button>
                        ) : tableHeader === 'status'
                          && domainObject !== 'model' ? (
                            <span
                              style={{
                                color:
                                tableItem.status === 'SUCCESS'
                                  ? '#1d8102'
                                  : '#687078',
                              }}
                            >
                              <i
                                className={
                                  tableItem.status === 'SUCCESS'
                                    ? 'fas fa-check-circle'
                                    : 'fas fa-minus-circle'
                              }
                              />
                              {' '}
                              {tableItem.status.charAt(0).toUpperCase()
                              + tableItem.status.slice(1).toLowerCase()}
                              {/* {
                                !showError ||
                                (showError &&
                                  showError.name !== tableItem.name) ? (
                                  <i
                                    className="fas fa-info-circle status-info icon-color"
                                    onClick={() =>
                                      handlerrorDetails(tableItem)
                                    }
                                  ></i>
                                ) : (selectedRow &&
                                    selectedRow.name === tableItem.name) ||
                                  showError ? (
                                  <i
                                    className="fas fa-info-circle status-info icon-color"
                                    onClick={() =>
                                      handleSelectedButton(tableItem)
                                    }
                                  ></i>
                                ) : null
                              } */}
                            </span>
                          ) : tableHeader === 'UpdateLabel'
                          && domainObject !== 'model' ? (
                            <Image
                              aria-hidden="true"
                              src={Icons.UploadIcon.toString()}
                              alt={Icons.UploadIcon.toString()}
                              onClick={() => {
                                const datasetName = tableItem.name;
                                const datasetId = tableItem.id;

                                window.location.href = `${
                                  '/internal/label/'`${datasetId}/`
                                }${datasetName}`;
                              }}
                              className="cardIcon center"
                            />
                            ) : tableItem[tableHeader] ? (
                              tableItem[tableHeader]
                            ) : tableHeader === 'version' ? (
                              tableItem[tableHeader]
                            ) : (
                              '-'
                            )}
                  </td>
                  ),
                )}
              </tr>
            ))}
        </tbody>
      </Table>
      {!error && !loading ? (
        <Spinner size="sm" color="warning" className="center" />
      ) : (
        error && (
          <CustomAlert message={error} theme="info" image={Icons.WarningRed.toString()} />
        )
      )}
      {deletePopup && (
        <Suspense fallback="Loading">
          <Popup
            spin={spin}
            action={() => handleDeleteSubmit(itemData)}
            show={deletePopup}
            hide={() => setDeletePopup(false)}
            modal_title="Delete Confirm"
            modal_body={`Are you sure you want to delete this ${
              itemData.name ? itemData.name : itemData.id
            } ${domainObject} and all of its content ? This action cannot be undone.`}
            button_variant="danger"
            button_label="Delete"
            button_icon="fas fa-trash-alt"
          />
        </Suspense>
      )}
      {releaseSdkPopup && (
        <Suspense fallback="Loading">
          <Popup
            spin={spin}
            action={(e) => PublishSdktoCustomers(e, itemData)}
            show={releaseSdkPopup}
            hide={() => {
              setReleaseSdkPopup(false);
              setErrorMsg(null);
              setSpin(false);
              setItemData(null);
            }}
            modal_title="Confirm"
            modal_body={`The following SDK ${itemData.id} will be published to the branded customer
             who has entitled to this platform.`}
            button_variant="success"
            button_label="Release SDK"
            successMsg={successMsg}
            errorMsg={errorMsg}
          />
        </Suspense>
      )}

      {buildSDKDialog && (
        <BuildSDKDialog
          show={buildSDKDialog}
          onHide={() => setBuildSDKDialog(false)}
          model_name={itemData.name}
          token={token}
        />
      )}
    </div>
  );
}

ResizeableTables.propTypes = {
  minCellWidth: PropTypes.number.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  loading: PropTypes.bool,
  type: PropTypes.string,
  checked: PropTypes.string,
  onChange: PropTypes.string,
  disableCheckbox: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tableHeadsObject: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  user: PropTypes.oneOfType([PropTypes.object]),
  domainObject: PropTypes.string,
  showError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedRow: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  viewMetrics: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]),
  setshowError: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  handleStatisticsDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  setSelectedRow: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
ResizeableTables.defaultProps = {
  disableCheckbox: false,
  data: '',
  error: '',
  type: '',
  loading: '',
  checked: '',
  onChange: '',
  user: {},
  tableHeadsObject: '',
  domainObject: '',
  showError: '',
  selectedRow: '',
  viewMetrics: '',
  setshowError: '',
  handleStatisticsDisplay: '',
  setSelectedRow: '',
};

export default ResizeableTables;
