/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import {
  Modal, Button, Card,
} from 'react-bootstrap';
import { Spinner } from 'reactstrap';

// A generic popup that can be used for displaying all types of popups.

function Popup(props) {
  const properties = props;
  return (
    <Modal
      size="md"
      show={properties.show}
      onHide={properties.hide}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="delete" closeButton>
        <Modal.Title style={{ fontWeight: 'normal', margin: 0 }}>
          {properties.errorMsg
            ? 'Error'
            : properties.successMsg
              ? 'Success'
              : properties.modal_title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card.Text>
          {properties.successMsg
            ? properties.successMsg
            : properties.errorMsg
              ? properties.errorMsg
              : properties.modal_body}
        </Card.Text>
      </Modal.Body>
      {!properties.successMsg && (
        <Modal.Footer>
          {!properties.errorMsg && (
            <Button variant="light" onClick={properties.hide}>
              Cancel
            </Button>
          )}
          {!properties.errorMsg && (
            <Button
              variant={properties.button_variant}
              onClick={properties.action}
            >
              {properties.button_label}

              {properties.spin ? (
                <Spinner
                  size="sm"
                  style={{ color: 'white', textAlign: 'center' }}
                />
              ) : (
                <span>
                  <i
                    className={properties.button_icon}
                    style={{ color: 'white', textAlign: 'center' }}
                  />
                </span>
              )}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default Popup;
