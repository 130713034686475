import React from 'react';
import EdiText from 'react-editext';
import settings from '../../config/config';
import { Requests } from '../../views/request/request';

export default function SaveNotes(user) {
  const {
    token, id, version, notes, domainObject,
  } = user;
  const saveNotes = (val) => {
    let addFormNotesAPI = null;
    if (domainObject === 'model') {
      addFormNotesAPI = settings.model;
    } else if (domainObject === 'dataset') {
      addFormNotesAPI = settings.dataset;
    } else if (domainObject === 'sdk') {
      addFormNotesAPI = settings.sdk;
    }
    const param = `?id=${id}&version=${version}`;

    Requests(
      addFormNotesAPI,
      'PATCH',
      JSON.stringify({ notes: val }),
      'event_body_params',
      token,
      param,
    );
  };

  return (
    <div key={id} style={{ overflowY: 'auto', height: '48px', overflowX: 'hidden' }}>
      <EdiText
        id={id}
        value={notes}
        onSave={(e) => saveNotes(e, id)}
      />
    </div>
  );
}
