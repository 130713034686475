/*eslint-disable*/
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  Row, Container, Col, Button, Form,
} from 'react-bootstrap';
import settings from '../../../../config/config';
import { Requests } from '../../../request/request';

function BuildSDKDialog(props) {
  const [openVino, setOpenVino] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(false);
  const platforms = [
    {
      name: 'x86-64', value: 'x86_64', disabled: false, showOpenVino: true,
    },
    {
      name: 'ARM32v7(e.g. Raspberry Pi 4)', value: 'ARM32v7', disabled: false, showOpenVino: true,
    },
    {
      name: 'ARM32v6(e.g. Raspberry Pi Zero)', value: 'ARM32v6', disabled: false, showOpenVino: false,
    },
    {
      name: 'ARM64', value: 'ARM64', disabled: false, showOpenVino: true,
    },
    {
      name: 'CORAL', value: 'CORAL', disabled: true, showOpenVino: false,
    },
    {
      name: 'MOVIDIUS', value: 'MOVIDIUS', disabled: true, showOpenVino: false,
    },
    {
      name: 'ANDROID', value: 'ANDROID', disabled: true, showOpenVino: false,
    },
    {
      name: 'iOS', value: 'iOS', disabled: true, showOpenVino: false,
    },

  ];

  function handleBuildChange(selectedPlatform) {
    setSelectedPlatform(selectedPlatform);
  }

  function buildSDK() {
    const body = {};
    // Requests(network_url, "POST", network_params, "event_body", props.token)
    //     .then(() => {
    //         deleteItem(model_url, model_params);
    //     })
    //     .catch((err) => {
    //     });
  }
  return (
    <Modal show={props.show} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Build SDK
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div>
            <p className="text-center">
              {' '}
              {props.model_name}
            </p>
          </div>
          <Form>
            <Form.Group>
              <Row>
                {platforms.map((platform, index) => (
                  <Col xs={12} className="m-2">
                      <input
                          type="radio"
                          name="platforms"
                          value={platform.value}
                          onChange={(event) => handleBuildChange(event.target.value)}
                          disabled={platform.disabled}
                        />
                      <Form.Check.Label className="ml-2">
                          {platform.name}
                        </Form.Check.Label>
                      {selectedPlatform === platform.value && platform.showOpenVino && (
                          <div>
                            <ul className="pl-4">
                                  <input
                                    type="checkbox"
                                    onChange={(event) => {
                                          setOpenVino(event.target.checked);
                                        }}
                                  />
                                  <Form.Check.Label className="ml-2 font-14">
                                    Optimize for OpenVINO
                                        <span>&trade;</span>
                                  </Form.Check.Label>
                                  <div className="font-14">
                                    <ul>
                                          <li className="mb-0">Inference time 6-10x faster.</li>
                                          <li className="mb-0">
                                            SDK will be &#xB1;15MB larger in
                                            size.
</li>
                                          <li>
                                            Select this option unless you want the smallest SDK
                                            possible.
</li>
                                        </ul>
                                  </div>
                                </ul>
                          </div>
                        )}
                    </Col>
                ))}
              </Row>
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={props.onHide}>Cancel</Button>
        <Button variant="outline-primary" onClick={() => buildSDK()}>Build</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BuildSDKDialog;
