/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Card,
  Button,
  Spinner,
  Form,
  Modal,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Requests } from "../request/request";
import settings from "../../config/config";
import Icons from "../../components/icons/icons";
import CustomPagination from "../../components/pagination/pagination";
import CustomAlert from "../../components/alert/alert";

function ViewCompanies(props) {
  const { refreshedIdToken } = props;
  const [token] = useState(refreshedIdToken);
  const [companyItems, setCompanyItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [spin, setSpin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 6;
  const currentData = companyItems.slice(
    currentPage * dataPerPage,
    (currentPage + 1) * dataPerPage
  );
  const [show, setShow] = useState(false);
  const handleClose = () => {setError(false);setShow(false);}
  const handleShow = () => {setSuccess(false); setShow(true);}

  useEffect(() => {
    Requests(settings.company, "GET", "", "query", token)
      .then((data) => {
        if (data && !data.status && !data.message) {
          setCompanyItems(data);
          setError(null);
          setLoading(false);
        }
        if(data.message){
          setCompanyItems([]);
          setLoading(false);
          setError(data.message)
        }
      })
      .catch((error) => {
        setCompanyItems([]);
        setLoading(false);
        setError(
          "Oops, Server Error. Please try again later. If error persist please contact support@xailient.com "
        );
      });
  }, []);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }
  const [newvalue, setnewvalue] = useState([]);

  const editCompany = (id) => {
    handleShow();
    let value = companyItems.filter((user) => user.id === id)[0];
    setnewvalue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setnewvalue({
      ...newvalue,
      [name]: value,
    });
  };

  let description = newvalue.details
    ? Object.values(newvalue.details).join("").toString()
    : "";

  const handleSubmit = (e) => {
    setSpin(true);
    e.preventDefault();
    e.stopPropagation();
    let param = `id=${newvalue.id}`;
    Requests(
      settings.company,
      "PATCH",
      JSON.stringify({
        name: newvalue.name,
        details: { description: description },
      }),
      "event_body_params",
      token,
      param
    )
      .then((data) => {
        if (data  && data.statusCode !== 400) {
          setSuccess(true);
          setSpin(false);
          const updatedCompany = companyItems.map((item, i) => {
            if (item.id == data.id) {
              return data;
            } else {
              return item;
            }
          });
          setCompanyItems(updatedCompany);
        }
        if (data.statusCode === 400) {
            setSpin(false);
            setError(`Company already exists`);
          }
      })

      .catch((err) => {
        setError(
          "Oops, Server Error. Please try again later. If error persist please contact support@xailient.com "
        );
        setSpin(false);
      });
  };

  const [getUserType, setgetUserType] = useState(null)
  let customerID = props.username
  Requests(settings.user, "GET", "customerID="+encodeURIComponent(customerID), "query", token)
  .then((data)=>{
    setgetUserType[data[0].userType]
  })
  .catch((e)=>{
    setError(
      "Oops, Server Error.  Please try again later. If error persist please contact support@xailient.com  "
    );
  })


  return (
    <div className="content mt-4">
      <Card>
        <Card.Body style={{ overflow: "auto" }}>
          <div className="d-flex align-items-center">
            <Card.Title className="custom-card-title">
              View Companies ({companyItems.length})
            </Card.Title>
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Link to="/managecompany">
                  <Button
                    type="submit"
                    id="manage-users-btn"
                    variant="primary"
                    className="mb-2"
                  >
                    Create new company{" "}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <Table
            borderless
            size="md"
            className="border-top"
            id="view-users-table"
          >
            <thead className="font-14">
              <tr className="border-bottom">
                <th className="white-header pl-5 column-width">ID</th>
                <th className="white-header">Company Name</th>
                <th className="white-header">Details</th>
                <th className="white-header">Edit</th>
                {getUserType === "XailientAdmin"? <th className="white-header">Delete</th> : null}
              </tr>
            </thead>
            <tbody>
              {currentData.map((user, index) => (
                <tr className="font-14 border-bottom" key={index}>
                  <td className="pl-5">{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.details && user.details.description}</td>

                  <td>
                    <span>
                      <img
                        alt="Edit"
                        className="icon-button"
                        onClick={() => editCompany(user.id)}
                        src={Icons.Edit}
                      />
                    </span>
                  </td>
                  {getUserType === "XailientAdmin"?  <td>
                    <span>
                      <img
                        alt="Delete"
                        className="icon-button"
                        src={Icons.Trash}
                      />
                    </span>
                  </td> : null}
                </tr>
              ))}
            </tbody>
          </Table>
          {error && (
            <CustomAlert
              message={error}
              theme="danger"
              image={Icons.Alert}
              style={{ textAlign: "inherit" }}
            />
          )}

          {loading && (
            <div className="text-center">
              <Spinner style={{ color: "#705AF3" }} animation="grow" />
            </div>
          )}
        </Card.Body>
        <CustomPagination
          size="lg"
          previous={() => paginate(currentPage - 1)}
          next={() => paginate(currentPage + 1)}
          currentPage={currentPage}
          increment={`${currentPage + 1} of ${Math.ceil(
            companyItems.length / dataPerPage
          )}`}
          pagesCount={Math.ceil(companyItems.length / dataPerPage)}
        />
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h4>Edit company</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success && (
            <CustomAlert
              message={`Success, Edited successfully.`}
              theme="success"
              image={Icons.CheckMark}
              style={{ textAlign: "inherit" }}
            />
          )}

          {error && (
            <CustomAlert
              message={error}
              theme="danger"
              image={Icons.Alert}
              style={{ textAlign: "inherit" }}
            />
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mt-3 mb-3">
              <Form.Label style={{ color: "#333333" }}>
                Company Name *
              </Form.Label>
              <Form.Control
                placeholder="Company Name"
                required
                name="name"
                value={newvalue.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mt-3 mb-3">
              <Form.Label style={{ color: "#333333" }}>
                Details (Optional)
              </Form.Label>
              <Form.Control
                placeholder="Company Details"
                name="details"
                value={description}
                onChange={handleChange}
              />
            </Form.Group>

            <Modal.Footer>
              <Button
                type="submit"
                variant="primary"
                style={{ margin: "0 auto", display: "block" }}
                id="edit-company-btn"
              >
                Submit{" "}
                {spin && (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    color="light"
                  />
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

ViewCompanies.propTypes = {
  refreshedIdToken: PropTypes.string,
};

ViewCompanies.defaultProps = {
  refreshedIdToken: "",
};

export default ViewCompanies;
