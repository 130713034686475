import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';

function CustomProgressBar({ isProgress }) {
  return (
    <div>
      <ProgressBar striped now={isProgress} label={`${isProgress}%`} />
      {/* <div className="text-center">{isProgress} of 100%</div> */}
    </div>
  );
}

CustomProgressBar.propTypes = {
  isProgress: PropTypes.number.isRequired,
};

export default CustomProgressBar;
