let identityPoolId = 'us-east-1:791379ed-1e99-462b-b43e-5d497107c228';
let userPoolId = 'us-east-1_YmMQytB5N';
let userPoolWebClientId = '3mgdj7tlmm98eeiftg8fnlopuv';

if (process.env.REACT_APP_STAGE === 'staging') {
  identityPoolId = 'us-east-1:b43eb1af-1258-4b4a-af9b-36a9a59c896f';
  userPoolId = 'us-east-1_OIvu5xXVk';
  userPoolWebClientId = '3slmuvl5fonugamvp8bnc62sb4';
}

if (process.env.REACT_APP_STAGE === 'prod') {
  identityPoolId = 'us-east-1:598ee3d9-2e60-4cb8-892f-6074822b461d';
  userPoolId = 'us-east-1_DCx68xe4T';
  userPoolWebClientId = '2pfmpqji0oprdrgfhkrja4gg3t';
}

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    `${identityPoolId}`,
  region: 'us-east-1',
  identityPoolRegion: 'us-east-1',
  userPoolId: `${userPoolId}`,
  userPoolWebClientId: `${userPoolWebClientId}`,
  mandatorySignIn: false,
  Auth: {
    region: 'us-east-1',
    identityPoolRegion: 'us-east-1',
    userPoolId: `${userPoolId}`,
    userPoolWebClientId: `${userPoolWebClientId}`,
    mandatorySignIn: false,
  },
};

export default awsmobile;
