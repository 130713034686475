import React, { useState } from 'react';
import TagsInput from 'react-tagsinput';
import settings from '../../config/config';
import { Requests } from '../../views/request/request';

export default function AddTag(user) {
  const {
    token, id, version, tags, domainObject,
  } = user;
  const [tag, setTags] = useState([]);

  React.useEffect(() => {
    if (!tags) {
      setTags([]);
    } else {
      setTags(tags);
    }
  }, [tags]);

  const saveTags = (val) => {
    let addTagAPI = null;
    if (domainObject === 'dataset') {
      addTagAPI = settings.dataset;
    } else if (domainObject === 'model') {
      addTagAPI = settings.model;
    } else if (domainObject === 'sdk') {
      addTagAPI = settings.sdk;
    }
    const param = `?id=${id}&version=${version}`;

    Requests(
      addTagAPI,
      'PATCH',
      JSON.stringify({ tags: val }),
      'event_body_params',
      token,
      param,
    );
    // localStorage.setItem("tags", JSON.stringify(val));
  };

  const handleTagChange = (val) => {
    setTags(val);
    if (val) {
      saveTags(val);
    }
  };

  return (
    <div key={id} style={{ overflowY: 'auto', height: '52px', overflowX: 'hidden' }}>
      <TagsInput
        value={tag}
        onChange={(e) => handleTagChange(e)}
        inputProps={{ placeholder: 'Add a tag' }}
      />
    </div>
  );
}
