/*eslint-disable*/
import { useState,useEffect, useRef} from "react";

const useDatasetApiRequest = (url,token) => {
    const [dataset_items, setDatasetItems] = useState([]);
    const [is_dataset_loaded, setIsDatasetLoaded] = useState(false);
    const [dataset_error, setDatasetError] = useState(null);
    const isMount = useRef(true);

    const fetchData = async (isMount) => {
      try {
        const res = await fetch(`${url}?fetch=all`, {
          method: "GET",
          headers: {
            Accept: "*",
            Authorization: token,
          },
        });
        // if (!res.ok) {
        //   setIsDatasetLoaded(false);
        //   setDatasetError("There are no available datasets yet.");
        //   return;
        // }
        const response = await res.json();

        if (response && !isMount.current) {
            console.log('Dataset API Response',response)
          // const sanity_status_filter = ['SANITY_CHECK_COMPLETE','ANALYSIS_COMPLETE','SANITY_CHECK_PARTIAL_SUCCESS','SANITY_CHECK_USER_APPROVED']

          // const filtered_datasets = response.filter(
          //   (val) => sanity_status_filter.indexOf(val.state) !== -1
          // );
          
          setIsDatasetLoaded(true);
          setDatasetItems(response);
        }
          // if(response.message === 'The incoming token has expired'){
          //   fetchData(token)
          // }
        } catch (error) {
          setIsDatasetLoaded(false);
          setDatasetError("There are no available datasets yet.");
        } 
    };

    useEffect(() => {
        fetchData(isMount.current = true);
      return () => {
        isMount.current = false;
      };
      // eslint-disable-next-line
    }, []);
  
    return { dataset_error, is_dataset_loaded, dataset_items };
  };

export default useDatasetApiRequest;