/*eslint-disable*/
import settings from "../../config/config";
import awsmobile from "../../config/aws-exports";

const useToken = () => {
  function generateNewTokenAPI(props) {
    var tokenURL = settings.updateTokenAPI;
    var clientId = awsmobile.userPoolWebClientId;
    
    if(props.signInUserSession && props.signInUserSession.refreshToken && props.signInUserSession.refreshToken.token){
      var refreshToken = props.signInUserSession.refreshToken.token;
    return new Promise((resolve, reject) => {
      fetch(tokenURL, {
        headers: {
          "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
          "Content-Type": "application/x-amz-json-1.1",
        },
        mode: "cors",
        cache: "no-cache",
        method: "POST",
        body: JSON.stringify({
          ClientId: clientId,
          AuthFlow: "REFRESH_TOKEN_AUTH",
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
          },
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log("Token Updated");
          if (
            data &&
            data.AuthenticationResult &&
            data.AuthenticationResult.IdToken
          ) {
            var idToken = data.AuthenticationResult.IdToken;
            resolve(idToken);
          }
          if (data.message === "Rate exceeded") {
            window.location.reload();
            return;
          }
        })
        .catch((err) => {
          console.log("Error updating the token: ", err);
          return reject(err);
        });
    });
  }
  }

  return [generateNewTokenAPI];
};

export default useToken;
