/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardGroup } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { Spinner } from "reactstrap";
import Plot from "react-plotly.js";
import settings from "../../../config/config";
import { Requests } from "../../request/request";
import useToken from "../../account/token";

export const GeometricProperties = (props) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noStats, setNoStats] = useState(false);
  const isSubscribed = useRef(false);

  function range(start, end) {
    var myArray = [];
    for (var i = start; i <= end; i++) {
      myArray.push(Number(i).toFixed(2));
    }
    return myArray;
  }

  const [generateNewTokenAPI] = useToken();

  const getRefreshedIDToken = async (props) => {
    const refreshedIDToken = await generateNewTokenAPI(props);
    return refreshedIDToken;
  };

  const callRefreshToken = (props,isSubscribed) => {
    getRefreshedIDToken(props)
      .then((new_token) => {
        getStatData(new_token,isSubscribed);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getStatData = (new_token,isSubscribed) => {
    let api = settings.dataset;
    let params = "?id=" + props.id;
    Requests(api, "GET", params, "query", new_token)
      .then((data) => {
        console.log(data,'Geometric Status',data[0].status)
        if (data[0].status === "SUCCESS" && !(isSubscribed.current)) {
          setLoading(true);
          setStats(data);
        }else{
          setLoading(false);
          setNoStats(true);
        }
      })
      .catch((err) => {
        console.log(err, "eerr");
        setLoading(false);
        setNoStats(true);
      });
  };

  useEffect(() => {
    if (props) {
      callRefreshToken(props,(isSubscribed.current = false));
    }
    return () => (isSubscribed.current = true);
    // eslint-disable-next-line
  }, []);

  let datasetProperties = stats.map((row, i) => row.datasetProperties);
  let geometricProperties = stats.map((row, i) => row.geometricProperties);
  let photometricProperties = stats.map((row, i) => row.photometricProperties);

  const [selected, setSelected] = useState("");

  function handleLogChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setSelected(value);
  }

  return (
    <div>
      {loading ? (
        <div>
          {(datasetProperties.length > 0) &&
            datasetProperties.map((val, i) => {
              var instancePlot = [["Classes", "Count"]];
              if (!val.numInstancesPerClass) {
                instancePlot.push([]);
                return (
                  <Row key={i}>
                    <Col>
                      <Card.Body className="cardBorder">
                        <Card.Text className="emptyData">
                          No Statistics found ! <br />
                        </Card.Text>
                      </Card.Body>
                    </Col>
                  </Row>
                );
              }
              Object.keys(val.numInstancesPerClass).map(function (key) {
                return instancePlot.push([key, val.numInstancesPerClass[key]]);
              });

              instancePlot.sort(function (a, b) {
                return b[1] - a[1];
              });

              var imagePlot = [["Class", "Image Count"]];
              if (!val.numImagesPerClass) {
                imagePlot.push([]);
                return null;
              }
              Object.keys(val.numImagesPerClass).map(function (key) {
                return imagePlot.push([key, val.numImagesPerClass[key]]);
              });

              imagePlot.sort(function (a, b) {
                return b[1] - a[1];
              });

              var distributionPlot = [];
              var trace1;
              if (!val.numImagesPerClass) {
                distributionPlot.push([]);
                return null;
              }

              geometricProperties.length > 0 &&
                geometricProperties.map((val, i) => {
                  return Object.keys(val.bbxImAreaRatioPerClass).map(function (
                    key
                  ) {
                    //Converting the decimal values into percentage
                    function roundedPercents(n) {
                      return n * 100;
                    }
                    var roundedResults =
                      val.bbxImAreaRatioPerClass[key].map(roundedPercents);

                    // Transforming all the classes into upper case.
                    var className = key.toUpperCase();
                    trace1 = {
                      x: roundedResults,
                      name: className,
                      opacity: 0.7,
                      type: "histogram",
                    };

                    return distributionPlot.push(trace1);
                  });
                });

              return (
                <div key={i}>
                  {instancePlot.length > 0 && imagePlot.length > 0 && (
                    <div style={{ textAlign: "center" }}>
                      <label>
                        <input
                          name="isChecked"
                          type="checkbox"
                          checked={selected}
                          onChange={handleLogChange}
                        />
                      </label>{" "}
                      Log Scale
                    </div>
                  )}
                  <CardGroup>
                    <Card>
                      <Card.Body className="my-pretty-chart-container instance-container">
                        <Card.Title>No of Instance per class </Card.Title>
                        {instancePlot.length > 0 && imagePlot.length === [] && (
                          <div>
                            <label>
                              <input
                                name="isChecked"
                                type="checkbox"
                                checked={selected}
                                onChange={handleLogChange}
                              />
                            </label>{" "}
                            Log Scale
                          </div>
                        )}
                        {selected ? (
                          <Chart
                            style={{ marginTop: "-95px" }}
                            width={"100%"}
                            height={"300px"}
                            chartType="BarChart"
                            loader={
                              <div>
                                <Spinner
                                  type="grow"
                                  className="loading-spinner"
                                >
                                  Loading Chart
                                </Spinner>
                              </div>
                            }
                            data={instancePlot}
                            options={{
                              legend: { position: "none" },
                              colors: ["#FBEAFF", "#9378FF"],
                              bar: { groupWidth: "85%" },
                              animation: {
                                duration: 1000,
                                easing: "out",
                              },
                              vAxis: {
                                title: "Number of Instances",
                                format: "decimal",
                                gridlines: { count: 4 },
                                scaleType: "log",
                              },
                              hAxis: {
                                title: "Classes",
                              },
                              orientation: "horizontal",
                            }}
                          />
                        ) : (
                          <Chart
                            width={"100%"}
                            height={"300px"}
                            style={{ marginTop: "-3%" }}
                            chartType="BarChart"
                            loader={
                              <div>
                                <Spinner
                                  type="grow"
                                  className="loading-spinner"
                                >
                                  Loading Chart
                                </Spinner>
                              </div>
                            }
                            data={instancePlot}
                            options={{
                              legend: { position: "none" },
                              colors: ["#FBEAFF", "#9378FF"],
                              animation: {
                                duration: 1000,
                                easing: "linear",
                              },
                              vAxis: {
                                title: "Number of Instances",
                              },
                              hAxis: {
                                title: "Classes",
                              },
                              orientation: "horizontal",
                            }}
                          />
                        )}
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Body>
                        {imagePlot.length > 0 && (
                          <div
                            className={"my-pretty-chart-container"}
                            style={{ textTransform: "capitalize" }}
                          >
                            <Card.Title style={{ textAlign: "center" }}>
                              No of Images per class{" "}
                            </Card.Title>
                            {selected ? (
                              <Chart
                                width={"100%"}
                                height={"300px"}
                                style={{ marginTop: "-3%" }}
                                chartType="BarChart"
                                loader={
                                  <div>
                                    <Spinner
                                      type="grow"
                                      className="loading-spinner"
                                    >
                                      Loading Chart
                                    </Spinner>
                                  </div>
                                }
                                data={imagePlot}
                                options={{
                                  legend: { position: "none" },
                                  colors: ["#6544d0"],
                                  bar: { groupWidth: "85%" },
                                  animation: {
                                    duration: 1000,
                                    easing: "out",
                                  },

                                  vAxis: {
                                    title: "Number of Images",
                                    format: "decimal",
                                    gridlines: { count: 4 },
                                    scaleType: "log",
                                  },
                                  hAxis: {
                                    title: "Classes",
                                  },
                                  orientation: "horizontal",
                                }}
                              />
                            ) : (
                              <Chart
                                width={"100%"}
                                height={"300px"}
                                style={{ marginTop: "-3%" }}
                                chartType="BarChart"
                                loader={
                                  <div>
                                    <Spinner
                                      type="grow"
                                      className="loading-spinner"
                                    >
                                      Loading Chart
                                    </Spinner>
                                  </div>
                                }
                                data={imagePlot}
                                legend_toggle
                                options={{
                                  legend: { position: "none" },
                                  colors: ["#6544d0"],
                                  animation: {
                                    duration: 1000,
                                    easing: "linear",
                                  },

                                  vAxis: {
                                    title: "Number of Images",
                                    format: "decimal",
                                  },
                                  hAxis: {
                                    title: "Classes",
                                  },
                                  orientation: "horizontal",
                                }}
                              />
                            )}
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </CardGroup>
                  <Card>
                    <Card.Body>
                      {distributionPlot.length > 0 && (
                        <Plot
                          data={distributionPlot}
                          useResizeHandler
                          className="plot"
                          layout={{
                            barmode: "overlay",
                            title: "Image area ratio distribution per class",
                            autosize: true,
                            xaxis: {
                              title: "",
                              autoscale: true,
                              tickmode: "array",
                            },
                            yaxis: {
                              title: "Count",
                              autoscale: true,
                            },
                          }}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </div>
              );
            })}

          {photometricProperties.length > 0 &&
            photometricProperties.map((val, i) => {
              var brightness = [];
              var contrast = [];
              var blur = [];
              var shanEntpy = [];

              if (!val.brightnessRatios) {
                brightness.push([]);
                return null;
              }
              var brightnessResults = val.brightnessRatios.map(
                ({ low, high }) => [range(low, high)]
              );

              const brightnessArray = brightnessResults.flat(2); //The depth level specifying how deep a nested array structure should be flattened. Defaults to 1.

              if (brightnessArray.length === 0) {
                return null;
              }
              var brightnessObj = {
                y: brightnessArray,
                name: "",
                marker: { color: "#FF3F3F" },
                boxpoints: false,
                type: "box",
              };
              brightness.push(brightnessObj);

              if (!val.contrastRatios) {
                contrast.push([]);
                return null;
              }
              var contrastResults = val.contrastRatios.map(({ low, high }) => [
                range(low, high),
              ]);

              const contrastArray = contrastResults.flat(2); //The depth level specifying how deep a nested array structure should be flattened. Defaults to 1.
              if (contrastArray.length === 0) {
                return null;
              }

              var contrastObj = {
                y: contrastArray,
                name: "",
                marker: { color: "#9378FF" },
                boxpoints: false,
                type: "box",
              };
              contrast.push(contrastObj);

              if (!val.blurValues) {
                blur.push([]);
                return null;
              }
              var blurResults = val.blurValues.map(({ low, high }) => [
                range(low, high),
              ]);

              const blurArray = blurResults.flat(2); //The depth level specifying how deep a nested array structure should be flattened. Defaults to 1.
              var blurObj = {
                y: blurArray,
                name: " ",
                marker: { color: "#C8D5F8" },
                boxpoints: false,
                type: "box",
              };
              blur.push(blurObj);

              if (!val.shannonEntropyValue) {
                shanEntpy.push([]);
                return null;
              }
              var ShannonResults = val.shannonEntropyValue.map(
                ({ low, high }) => [range(low, high)]
              );

              const shannonArray = ShannonResults.flat(2); //The depth level specifying how deep a nested array structure should be flattened. Defaults to 1.
              if (shannonArray.length === 0) {
                return null;
              }
              var shanEntpyObj = {
                y: shannonArray,
                name: " ",
                marker: { color: "#4D0679" },
                boxpoints: false,
                type: "box",
              };
              shanEntpy.push(shanEntpyObj);

              return (
                <div key={i}>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col md={6}>
                          <div className="my-pretty-chart-container">
                            {brightness.length > 0 && brightness !== [] && (
                              <Plot
                                data={brightness}
                                layout={{
                                  yaxis: {
                                    title: "Image Brightness",
                                    autoscale: true,
                                    zeroline: false,
                                  },

                                  title: "Image Brightness",
                                  showlegend: false,
                                  autosize: true,
                                  width:450
                                }}
                                useResizeHandler
                                className="plot"
                                responsive={true}
                              />
                            )}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="my-pretty-chart-container">
                            {contrast.length > 0 && (
                              <Plot
                                data={contrast}
                                layout={{
                                  yaxis: {
                                    title: "Image Contrast",
                                    autoscale: true,
                                    zeroline: false,
                                  },

                                  title: "Image Contrast",
                                  showlegend: false,
                                  autosize: true,
                                }}
                                useResizeHandler
                                className="plot"
                                responsive={true}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <hr/>
                  
                      <Row>
                        <Col md={6}>
                          <div className="my-pretty-chart-container">
                            {blur.length > 0 && (
                              <Plot
                                data={blur}
                                layout={{
                                  yaxis: {
                                    title: "Image Bluriness",
                                    autoscale: true,
                                    zeroline: false,
                                  },
                                  title: "Image Bluriness",
                                  showlegend: false,
                                  autosize: true,
                                }}
                                useResizeHandler
                                className="plot"
                                responsive={true}
                              />
                            )}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="my-pretty-chart-container">
                            {shanEntpy.length > 0 && (
                              <Plot
                                data={shanEntpy}
                                layout={{
                                  yaxis: {
                                    title: "Shannon Entropy",
                                    autoscale: true,
                                    zeroline: false,
                                  },
                                  title: "Shannon Entropy",
                                  showlegend: false,
                                  autosize: true,
                                }}
                                useResizeHandler
                                className="plot"
                                responsive={true}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
        </div>
      ) : !noStats ? (
        <div className="data-loading">
          <Spinner type="grow" className="statistics-spinner" />
          <span className="spinner-span">
            Please wait... <br /> Your Geometric Properties is loading.
          </span>
        </div>
      ) : (
        <Row>
          <Col>
            <Card.Body className="cardBorder">
              <Card.Text className="emptyData">
                No Statistics found ! <br />
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
      )}
    </div>
  );
};
