/*eslint-disable*/
/*
  Upload Config file : 
  Step 3 - Once the user has selected the pre-loaded vs custom datasets followed by classes,
  user can either upload config files or select pre defined config.yml files from 
  the options to begin the training process.
  Admin users can select DNN,YOLO, SSD,ANPR and can upload the custom config file.
*/
import React, { useState, useRef } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  FormLabel,
  FormControl,
  FormGroup,
  ListGroup
} from "react-bootstrap";
import { Spinner } from "reactstrap";
import settings from "../../../../config/config";
import AWS from "aws-sdk";
import { Auth } from "aws-amplify";
import awsconfig from "../../../../config/aws-exports";
import Icons from "../../../../components/icons/icons";
import { Requests } from "../../../request/request.js";
import axios from 'axios';
import CustomAlert from "../../../../components/alert/alert"
import CustomProgressBar from "../../../../components/progress/progress";

const TrainModel = (props) => {
  const file = useRef(null);
  const [isProgress, setIsProgress] = useState(0);
  const [configFileName, setConfigFileName] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selected, setSelected] = useState("v1");
  const [enableOptions, setEnableOptions] = useState(false);
  const [enableCustomUpload, setEnableCustomUpload] = useState(false);
  const [initialiseTrainingValue, setInitialiseTrainingValue] = useState(null);
  const [spin, setSpin] = useState(false);
  const [validated, setValidated] = useState(false);
  const [volume, setVolume] = useState("1");
  const [msg, setMsg] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [enableObjDetect, setEnableObjDetect] = useState(false);
  // const [networkInfo, setNetworkInfo] = useState({});


  /* Model Name format is based on Model_ + date_,month_ and year */
  var currentDate = new Date();
  var date = String(currentDate.getDate()).padStart(2, "0");
  var month = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var year = currentDate.getFullYear();
  currentDate = "Model_" + Math.floor(Math.random() * 100) + "_" + month + "-" + date + "-" + year;

  const [input, setInput] = useState(currentDate);
  var token = (props.refreshedIdToken && (props.refreshedIdToken === props.signInUserSession.idToken.jwtToken)) ? props.refreshedIdToken : (props.refreshedIdToken && (props.refreshedIdToken !== props.signInUserSession.idToken.jwtToken)) ? props.refreshedIdToken : props.signInUserSession.idToken.jwtToken;

  const initCredentials = async () => {
    try {
      const credentials = await Auth.currentUserCredentials();
      AWS.config.credentials = credentials;
      AWS.config.correctClockSkew = true;
      AWS.config.httpOptions.timeout = 0;
      return credentials;
    } catch (e) {
      return null;
    }
  };

  const createS3 = ({ bucket, region }) => {
    return new AWS.S3({
      apiVersion: "2006-03-01",
      httpOptions: { timeout: 0 },
      signatureVersion: "v4",
      params: { Bucket: bucket },
      region,
    });
  };
  const REFRESH_INTERVAL = 50 * 60 * 1000;
  const MIN_REFRESH_INTERVAL = 30 * 1000;
  var refreshTimeout;

  const scheduleCredentialsRefresh = (interval = REFRESH_INTERVAL) => {
    refreshTimeout = setTimeout(async () => {
      const session = await Auth.currentSession();

      AWS.config.credentials.params.Logins[
        `cognito-idp.us-east-1.amazonaws.com/${awsconfig.userPoolId}`
      ] = session.getIdToken().getJwtToken();

      AWS.config.credentials.refresh(async (error) => {
        console.log("Token Refreshed");
        if (refreshTimeout) {
          scheduleCredentialsRefresh(
            error ? MIN_REFRESH_INTERVAL : REFRESH_INTERVAL
          );
        }
      });
    }, interval);
  };

  /* S3 & AWS Cognito Credentials and Configuration */
  Auth.currentCredentials().then((res) => {
    AWS.config.update({
      region: "us-east-1",
      credentials: res,
    });

    AWS.config.getCredentials(function (err) {
      if (err) console.log(err.stack); // credentials not loaded
    });

    new AWS.S3({
      region: "us-east-1",
      apiVersion: "2010-12-01",
    });

    AWS.config.getCredentials(function (err) {
      if (err) console.log(err.stack); // credentials not loaded
    });
  });

  function handleConfigFileChange(event) {
    setDisabledBtn(true);
    file.current = event.target.files[0];
    if(file.current){
    setConfigFileName(file.current.name);
    setError(null);
    var type = "config";
    handleFormValidation(event, type);
    }
  }

  /* 
    Initialise Training : API returns config file location to upload config file in s3 or begins training if range slider is choosen.
    Input : 
      Email - registered email id,
      ConfigName - 128_128.yml / config.yml
      modelName - Model name 
      augmentation - false by default (Haven't released this feature as of 25 Feb 2021)
      Datasets - [customFace]
      PublicDatasets - [udas1, udas2]
      classes - [car,truck]
      modelArch - yolo/dnn/ssd/anpr
    Output :
      status,training-id, config-location 
  */
  function initialiseTraining(
    selected,
    configName,
    modelName,
    augmentation,
    type
  ) {
    var requestBody = {
      datasetIDs: props.chooseClasses[0].items.datasetIDs,
      publicDatasetIDs: props.chooseClasses[0].items.public_dataset_ids,
      classes: props.chooseClasses[0].items.classes,
      networkArchitecture: selected 
    };

    setValidated(true);
    var networkAPI = settings.network;
    var request_type = 'event_body';
      Requests(networkAPI,"POST",JSON.stringify(requestBody),request_type, token)
      .then((data) => {
        console.log("Initialising Training API: ", data);
        if (data && type === "slider") {             
            setError(null);
            setValidated(false);
            beginTraining(data);
            // setNetworkInfo(data);
        }else {
          var value = {
            config: data,
          };
          setInitialiseTrainingValue(value);
          // setIsProgressLoading(true);
          uploadConfigFile(data, configName);
        }

        if (data.reason) {
            // setError(true);
            setSpin(false);
            setValidated(false);
            setSpinner(false)
            setError(data.reason);
            setInitialiseTrainingValue(null);
            setConfigFileName(null)
          }
        })
        .catch((err) => {
          console.log("Error Initialising the API: ", err);
          // setError(true);
          setValidated(false);
          setSpin(false);
          setSpinner(false)
          setError("Internal Server Error..Please try again");
      });
  }

  /* 
      Network Architecture: 
      Selected value will be as follows,
      DNN - v1,
      OCR - v2
      MobilenetSSD - v3
      YOLO - v4
  */
  function handleModelArchChange(value) {
    setEnableCustomUpload(false);
    setError(null);
    setSuccess(false);
    setDisabledBtn(false);
    if (
      value === "obj_detector" ||
      value === "v3" ||
      value === "v4"
    ) {
      setEnableObjDetect(true)
      setVolume("")
      setEnableOptions(true);
    } else {
      setEnableObjDetect(false)
      setEnableOptions(false);
    }

    if(value === "v2"){
      setVolume("1")
    }
    setSelected(value);
  }

  function handleSliderChange(value) {
    setVolume(value)
  }

  /* 
    Train Now Starts : 
    Invoke the Begin training API by passing Email ID , 
    cognito Id token, trainingID, modelArch, Datasets, PublicDatasets,
    classes as input and success status as output
  */

  function beginTraining(data) {
    var trainNetworkAPI = settings.trainNetwork;
    // setError(null);
    var params = "?id=" + data['id'] + "&version=" + data['version'];
    Requests(trainNetworkAPI,"POST",params,'query', token)
      .then((res) => {
        console.log("Train Now Started:", res);
        if (res) {
          var modelAPI = settings.model;
          var request_type = 'event_body';
          Requests(modelAPI,"POST",JSON.stringify({
            name: input,
            networkIDs: [data['id']],
            networkVersions: [(data['version']).toString()],
            modelArchitecture: (data['networkArchitecture'] === 'v1') ? 'DNN' : "" }),request_type, token)
            .then((modelResponse) => {
              console.log("Model has been created :", modelResponse);
              var queryparams = "?id=" + modelResponse['id'] + "&version=" + modelResponse['version'];

              Requests(modelAPI,"PATCH",JSON.stringify({
                name: input
                }),'event_body_params', token,queryparams)
                .then((modelName) => {
                  console.log("Model name has been stored:", modelName);
                  setDisabledBtn(false);
                  setMsg(true);
                  setSpin(false)
                  if (props.finalStep === 3) {
                    var plusLinks = document.querySelectorAll("li.progtrckr-doing");
                    var firstLink = plusLinks[0];
                    firstLink.setAttribute("class", "progtrckr-done");
                  }
              
                })
            })
          
        }
      })
      .catch((err) => {
        console.log("There was an error training your model: ", err);
        setSpin(false);
        // setError(true);
        setError("Something went wrong. Please try again later, If this error persists, please contact support@xailient.com");
      });
  }

  function handleConfigUpload(e) {
    e.preventDefault();
    setSpin(true);
    if (!configFileName) {
      var type = "slider";
      handleFormValidation(e, type);
    } 
    else {
        setError(null);
        if(initialiseTrainingValue && ("config" in initialiseTrainingValue)){
          var network_details = initialiseTrainingValue.config;
          setSuccess(false);
          beginTraining(network_details);
        }else{
          handleFormValidation(e, "");
        }
    }
  }

  /* 
    Choose config file (optional) :
    Admin Users can upload the config.yml file via 
    the console to begin the training process  
  */
 async function uploadConfigFile(request, file_name) {

   var params = "?id=" + request["id"] + "&version=" + request["version"] + "&__include_urls__=True";
    Requests(settings.network, "GET", params, "query", token).then(
      async (data) => {
        console.log("Bucket Created for Upload Training Dataset :", data);
        var url_info = data[0].trainingConfigInfo;

        const formData = new FormData();
        Object.keys(url_info.fields).forEach((key) => {
          formData.append(key, url_info.fields[key]);
        });
        // Actual file has to be appended last.
        formData.append("file", file_name, "config.yml");
        const credentials = await initCredentials();
        var bucket = url_info["url"];
        var region = "us-east-1";
        // eslint-disable-next-line
        const s3 = createS3({ bucket, region });

        if (credentials) {
          scheduleCredentialsRefresh();
        }

        axios
          .post(url_info["url"], formData, {
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setSpinner(false);
              setIsProgress(percentCompleted);
            },
          })
          .then(function (response) {
            console.log("Uploaded Training Dataset:", response);
            if (response.status === 204) {
              console.log("Config File Uploaded:");
              setDisabledBtn(false);
              // setIsProgressLoading(false);
              setError(null);
              setSpinner(false);
              setSuccess(true);
            }
          })
          .catch(function (error) {
            console.log("Config File Upload Error:", error);
            // setError(true);
            setSpinner(false);
            setError("Config file upload error!");
          });
      }
    );
}

  function handleFormValidation(event, type) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    var model = input;
    setValidated(true);
    setError(null);

    var checkSplCharacter = /^[a-zA-Z0-9-_]+$/;

    if (model.search(checkSplCharacter) === -1) {
      setSpin(false);
      setValidated(false);
      return null;
    }
    if (model.length === 0 || model === "" || model.split(" ").length > 1) {
      setSpin(false);
      setValidated(false);
      return null;
    }
    if (selected.length === 0) {
      // setError(true);
      setSpin(false);
      setValidated(false);
      setError("Please select your cortical function to process");
      return null;
    }
    if (selected === "obj_detector") {
      setSpin(false);
      setValidated(false);
      setError("Please select an object detector to process");
      return null;
    }
    var modelName = model;
    
    if (type === "slider") {
      var config = volume;
      if (selected === "v4") {
         config = "config_yolo";
      } else if (selected === "v3") {
         config = "config_ssd";
      } else if (volume === "1" && selected === "v1") {
         config = "128_128";
      } else if (volume === "2" && selected === "v1") {
         config = "256_256";
      } 
      else if (selected === "v2" && volume === "1") {
         config = "config_ocr";
      }
      var configName = config;
      var augmentation = false;
      console.log(" <<<<<------ Slider Bar --->>>>>> ");
      initialiseTraining(selected, configName, modelName, augmentation, type);
    } else {
      if (!file.current) {
        setError("Error!..Please select valid config file");
        setSuccess(false);
      } else {
        console.log(" <<<<<------ Custom File Upload --->>>>>> ");
        setSpinner(true);
        initialiseTraining(selected, file.current, modelName, "", type);
      }
    }
  }
  return (
    <Card>
      <Card.Body>
        <div style={{ padding: "2px" }}>
        <Card.Title>3. Configure Training</Card.Title>
          <hr />
          <Row>
            <Col>
              <div>
                <Card.Title style={{ fontSize: "12px", fontWeight: "700" }}>
                  Model Setting
                </Card.Title>
                <span>Model Name</span>
                <br />

                <Form noValidate validated={validated}>
                  <Form.Group as={Row} controlId="formHorizontalModelName">
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="modelName"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Enter your Model Name"
                        pattern="^[a-zA-Z0-9-_]+$"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid model name without any whitespace
                        and special characters.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Form>
                <br />
              </div>

              <div>
                <div>
                  <Card.Title
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    Select Cortical Function
                  </Card.Title>

                  <Row>
                  <Col sm={4}>
                      <Card
                        className="card-custom"
                        style={{
                          border:
                            selected === "v1"
                              ? "2px solid #ec7211"
                              : "1px solid #E8E7EB",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                      >
                        <Card.Body
                          onClick={() => handleModelArchChange("v1")}
                          style={{ backgroundColor: "unset" }}
                        >
                          <Card.Title
                            style={{
                              color: selected === "v1" ? "#ec7211" : "#121212",
                              fontSize: "13px",
                              fontWeight: "700",
                            }}
                          >
                            Single Class Object Detector{" "}
                            <div className="pull-right">
                              <Form.Group>
                                <Form.Check.Label className="customLabel">
                                  <Form.Check.Input
                                    type="radio"
                                    name="radios"
                                    className="customRadio"
                                    value={"v1"}
                                    key={"v1"}
                                    checked={selected === "v1" ? true : false}
                                    onChange={(event) =>
                                      handleModelArchChange(event.target.value)
                                    }
                                  />
                                  <img
                                    src={Icons.CheckCircle}
                                    alt="checkmark"
                                  />
                                </Form.Check.Label>
                              </Form.Group>
                            </div>
                          </Card.Title>
                          <Card.Text>{""}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    {/* 
                  <Col>
                    <Card
                      className="card-custom"
                      style={{
                        border:
                          selected === "v1"
                            ? "2px solid #ec7211"
                            : "1px solid #E8E7EB",
                      }}
                    >
                      <Card.Body style={{ borderRadius: "8px" }}>
                        <Card.Title
                          style={{
                            color: "#ec7211",
                            fontSize: "13px",
                            fontWeight: "700",
                          }}
                        >
                          Image Classifier
                          <div className="pull-right">
                            <Form.Group>
                            <Form.Check.Label>
                                <Form.Check.Input
                                  disabled
                                  className="chooseDatasetTable"
                                  type="radio"
                                  label="Image Classifier"
                                  name="radios"
                                  value={"classifier"}
                                  key={"classifier"}
                                  onChange={(event) =>
                                    handleModelArchChange(event.target.value)
                                  }
                                />
                                <img src={Icons.CheckCircle} alt="checkmark" />
                              </Form.Check.Label>
                            </Form.Group>
                          </div>
                        </Card.Title>
                        <Card.Text>
                        Examine how the generator and discriminator losses changed while training.

                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col> */}

                    <Col sm={4}>
                      <Card
                        className="card-custom"
                        style={{
                          border:
                            selected === "obj_detector" ||
                            selected === "v3" ||
                            selected === "v4"
                              ? "2px solid #ec7211"
                              : "1px solid #E8E7EB",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                      >
                        <Card.Body
                          onClick={() => handleModelArchChange("obj_detector")}
                          style={{ backgroundColor: "unset" }}
                        >
                          <Card.Title
                            style={{
                              color:
                                selected === "obj_detector"
                                  ? "#ec7211"
                                  : "#121212",
                              fontSize: "13px",
                              fontWeight: "700",
                            }}
                          >
                            Multi Class Object Detector{" "}
                            <div className="pull-right">
                              <Form.Group>
                                <Form.Check.Label className="customLabel">
                                  <Form.Check.Input
                                    type="radio"
                                    className="customRadio"
                                    label=" Object Detector"
                                    name="radios"
                                    value={"obj_detector"}
                                    key={"obj_detector"}
                                    checked={enableObjDetect && true}
                                    onChange={(event) =>
                                      handleModelArchChange(event.target.value)
                                    }
                                  />
                                  <img
                                    src={Icons.CheckCircle}
                                    alt="checkmark"
                                  />
                                </Form.Check.Label>
                              </Form.Group>
                            </div>
                          </Card.Title>
                          <Card.Text>{""}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card
                        className="card-custom"
                        style={{
                          border:
                            selected === "v2"
                              ? "2px solid #ec7211"
                              : "1px solid #E8E7EB",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                      >
                        <Card.Body
                          onClick={() => handleModelArchChange("v2")}
                          style={{ backgroundColor: "unset" }}
                        >
                          <Card.Title
                            style={{
                              color: selected === "v2" ? "#ec7211" : "#121212",
                              fontSize: "13px",
                              fontWeight: "700",
                            }}
                          >
                            ANPR{" "}
                            <div className="pull-right">
                              <Form.Group>
                                <Form.Check.Label className="customLabel">
                                  <Form.Check.Input
                                    className="customRadio chooseDatasetTable"
                                    type="radio"
                                    label="ANPR (License Plate Reader)"
                                    name="radios"
                                    value={"v2"}
                                    checked={selected === "v2" ? true : false}
                                    key={"v2"}
                                    onChange={(event) =>
                                      handleModelArchChange(event.target.value)
                                    }
                                  />
                                  <img
                                    src={Icons.CheckCircle}
                                    alt="checkmark"
                                  />
                                </Form.Check.Label>
                              </Form.Group>
                            </div>
                          </Card.Title>
                          <Card.Text>{""}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <fieldset>
                    <Form.Group as={Row}>
                      <Col sm={6}>
                        {enableOptions && (
                          <div>
                            <Card.Title
                              style={{ fontSize: "12px", fontWeight: "700" }}
                            >
                              Select Object Detector Model
                            </Card.Title>

                            <ul>
                              <li style={{ listStyle: "none" }}>
                                <Form.Check
                                  className="chooseDatasetTable"
                                  type="radio"
                                  label="YOLO V3"
                                  name="v4"
                                  value={"v4"}
                                  checked={selected === "v4" && true}
                                  key={"v4"}
                                  onChange={(event) =>
                                    handleModelArchChange(event.target.value)
                                  }
                                />
                                <Form.Check
                                  className="chooseDatasetTable"
                                  type="radio"
                                  label="Mobilenet V2 SSD"
                                  name="v3"
                                  value={"v3"}
                                  checked={selected === "v3" ? true : false}
                                  key={"v3"}
                                  onChange={(event) =>
                                    handleModelArchChange(event.target.value)
                                  }
                                />
                              </li>
                            </ul>
                            <br />
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                  </fieldset>

                  {(!enableOptions || enableCustomUpload) && (
                    <div>
                      <Card.Title
                        style={{ fontSize: "12px", fontWeight: "700" }}
                      >
                        Inference Time
                      </Card.Title>
                      <Card.Text>
                        Training times estimated based on 10,000 images.
                      </Card.Text>
                      <Form.Group as={Row} controlId="formHorizontalSpeed">
                        <Col sm={6}>
                          <Card
                            className="card-custom"
                            style={{
                              border:
                                volume === "1"
                                  ? "2px solid #ec7211"
                                  : "1px solid #E8E7EB",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                          >
                            <Card.Body
                              style={{ backgroundColor: "unset" }}
                              onClick={() => setVolume("1")}
                            >
                              <Card.Title
                                style={{
                                  color: volume === "1" ? "#ec7211" : "#121212",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                }}
                              >
                                <img src={Icons.Fastest} alt="fastest" /> {""}{" "}
                                Fastest
                                <div className="pull-right">
                                  <Form.Group>
                                    <Form.Check.Label className="customLabel">
                                      <Form.Check.Input
                                        type="radio"
                                        className="customRadio"
                                        name="1"
                                        value={"1"}
                                        key={"1"}
                                        checked={volume === "1" ? true : false}
                                        onChange={(event) => {
                                          handleSliderChange(
                                            event.target.value
                                          );
                                        }}
                                      />

                                      <img
                                        src={Icons.CheckCircle}
                                        alt="checkmark"
                                      />
                                    </Form.Check.Label>
                                  </Form.Group>
                                </div>
                              </Card.Title>
                              <Card.Text>
                                This option provides the fastest inference
                                speed. Most useful for detecting large to medium
                                objects (e.g. nearby).{" "}
                              </Card.Text>
                              <ListGroup variant="flush">
                                <ListGroup.Item className="custom-listgroup">
                                  {" "}
                                  Raspberry Pi 3B+ (with 1 Core) :{" "}
                                  <b className="pull-right">74 ms</b>{" "}
                                </ListGroup.Item>
                                <ListGroup.Item className="custom-listgroup">
                                  {" "}
                                  Raspberry Pi 3B+ with Movidius :{" "}
                                  <b className="pull-right">14 ms</b>{" "}
                                </ListGroup.Item>
                                <ListGroup.Item className="custom-listgroup">
                                  {" "}
                                  Pi 3B+ with Google Coral:{" "}
                                  <b className="pull-right"> 9 ms</b>
                                </ListGroup.Item>
                                <ListGroup.Item className="custom-listgroup">
                                  {" "}
                                  Jetson Nano :{" "}
                                  <b className="pull-right">25 ms</b>{" "}
                                </ListGroup.Item>
                                <ListGroup.Item className="custom-listgroup">
                                  {" "}
                                  Training Time :{" "}
                                  <b className="pull-right">60 minutes</b>{" "}
                                </ListGroup.Item>
                              </ListGroup>{" "}
                            </Card.Body>
                          </Card>
                        </Col>
                        {selected === "v1" && (
                          <Col>
                            <Card
                              className="card-custom"
                              style={{
                                border:
                                  volume === "2"
                                    ? "2px solid #ec7211"
                                    : "1px solid #E8E7EB",
                                borderRadius: "8px",
                                cursor: "pointer",
                              }}
                            >
                              <Card.Body
                                onClick={() => setVolume("2")}
                                style={{ backgroundColor: "unset" }}
                              >
                                <Card.Title
                                  style={{
                                    color:
                                      volume === "2" ? "#ec7211" : "#121212",
                                    fontSize: "13px",
                                    fontWeight: "700",
                                  }}
                                >
                                  <img src={Icons.Accurate} alt="accurate" />{" "}
                                  {""} Most Accurate
                                  <div className="pull-right">
                                    <Form.Group>
                                      <Form.Check.Label className="customLabel">
                                        <Form.Check.Input
                                          type="radio"
                                          className="customRadio"
                                          name="2"
                                          value={"2"}
                                          key={"2"}
                                          checked={
                                            volume === "2" ? true : false
                                          }
                                          onChange={(event) => {
                                            handleSliderChange(
                                              event.target.value
                                            );
                                          }}
                                        />
                                        <img
                                          src={Icons.CheckCircle}
                                          alt="checkmark"
                                        />
                                      </Form.Check.Label>
                                    </Form.Group>
                                  </div>
                                </Card.Title>
                                <Card.Text>
                                  This option provides the best accuracy. Most
                                  useful for detecting small objects (e.g. far
                                  away).
                                </Card.Text>
                                <ListGroup variant="flush">
                                  <ListGroup.Item className="custom-listgroup">
                                    {" "}
                                    Raspberry Pi 3B+ (with 1 Core) :{" "}
                                    <b className="pull-right">264 ms</b>{" "}
                                  </ListGroup.Item>
                                  <ListGroup.Item className="custom-listgroup">
                                    {" "}
                                    Raspberry Pi 3B+ with Movidius :{" "}
                                    <b className="pull-right">TBA</b>{" "}
                                  </ListGroup.Item>
                                  <ListGroup.Item className="custom-listgroup">
                                    {" "}
                                    Pi 3B+ with Google Coral:{" "}
                                    <b className="pull-right">TBA</b>
                                  </ListGroup.Item>
                                  <ListGroup.Item className="custom-listgroup">
                                    {" "}
                                    Jetson Nano :{" "}
                                    <b className="pull-right">TBA</b>{" "}
                                  </ListGroup.Item>
                                  <ListGroup.Item className="custom-listgroup">
                                    {" "}
                                    Training Time :{" "}
                                    <b className="pull-right">
                                      70 minutes
                                    </b>{" "}
                                  </ListGroup.Item>
                                </ListGroup>{" "}
                              </Card.Body>
                            </Card>
                          </Col>
                        )}
                      </Form.Group>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <div className="vertical-divider" />
            <Col sm={3}>
              <Card.Text className="summary">Summary</Card.Text>
              <Card.Text>
                <small style={{ fontSize: "12px", color: "#777777" }}>
                  Datasets Used
                </small>
              </Card.Text>
              {props.chooseDatasets[0] && props.chooseDatasets[0].dataset_name && (
                <div className="wrap-text">
                  {props.chooseDatasets[0].dataset_name.map((val) => (
                    <div key={val}>{val}</div>
                  ))}
                </div>
              )}

              {props.chooseDatasets[0] &&
                props.chooseDatasets[0].public_dataset_name && (
                  <div className="wrap-text">
                    {props.chooseDatasets[0].public_dataset_name.map((val) => (
                      <div key={val}>{val}</div>
                    ))}
                  </div>
                )}
              <br />
              <Card.Text>
                <small style={{ fontSize: "12px", color: "#777777" }}>
                  Combined Classes Used
                </small>
              </Card.Text>
              {Array.isArray(props.chooseClasses[0].items.classes) &&
              props.chooseClasses[0].items.classes.length > 0 &&
              props.chooseClasses[0].items.classes.length > 20 ? (
                <div style={{ textAlign: "justify" }}>
                  {props.chooseClasses[0].items.classes.join(", ")}
                </div>
              ) : (
                props.chooseClasses[0].items.classes.map((val) => (
                  <div key={val}>{val}</div>
                ))
              )}
            </Col>
          </Row>

          {!enableOptions && (
            <div>
              <Card.Text style={{ textAlign: "center" }}>( OR )</Card.Text>
              <Button
                variant="link"
                onClick={() => setEnableCustomUpload(true)}
                style={{ margin: "auto", display: "block" }}
              >
                Upload Config File Instead
              </Button>
            </div>
          )}
          {(enableOptions || enableCustomUpload) && (
            <Form>
              <Card.Title style={{ fontSize: "12px", fontWeight: "600" }}>
                Choose Config File <b>(Optional)</b>
              </Card.Title>
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={8}>
                      <div className="img-div pull-left">
                        <img
                          src={Icons.quickStart1}
                          className="datasetsImg"
                          alt="upload"
                        />
                      </div>
                      <div className="btn-div pull-left">
                        <span className="cardText">
                          Upload your config file in .yml file format. Config
                          file upload for DNN, ANPR and YOLO/SSD is optional. We
                          use default configurations while training the model.{" "}
                          <br />
                          Note: Proceed to BEGIN TRAINING if you don't have
                          config file to upload.
                        </span>
                      </div>
                    </Col>
                    <Col sm={4} style={{ marginTop: "25px" }}>
                      <FormGroup id="file">
                        <FormLabel
                          htmlFor="myConfig"
                          className="btn-light custom-file-upload"
                        >
                          Choose File {""}
                          <img src={Icons.UploadIcon} alt="upload" />
                        </FormLabel>

                        <span>
                          {" "}
                          {configFileName} {""}{" "}
                          {spinner && (
                            <Spinner size="sm" role="status" color="warning" />
                          )}
                        </span>

                        <FormControl
                          onChange={handleConfigFileChange}
                          id="myConfig"
                          type="file"
                        />
                      </FormGroup>
                      {isProgress !== Number(0) && (
                        <CustomProgressBar isProgress={isProgress} />
                      )}
                    </Col>
                    {success && (
                      <CustomAlert
                        message="Config File has been uploaded. Please Begin your Training"
                        theme="success"
                      />
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          )}
          <br />
          {error && (
            <CustomAlert
              message={error}
              theme="danger"
              image={Icons.WarningRed}
              button="reload"
              button_msg="Click Here To Train AI Model"
            />
          )}

          {msg && (
            <CustomAlert
              message="Success! Training Started. We will send you an email, once the training is completed.In the meantime, please check your training status in the Manage AI Model"
              theme="success"
              image={Icons.InfoUnionPrimary}
              button="success"
              buttonMsg="Train New AI Model"
            />
          )}
          <br />
          {!disabledBtn && (
            <Button
              style={{ float: "right", textAlign: "center" }}
              onClick={(e) => {
                handleConfigUpload(e);
              }}
              variant="warning"
              disabled={msg ? true : false}
            >
              Begin Training{" "}
              {spin && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  color="light"
                />
              )}
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default TrainModel;


