/*eslint-disable*/

export const RequestResponse = async (url,method,body,type,token,param) => {

    var query_param;
    
    const requestOptions = {
        method: method,
        headers: { 
            Accept: "*",
            Authorization: token
        },
    };
    
    if(type === 'event_body'){
        requestOptions.body = body
    }
    if(type === 'query'){
        query_param = body
    }
    if(type === 'event_body_params'){
        requestOptions.body = body
        query_param = param
    }
     return fetch(`${url}` + (query_param ? `?${query_param}` : ""),requestOptions)
     
     .then(response => {
            if (response.status === 200) {
                return response
            } else {
                throw new Error(`${response.status}`);
              }      
          })
          .then(data => data.json())
          .catch(error => {
              return error; 
          })
    };