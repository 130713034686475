/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {Card, Button, Modal, InputGroup, Form} from "react-bootstrap";
import ResizeableTables from "../../../components/table/resizeable_table";
import SplitterLayout from "react-splitter-layout";
import useModelApiRequest from "../../helper/model";
import settings from "../../../config/config";
import PopupContent from "../../../components/table/table_popup_content";
import CustomPagination from "../../../components/pagination/pagination";
import  search from "../../../components/search/search";
import {Requests} from "../../request/request";
import Tab from "../../../components/tab/tab";
import SaveNotes from "../../../components/edit_text/edit";

const initialHeaderState = {
  Select: { active: true },
  id: { active: true },
  name: { active: true },
  status: { active: true },
  modelArchitecture: { active: true },
  buildSDK: { active: true },
  creationDate: { active: true },
  version: { active: true },
  Delete: { active: true },
};

const ViewModel = (props) => {
  let token = props.refreshedIdToken;
  let url = settings.model;
  const { model_error, is_model_loaded, model_items } = useModelApiRequest(
    url,
    token
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tableHeadsObject, setTableHeads] = useState(initialHeaderState);
  const [showMetricsButton, setShowMetricsButton] = useState(false);
  const [showTabsData, setShowTabsData] = useState(false);
  const [tabsData, setTabsData] = useState([]);
  const [selectedModels, setSelectedModels] = useState([])
  

  useEffect(() => {
    !JSON.parse(localStorage.getItem("tableHeadsModel")) &&
      window.localStorage.setItem(
        "tableHeadsModel",
        JSON.stringify(tableHeadsObject)
      );
  }, [tableHeadsObject]);

  const changeTableHeads = (id, e) => {
    let heads = { ...tableHeadsObject };
    heads[id].active = e;
    setTableHeads(heads);

    window.localStorage.setItem(
      "tableHeadsModel",
      JSON.stringify(tableHeadsObject)
    );
  };
  const saveTableInfo = () => {
    window.localStorage.setItem(
      "tableHeadsModel",
      JSON.stringify(tableHeadsObject)
    );
    handleClose();
  };

   //for pagination
   const [currentPage, setCurrentPage] = useState(0);
   const dataPerPage = 6;
   const currentData = model_items.slice(currentPage * dataPerPage, (currentPage + 1) * dataPerPage);
   const paginate = (e,pageNumber) => {
     e.preventDefault();
     setCurrentPage(pageNumber);
   };

  //for searchbar
  const [search_value, setSearchValue] = useState("");
  const handleSearchInputChanges = (e) => {
    setSearchValue(e.target.value);
  };
  let searchResults = search(model_items, search_value);

  function showViewMetrics(selectedModelIDs, model_data) {
    setShowMetricsButton(selectedModelIDs.length > 0)
    setShowTabsData(selectedModelIDs.length === 1)
    setSelectedModels(selectedModelIDs)
    generateTabsData(model_data)
  }

  function generateTabsData(modelData){

      let tabList = [{
          'Details': [{
              'Model Summary': [
                  {'Name:': modelData.name},
                  {'Type:': modelData.modelType},
                  {'Architecture:': modelData.modelArchitecture}
              ]
          }, {
              'Model Details': [
                  {'Version:': modelData.version},
                  {'Status:': modelData.status},
                  {'Notes:':   <SaveNotes
                              key={modelData.id}
                              notes={modelData.notes}
                              id={modelData.id}
                              user={props}
                              version={modelData.version}
                              domainObject="model"
                              token={token}
                            />}
              ]
          }]
      },
      ]
    setTabsData(tabList)
  }

  function evaluateModels() {
    let url = settings.evaluationresult
    let params = generateEvaluationParams()
    Requests(url, "GET", params, "query", token)
        .then((res) => res.json())
        .then((res) => {
        })

        .catch((err) => {

        });

  }

  function generateEvaluationParams(){
    let params = ""
    selectedModels.forEach((model)=>{
         params = `${params}id=${model}&`
    })
    return `?${params}`
  }

  return (
    <SplitterLayout vertical>
      <div className="my-pane">
        <Card.Body>
          <div className="d-flex align-items-center">
            <Card.Title>Models</Card.Title>
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Button
                  size="sm"
                  variant="warning"
                  onClick={() => (window.location.href = "/internal/train")}
                >
                  Train Model
                </Button>
              </div>
            </div>
          </div>
          <div className="top-menu">
            <InputGroup className="search-bar">
              <Form.Control
                value={search_value}
                onChange={handleSearchInputChanges}
                type="text"
                placeholder="Search"
              />
            </InputGroup>

            {model_items.length > dataPerPage &&
              <CustomPagination
                size={"lg"}
                previous={(e) => paginate(e, currentPage - 1)}
                next={(e) => paginate(e, currentPage + 1)}
                currentPage={currentPage}
                increment={currentPage + 1 + " of " + Math.ceil(model_items.length / dataPerPage)}
                pagesCount={Math.ceil(model_items.length / dataPerPage)}
              />
            }

            <div className="customised-configuration">
              <i className="fas fa-cog fa-lg setting-icon icon-color" onClick={handleShow}></i>
            </div>
          </div>
          {/* FIX ME - Evaluation Metrics*/}
          {/* {showMetricsButton &&(
            <div>
              <Button
                  size="sm"
                  variant="warning"
                  onClick={() => evaluateModels()}
              >
                View/Compare Metrics
              </Button>
            </div>
          )} */}
        </Card.Body>

        <ResizeableTables
          minCellWidth={120}
          data={search_value === "" ? currentData : searchResults}
          error={model_error}
          loading={is_model_loaded}
          user={props}
          tableHeadsObject={tableHeadsObject}
          domainObject="model"
          viewMetrics={showViewMetrics}
        ></ResizeableTables>

        <div className="d-flex justify-content-end">
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Toggle Table Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PopupContent
                tableHeads={tableHeadsObject}
                handleChange={changeTableHeads}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="warning" onClick={saveTableInfo}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div className="my-pane">
        <Card.Body>

          <Card.Title>Select a model above</Card.Title>

          {showTabsData && (
              <Tab tabsdata={tabsData}></Tab>
          )}
        </Card.Body>
      </div>
    </SplitterLayout>
  );
};

export default ViewModel;
