/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Card, Table, Button } from "react-bootstrap";
import { TimeStampTodateConverter } from "../timestamp/unix_to_datetime_converter";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

export const CustomTable = ({ tableheader, data, viewpage }) => {
  const [loading, setLoading] = useState(true);
  const [deviceID, setDeviceID] = useState(null);
  const [frID, setfrID] = useState(null)

  function checkStatus(status) {
    if (status) {
      return { variant: "success", text: "Activated" };
    }
    return { variant: "secondary", text: "Deactivated" };
  }

  function handleCheckbox(e) {
    if (e.target.checked) {
      setDeviceID(e.target.value);
    } else {
      setDeviceID(null);
    }
  }
  function handlefrCheckbox(e) {
    if (e.target.checked) {
      setfrID(e.target.value);
    } else {
      setfrID(null);
    }
  }
  
  return (
    <div>
      <Card className="pt-1 mt-2 mb-2">
        <Table borderless size="md" className="mb-0" id="view-devices-table">
          <thead className="font-14">
            <tr>
              {tableheader.map((item,i) => (
                <td key={i}>{item}</td>
              ))}
            </tr>
          </thead>

          <tbody>
            {viewpage === "devices"
              ? data.map((device,key) => {
                  return (
                    <tr className="font-14 border-top" key={device.id}>
                      <td>
                        {/* <input
                          type="checkbox"
                          checked={deviceID === device.id}
                          onChange={handleCheckbox}
                          value={device.id}
                          className="mr-1"
                        /> */}
                        <span>
                          <Link to={`/customer/device/${device.id}`}>
                            <label className="link-cursor-pointer primary-color">
                              {device.id}
                            </label>
                          </Link>
                        </span>
                      </td>

                      <td>{device.customerDeviceID}</td>
                      <td>
                        <Button
                          className="rounded small py-0"
                          disabled
                          size="sm"
                          variant={`outline-${
                            checkStatus(device.activated).variant
                          }`}
                        >
                          {loading && device.id === deviceID ? (
                            <Spinner
                              type="grow"
                              size="sm"
                              style={{
                                color: "#6544D0",
                                margin: "0 auto",
                                display: "block",
                              }}
                            />
                          ) : (
                            <small>{checkStatus(device.activated).text}</small>
                          )}
                        </Button>
                      </td>
                      <td>{device.hardwareModelName}</td>
                      <td>{TimeStampTodateConverter(device.creationDate)}</td>
                    </tr>
                  );
                })
              : ""}

            {viewpage === "devicedetail" ? data.map((fr,key)=> {
             return (
              <tr className="font-14 border-top" key={key}>
                <td>
                        {/* <input
                          type="checkbox"
                          checked={frID === fr.id}
                          onChange={handleCheckbox}
                          value={fr.id}
                          className="mr-1"
                        /> */}
                        <span>
                          <Link to={`/customer/frdetail/${fr.id}`}>
                            <label className="link-cursor-pointer primary-color">
                            
                              {fr.identityName}
                            </label>
                          </Link>
                        </span>
                      </td>
                <td>{fr.imagePaths.length}</td>
                <td>{TimeStampTodateConverter(fr.creationDate)}</td>

                
              </tr>
              
            )}):""}
          </tbody>
        </Table>
      </Card>
    </div>
  );
};
