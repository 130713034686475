/*eslint-disable*/
import React, { useState, useEffect, Suspense } from "react";
import {
  Table,
  Col,
  Card,
  Button,
  Spinner,
  Modal,
  Form,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Requests } from "../request/request";
import settings from "../../config/config";
import Icons from "../../components/icons/icons";
import CustomPagination from "../../components/pagination/pagination";
import CustomAlert from "../../components/alert/alert";
import useIsMountedRef from "../../views/helper/isMounted";
import Popup from "../../components/popups/popup";

function ViewRoles(props) {
  const { refreshedIdToken } = props;
  const [token] = useState(refreshedIdToken);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleClose = () => {
    setError(false);
    setShow(false);
  };
  const handleShow = () => {
    setSuccess(false);
    setShow(true);
  };
  const [permissionList, setPermissionList] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [spin, setSpin] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [roleID, setroleID] = useState("");
  const [checked, setChecked] = useState([]);
  const [id, setid] = useState("");

  const dataPerPage = 6;
  const currentData = roles.slice(
    currentPage * dataPerPage,
    (currentPage + 1) * dataPerPage
  );

  useEffect(() => {
    Requests(settings.role, "GET", "", "query", token)
      .then((data) => {
        setRoles(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRoles([]);
        setError("Oops, Server Error.  ");
      });
  }, []);

  useEffect(() => {
    var permissionAPI = settings.permission;
    setLoading(true);
    fetch(permissionAPI, {
      method: "GET",
      headers: {
        Accept: "*",
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setPermissionList(data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, isMountedRef]);

  function getUserType(userType) {
    if (userType === "Xailient") return "Xailient";
    if (userType === "Xailient Admin") return "Xailient Admin";
    return "Customer";
  }
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }
  const [newvalue, setnewvalue] = useState([]);

  useEffect(() => {
    var permissionAPI = settings.permission;
    setLoading(true);
    fetch(permissionAPI, {
      method: "GET",
      headers: {
        Accept: "*",
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setPermissionList(data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, isMountedRef]);

  const editRole = (id) => {
    handleShow();
    let value = roles.filter((role) => role.id === id)[0];
    setnewvalue(value);
    // convert from object to Array
    const tempPermissions = value.permissions;
    let newPermissions = [];
    for (let i = 0; i < Object.values(tempPermissions).length; i++) {
      const outputArr = Object.values(tempPermissions)[i];
      const group = Object.keys(tempPermissions)[i];
      const newObj = {
        group: group,
        outputArr: outputArr,
      };
      newPermissions.push(newObj);
    }
    setSelectedPermissions(newPermissions);
  };

  var isChecked = (item) =>
    checked.includes(item) ? "checked-item small" : "not-checked-item small";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setnewvalue({
      ...newvalue,
      [name]: value,
    });
  };

  const handleSelectedPermissions = (checked, permission, group) => {
    if (checked) {
      let tempArr = [...selectedPermissions];
      let exist = false;
      for (let i = 0; i < tempArr.length; i++) {
        const element = tempArr[i];
        if (element.group == group) {
          for (let j = 0; j < element.outputArr.length; j++) {
            const element1 = element.outputArr[j];
            if (element1 == permission) {
              exist = true;
            }
          }
        }
      }
      if (!exist) {
        if (selectedPermissions.length == 0) {
          let tempArr = [...selectedPermissions];
          tempArr[handleGetIndexOfObj(group)] = {
            group: group,
            outputArr: [permission],
          };
          setSelectedPermissions(tempArr);
        } else {
          let tempArr = [...selectedPermissions];
          if (tempArr[handleGetIndexOfObj(group)]) {
            tempArr[handleGetIndexOfObj(group)] = {
              ...tempArr[handleGetIndexOfObj(group)],
              outputArr: [
                ...tempArr[handleGetIndexOfObj(group)].outputArr,
                permission,
              ],
            };
            setSelectedPermissions(tempArr);
          } else {
            tempArr[handleGetIndexOfObj(group)] = {
              group: group,
              outputArr: [permission],
            };
            setSelectedPermissions(tempArr);
          }
        }
      }
    } else {
      let newPermissions = [...selectedPermissions];
      const tempGroup = selectedPermissions[handleGetIndexOfObj(group)];

      let newOutPutArr = [...tempGroup.outputArr];
      const newOutPutArr1 = newOutPutArr.filter(
        (item, i) => item != permission
      );

      newPermissions[handleGetIndexOfObj(group)] = {
        group: group,
        outputArr: newOutPutArr1,
      };
      setSelectedPermissions(newPermissions);
    }
  };

  const createDynamicObj = () => {
    let newObj = {};
    for (let i = 0; i < selectedPermissions.length; i++) {
      const element = selectedPermissions[i];
      newObj[element.group] = element.outputArr;
    }
    return newObj;
  };

  const isPermissionSelected = (p, group) => {
    for (let i = 0; i < selectedPermissions.length; i++) {
      const element = selectedPermissions[i];
      if (element.group == group) {
        for (let j = 0; j < element.outputArr.length; j++) {
          const element1 = element.outputArr[j];
          if (element1 == p) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const handleGetIndexOfObj = (group) => {
    if (selectedPermissions.length > 0) {
      for (let i = 0; i < selectedPermissions.length; i++) {
        const element = selectedPermissions[i];
        if (element.group == group) {
          return i;
        }
      }
      return selectedPermissions.length;
    } else {
      return 0;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSpin(true);
    let param = `id=${newvalue.id}`;
    const submitObj = {
      name: newvalue.name,
      permissions: createDynamicObj(),
    };
    Requests(
      settings.role,
      "PATCH",
      JSON.stringify(submitObj),
      "event_body_params",
      token,
      param
    )
      .then((data) => {
        if (data && data.statusCode !== 400) {
          setSuccess(true);
          setSpin(false);
          const updatedRoles = roles.map((item, i) => {
            if (item.id == data.id) {
              return data;
            } else {
              return item;
            }
          });
          setRoles(updatedRoles);
        }
        if (data.statusCode === 400) {
          setSpin(false);
          setError(`Role already exists.`);
        }
      })
      .catch((err) => {
        setError(
          "Oops, Server Error.  Please try again later. If error persist please contact support@xailient.com  "
        );
        setSpin(false);
      });
  };

  const fetchAllRoles = () => {
    Requests(settings.role, "GET", "", "query", token)
      .then((data) => {
        setRoles(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRoles([]);
        setError("Oops, Server Error.  ");
      });
  };
  useEffect(() => {
    fetchAllRoles();
  }, [isMountedRef]);

  //delete role
  const deleteRole = (id) => {
    setSpin(true);
    Requests(settings.role, "DELETE", "id=" + id, "query", token)
      .then((data) => {
        setSpin(false);
        setDeletePopup(false);
        fetchAllRoles();
      })
      .catch((e) => {
        setSpin(false);
        setDeletePopup(false);
        return null;
      });
  };
  const handleDeletePopup = (id) => {
    setid(id);
    setDeletePopup(true);
  };

  const [roleUserType, setroleUserType] = useState(null)
  let customerID = props.username
  Requests(settings.user, "GET", "customerID="+encodeURIComponent(customerID), "query", token)
  .then((data)=>{
    setroleUserType[data[0].userType]
  })
  .catch((e)=>{
    setError(
      "Oops, Server Error.  Please try again later. If error persist please contact support@xailient.com  "
    );
  })

  return (
    <div className="mt-4">
      <Card>
        <Card.Body style={{ overflow: "auto" }}>
          <div className="d-flex align-items-center">
            <Card.Title className="custom-card-title">
              View Roles ({roles.length})
            </Card.Title>
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Link to="/manageroles">
                  <Button
                    type="submit"
                    id="manage-roles-btn"
                    variant="primary"
                    className="mb-2"
                  >
                    Create new role{" "}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <Table
            borderless
            size="md"
            className="border-top"
            id="view-roles-table"
          >
            <thead className="font-14">
              <tr className="border-bottom">
                <th className="white-header pl-5 column-width">ID</th>
                <th className="white-header">Role Name</th>
                <th className="white-header ">User Type</th>
                <th className="white-header">Permissions</th>
                <th className="white-header">Edit</th>
                {roleUserType === "XailientAdmin"? <th className="white-header">Delete</th> : null}
              </tr>
            </thead>
            <tbody>
              {currentData.map((role) => (
                <tr className="font-14 border-bottom" key={role.id}>
                  <td className="pl-5">{role.id}</td>
                  <td>{role.name}</td>
                  <td>{getUserType(role.userType)}</td>

                  <td>
                    {Object.keys(role.permissions).map((key, index) => (
                      <Col key={index}>
                        <b>{key}:</b>
                        <div className="font-12">
                          {role.permissions[key].map((permission, i) => (
                            <Col key={i}>{permission}</Col>
                          ))}
                        </div>
                      </Col>
                    ))}
                  </td>
                  <td>
                    <span>
                      <img
                        alt="Edit"
                        className="icon-button"
                        onClick={() => editRole(role.id)}
                        src={Icons.Edit}
                      />
                    </span>
                  </td>
                  {
                    roleUserType === "XailientAdmin"? 
                    <td>
                    <span>
                      <img
                        alt="Delete"
                        onClick={(e) => handleDeletePopup(role.id)}
                        className="icon-button"
                        src={Icons.Trash}
                      />
                    </span>
                  </td> : null
                  }             
                </tr>
              ))}
              {error && (
                <CustomAlert
                  message={error}
                  theme="danger"
                  image={Icons.Alert}
                  style={{ textAlign: "inherit" }}
                />
              )}
            </tbody>
          </Table>

          {loading && (
            <div className="text-center">
              <Spinner style={{ color: "#705AF3" }} animation="grow" />
            </div>
          )}
        </Card.Body>
        <CustomPagination
          size="lg"
          previous={() => paginate(currentPage - 1)}
          next={() => paginate(currentPage + 1)}
          currentPage={currentPage}
          increment={`${currentPage + 1} of ${Math.ceil(
            roles.length / dataPerPage
          )}`}
          pagesCount={Math.ceil(roles.length / dataPerPage)}
        />

        {deletePopup && (
          <Suspense fallback="Loading">
            <Popup
              spin={spin}
              action={() => deleteRole(id)}
              show={deletePopup}
              hide={() => setDeletePopup(false)}
              modal_title="Delete Confirm"
              modal_body={`Are you sure you want to delete this user ${id} and all of its content ? This action cannot be undone.`}
              button_variant="danger"
              button_label="Delete"
              button_icon="fas fa-trash-alt"
            />
          </Suspense>
        )}
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h4>Edit Roles</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success && (
            <CustomAlert
              message={`Success, Edited successfully.`}
              theme="success"
              image={Icons.CheckMark}
              style={{ textAlign: "inherit" }}
            />
          )}

          {error && (
            <CustomAlert
              message={error}
              theme="danger"
              image={Icons.Alert}
              style={{ textAlign: "inherit" }}
            />
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mt-3 mb-3">
              <Form.Label style={{ color: "#333333" }}>User Type</Form.Label>
              <Form.Control
                disabled
                required
                value={newvalue.userType === "Xailient" ? "Xailient" : "Customer"}
              />
            </Form.Group>

            <Form.Group className="mt-3 mb-3">
              <Form.Label style={{ color: "#333333" }}>Role Name</Form.Label>
              <Form.Control
                name="name"
                value={newvalue.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Permissions for{" "}
                <b>{newvalue.userType === "Xailient" ? "Xailient" : "Customer"}</b>
              </Form.Label>
              {permissionList ? (
                <div className="checkList">
                  <Row>
                    {permissionList
                      .filter((item) => item.userType == newvalue.userType)
                      ?.map((item, index) => (
                        <div key={index} className="mr-5">
                          <b className="font-14">
                            {
                              permissionList.filter(
                                (item) => item.userType == newvalue.userType
                              )[index]?.group
                            }
                          </b>

                          {item.permissions.map((p, i) => (
                            <Col key={i}>
                              <Row className="align-items-center ml-1">
                                <input
                                  name={"permissions"}
                                  checked={isPermissionSelected(
                                    p,
                                    permissionList.filter(
                                      (item) =>
                                        item.userType == newvalue.userType
                                    )[index]?.group
                                  )}
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleSelectedPermissions(
                                      e.target.checked,
                                      p,
                                      permissionList.filter(
                                        (item) =>
                                          item.userType == newvalue.userType
                                      )[index]?.group
                                    )
                                  }
                                />
                                &nbsp; &nbsp;
                                <span className={isChecked(p)}>{p}</span>
                              </Row>
                            </Col>
                          ))}
                          <br />
                        </div>
                      ))}
                  </Row>
                </div>
              ) : (
                "No permissions to show"
              )}
            </Form.Group>

            <Modal.Footer>
              <Button
                type="submit"
                variant="primary"
                style={{ margin: "0 auto", display: "block" }}
                id="edit-company-btn"
              >
                Submit{" "}
                {spin && (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    color="light"
                  />
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

ViewRoles.propTypes = {
  refreshedIdToken: PropTypes.string,
};

ViewRoles.defaultProps = {
  refreshedIdToken: "",
};

export default ViewRoles;
