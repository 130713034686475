/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Form, Button, Card, Col, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Requests } from "../request/request.js";
import { RequestResponse } from "../request/requestResponse";
import settings from "../../config/config";
import { Spinner } from "reactstrap";
import CustomAlert from "../../components/alert/alert";
import Icons from "../../components/icons/icons";
import useIsMountedRef from "../../views/helper/isMounted";
import constants from "../../config/frontendConstants"

export const CreateTeamMember = (props) => {
  const [formData, setFormData] = useState({
    userType: "Customer",
    roleID: "",
    companyID: window.localStorage.getItem("companyID"),
    customerID: "",
    firstName: "",
    lastName: "",
  });

  let token = props.refreshedIdToken;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [spin, setSpin] = useState(false);
  const [validated, setValidated] = useState(false);
  const [roles, setRoles] = useState([]);
  const [userType, setUserType] = useState("Customer");
  const [customerID, setCustomerID] = useState(null);
  const isMountedRef = useIsMountedRef();

  const handleChange = (e, userType) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  useEffect(() => {
    let params = "Customer"
    Requests(
      settings.role,
      "GET",
      `q=${encodeURIComponent(JSON.stringify({ userType: params }))}`,
      "query",
      token
    )
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setRoles(data);
        }
      })
      .catch((e) => {
        return null;
      });
  }, [isMountedRef]);

  const createUserAccount = (userAPI, formData, token) => {
    RequestResponse(userAPI, "POST", JSON.stringify(formData), "event_body", token)

      .then((data) => {
        if (data && !data.message) {
          setSpin(false);
          setValidated(false);
          setSuccess(true);
        } 
        if (data.message === "400") {
          setSpin(false);
          setValidated(false);
          setError(`An account with Email ${formData.customerID} already exists.`);
        }
        if(data.message && data.message !== "400" ){
          setSpin(false);
          setValidated(false);
          setError("Access Denied! Please contact support@xailient.com");
        }
      })
      .catch((err) => {
        console.log(err);
        setSpin(false);
        setValidated(false);
        setError(
          "Oops, Server Error. Please try again later. If error persist please contact support@xailient.com "
        );
      });
  };

  const handleSubmit = (e) => {
    setError(false);
    setSuccess(false);

    e.preventDefault();
    e.stopPropagation();

    setValidated(true);

    if (
      (formData &&
        formData.userType &&
        formData.companyID &&
        formData.customerID &&
        formData.roleID,
      formData.firstName,
      formData.lastName)
    ) {
      setSpin(true);
      var userAPI = settings.user;
      setCustomerID(formData.customerID);
      createUserAccount(userAPI, formData, token);
    } else {
      setValidated(true);
      return;
    }
  };

  return (
    <div>
      <div className="content mt-4">
        <div>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <Card.Title className="custom-card-title">
                  Create New Team Member
                </Card.Title>
                <div className="ml-auto d-flex no-block align-items-center">
                  <div className="dl">
                    <Link to={"/viewteamMember"}>
                      <Button
                        type="submit"
                        id="view-users-btn"
                        variant="primary"
                        className="mb-2"
                      >
                        View Team Members
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <hr />

              <Form
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
                id="validate"
              >
                {success && (
                  <CustomAlert
                    message={`Success, An account with Email "${customerID}" has been created successfully.`}
                    theme="success"
                    image={Icons.CheckMark}
                    style={{ textAlign: "inherit" }}
                  />
                )}
                {error && (
                  <CustomAlert
                    message={error}
                    theme="danger"
                    image={Icons.Alert}
                    style={{ textAlign: "inherit" }}
                  />
                )}

                <Form.Group className="mt-3 mb-3">
                  <Form.Label style={{ color: "#333333" }}>
                    First Name
                  </Form.Label>
                  <Form.Control
                    placeholder="First Name"
                    required
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a first name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-3 mb-3">
                  <Form.Label style={{ color: "#333333" }}>
                    Last Name
                  </Form.Label>
                  <Form.Control
                    placeholder="Last Name"
                    required
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a last name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-3 mb-3" controlId="validateUsername">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder={"Email"}
                    key="email"
                    name="customerID"
                    type="email"
                    pattern={constants.emailPattern}
                    onChange={handleChange}
                    data-testid="emailInput"
                    value={formData.customerID}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Select Role</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      as="select"
                      name={"roleID"}
                      required
                      multiple={false}
                      value={formData.roleID}
                      onChange={handleChange}
                    >
                      <option>Select Role</option>

                      {roles.map((item, i) => {
                        return (
                          <option key={i} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please select role.
                  </Form.Control.Feedback>
                </Form.Group>
                <br />

                <Button
                  type="submit"
                  variant="primary"
                  className="btn-styling"
                  id="create-users-btn"
                  disabled={
                    !formData.roleID ||
                    !formData.firstName ||
                    !formData.lastName ||
                    !formData.customerID
                  }
                >
                  Submit {""}
                  {spin && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      color="light"
                    />
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};
