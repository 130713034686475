/*eslint-disable*/
import { useState,useEffect, useRef} from "react";

const useModelApiRequest = (url,token) => {
    const [model_items, setModelItems] = useState([]);
    const [is_model_loaded, setIsModelLoaded] = useState(false);
    const [model_error, setModelError] = useState(null);
    const isMount = useRef( true );

    useEffect(() => {
      const fetchData = (isMount) => {
        fetch(url + "?fetch=all", {
            method: "GET",
            headers: {
              Accept: "*",
              Authorization: token,
            },
          })
          .then((res) => res.json())
          .then(response => {
            if(response && !isMount.current){  
            
            console.log("Model",response);
            
            setIsModelLoaded(true);
            setModelItems(response);
            }else{
              setIsModelLoaded(false);
              setModelError("There are no available models yet.");

            }
          })
          .catch(error => {
            setModelError("Please refresh your page and try again. If this error persists, please contact support@xailient.com",error);
          });
      };
      fetchData(isMount.current = true);
      return () => {
        isMount.current = false;
      }
      // eslint-disable-next-line
    }, [token]);
  
    return { model_error, is_model_loaded, model_items };
  };

  export default useModelApiRequest