/*eslint-disable*/
export const Requests = async (url,method,body,type,token,param) => {

var query_param;

const requestOptions = {
    method: method,
    headers: { 
        Accept: "*",
        Authorization: token
    },
};

if(type === 'event_body'){
    requestOptions.body = body
}
if(type === 'query'){
    query_param = body
}
if(type === 'event_body_params'){
    requestOptions.body = body
    query_param = param
}
try {
  const res = await fetch(
    `${url}` + (query_param ? `?${query_param}` : ""),
    requestOptions
  );
  const response = await res.json();
  return response;
} catch (error) {
    throw new Error(`Request failed with message: ${error}`);
}
//  return fetch(url + (query_param ? query_param : ""),requestOptions)
//  .then(resp => resp.json())
//         .then( json => json)
//         .catch((error) => error);   
 // .then(response => {
    //     if (response.ok) {
    //         return response;
    //     } 
    //     const responseError = {
    //     type: 'Error',
    //     message: response.message || 'Something went wrong',
    //     data: response.data || '',
    //     code: response.code || '',
    //     };
    
    //     const error = new Error();
    //     error.info = responseError;
    
    //     return (error);
    //   })
    //   .then(data => data.json())
    //   .catch(error => {
    //       console.error('request error>>>>', error);
    //       return error; 
    //   })
};