/*eslint-disable*/
import VerifyUser from "../views/account/authenticate_user.js";
import Icons from "../components/icons/icons";

var MainRoutes = () => {
  let routes = [];
  const user_type = window.localStorage.getItem('userType')
  const menu = window.localStorage.getItem('permission')

    if (menu && user_type==="xailientadmin") {
      routes.push({
        path: `/manageusers`,
        name: "Users",
        icon: Icons.MenuIcon,
        component: VerifyUser,
      },
      {
        path: "/manageroles",
        name: "Roles",
        icon: Icons.MenuIcon,
        component: VerifyUser,
      },
      {
        path: "/managecompany",
        name: "Company",
        icon: Icons.MenuIcon,
        component: VerifyUser,
      },{
        path: "/viewroles",
        // name: "Role Management",
        submenu: true,
        component: VerifyUser,
      },
      {
        path: "/viewusers",
        // name: "User Management ",
        component: VerifyUser,
        submenu: true,
      },
      {
        path: "/viewteamMember",
        // name: "User Management ",
        component: VerifyUser,
        submenu: true,
      },
      {
        path: "/viewcompanies",
        submenu: true,
        component: VerifyUser,
      })
    }

    if(menu && user_type === "xailient"){
      routes.push({
              path: `/manageusers`,
              name: "Users",
              icon: Icons.MenuIcon,
              component: VerifyUser,
            })
    }
    if (user_type === "customer") {
      routes.push({
        path: "/dashboard",
        component: VerifyUser,
        icon: Icons.SDK,
        name: "Dashboard",
      });

      if (menu && menu.includes("Invite Team Member")) {
        routes.push({
          path: `/inviteTeamMember`,
          name: "Team Member",
          icon: Icons.Team,
          component: VerifyUser,
        },{
          path: "/viewteamMember",
          component: VerifyUser,
          submenu: true,
        });
      }
      if (menu && menu.includes("FR Management")) {
        routes.push({
          path: `/${user_type}/frmanagement`,
          component: VerifyUser,
          icon: Icons.FRManagement,
          name: "FR Management",
        });
        routes.push({
          path: `/${user_type}/device/:id`,
          component: VerifyUser,
          submenu: true,
          name: "Device",
        });
        routes.push({
          path: `/${user_type}/frdetail/:id`,
          component: VerifyUser,
          submenu: true,
          name: "Detail",
        });
        
      }
      if (menu && menu.includes("SDK")) {
        routes.push({
          path: `/${user_type}/sdk/:id`,
          component: VerifyUser,
          icon: Icons.DeveloperCenter,
          name: "Developer Center",
        });
      }
    }
    // if(menu && menu.includes('API')){
    //   routes.push({
    //     path: '/swaggerPage',
    //     name: "API",
    //     icon: Icons.DeveloperCenter,
    //     component: VerifyUser,
    //   })
    // }

    if(menu && menu.includes('SDK') && user_type === 'xailient'){
      routes.push({
        path: '/sdk',
        name: "SDK",
        icon: Icons.MenuIcon,
        component: VerifyUser,
      })
    }
    if(menu && menu.includes('Dataset')){
      routes.push({
        path: `/${user_type}/dataset`,
        name: "Dataset",
        icon: Icons.DatasetView,
        component: VerifyUser,
      },
      {
        path: `/${user_type}/add-dataset`,
        name: "Add New Dataset",
        submenu: true,
        component: VerifyUser,
      },
      {
        path: `/${user_type}/label`,
        name: "Label",
        submenu: true,
        component: VerifyUser,
      },
      )
    }
    if(menu && menu.includes('Model')){
      routes.push({
        path: `/${user_type}/model`,
        name: "Model",
        icon: Icons.AIModelView,
        component: VerifyUser,
      },{
        path: `/${user_type}/train`,
        name: "Train Model",
        submenu: true,
        component: VerifyUser,
      }
      )
    }

    if(user_type === 'xailient'){
      routes.push({
        path: '/',
        pathTo: '/manageusers',
        name: 'Users',
        redirect: true,
      })
    }
    if(user_type === 'customer'){
      routes.push({
        path: '/',
        pathTo: '/dashboard',
        name: 'Dashboard',
        redirect: true,
      })
    }
  return routes;
};
export default MainRoutes;
