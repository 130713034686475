/*eslint-disable*/
/*
   Multi Step Form to begin the training process.
   Step 1 - Choose one or more Datasets
   Step 2 - Coose one or more classes.
   Step 3 - Choose the training configurations. The configuration are categorised into Admin and Non Admin user. 
   Where Non admin user can only select DNN. Whereas Admin users (Model/AddModel/ConfigUpload directory) can select DNN,YOLO, SSD,ANPR and can upload the custom config file.
*/
import React from "react";
import StepZilla from "react-stepzilla";
import Datasets from "./datasets";
import Classes from "./classes.js";
import TrainModel from "./train";
import { Card } from "react-bootstrap";

const MultiStepModel = (props) => {
  // eslint-disable-next-line
  const [chooseDatasets, setChooseDatasets] = React.useState([]);
  // eslint-disable-next-line
  const [chooseClasses, setChooseClasses] = React.useState([]);
  // eslint-disable-next-line
  const [configParams, setConfigParams] = React.useState([]);
  const [step, setStep] = React.useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const steps = [
    {
      name: "1. Choose Training Dataset(s)",
      component: (
        <Datasets
          {...props}
          nextStep={nextStep}
          chooseDatasets={chooseDatasets}
        />
      ),
    },
    {
      name: "2. Combine Classes",
      component: (
        <Classes
          chooseDatasets={chooseDatasets}
          chooseClasses={chooseClasses}
          nextStep={nextStep}
          prevStep={prevStep}
          {...props}
        />
      ),
    },
    {
      name: "3. Configure Training",
      component: (
        <TrainModel
          finalStep={3}
          chooseDatasets={chooseDatasets}
          configParams={configParams}
          chooseClasses={chooseClasses}
          nextStep={nextStep}
          prevStep={prevStep}
          {...props}
        />
      ),
    },
  ];

  return (
    <div>
      <Card.Body>
        <Card.Title>Train New AI Models</Card.Title>

        <div
          className="step-progress"
          style={{ marginTop: window.innerWidth > 1149 ? "-55px" : "-25px" }}
        >
          <StepZilla
            steps={steps}
            stepsNavigation={false}
            preventEnterSubmission={true}
            hocValidationAppliedTo={[1]}
            nextTextOnFinalActionStep={"Save"}
            showNavigation={false}
          />
        </div>
      </Card.Body>
    </div>
  );
};

export default MultiStepModel;
