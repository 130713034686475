/* eslint-disable */
import { useState, useEffect, useRef } from 'react';
import { stringify } from 'query-string';

const useSDKApiRequest = (url, token, type) => {
  const [sdkItems, setSdkItems] = useState([]);
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const [sdkError, setSdkError] = useState(null);
  const isSDKMount = useRef(true);

  const fetchSDK = async (isSDKMount) => {
    var queryParams = {
      q: JSON.stringify({
        isShared: true,
      }),
    };
    var params = type ? stringify(queryParams) : "fetch=all";
    try {
      
      const res = await fetch(`${url}?` + params, {
        method: 'GET',
        headers: {
          Accept: '*',
          Authorization: token,
        },
      });
      const response = await res.json();
      if (response && !isSDKMount.current && !response.message) {
        console.log("SDK Success State", response);
        
        const filteredSDKs = response.filter(
          ((val) => val.state !== 'UNKNOWN')
        );
        if (Object.keys(filteredSDKs).length === 0) {
          setIsSdkLoaded(false);
          setSdkError("There are no available SDK(s) yet.");
          return null;
        }
        const sortedSDKs = filteredSDKs.sort(function(a, b) {
          return b.creationDate - a.creationDate;
        });
        setIsSdkLoaded(true);
        setSdkItems(sortedSDKs);
      } 
      if(response.message === 'The incoming token has expired'){
        setIsSdkLoaded(false);
        setSdkError('Please refresh your page and try again. If this error persists, please contact support@xailient.com', error);
      }else{
        setIsSdkLoaded(false);
        setSdkError('No Data Found')
      }
    } catch (error) {
      setIsSdkLoaded(false);
      setSdkError('Please refresh your page and try again. If this error persists, please contact support@xailient.com', error);
    }
  };

  useEffect(() => {
    fetchSDK(isSDKMount.current = true);
    return () => {
      isSDKMount.current = false;
    };
    // eslint-disable-next-line
    }, []);
  return { sdkError, isSdkLoaded, sdkItems };
};

export default useSDKApiRequest;
