/*eslint-disable*/
/*
    Add/Update Label file : 
    Once the dataset and csv has been uploaded...
    user can update thier labels.csv as per the requirement to begin the training process
*/
import React, { useState, useRef } from "react";
import {
  Col,
  Card,
  Row,
  Form,
  FormLabel,
  FormControl,
  FormGroup,
} from "react-bootstrap";
import { Spinner } from "reactstrap";
import CustomAlert from "../../../components/alert/alert";
import CustomProgressBar from "../../../components/progress/progress";
import settings from "../../../config/config";
import Icons from "../../../components/icons/icons";
import { Requests } from "../../request/request.js";
import axios from "axios";

export default function Label(props) {
  const file = useRef(null);
  const [is_loading, setIsLoading] = useState(false);
  const [is_progress, setIsProgress] = useState(0);
  const [label_file_name, setLabelFileName] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  let token = props.refreshedIdToken;
  /* 
    Update Label file : 
    Once the dataset and csv has been uploaded...
    user can update thier labels.csv as per the requirement to begin the training process
  */
  const handleUpdateFileChange = (event) => {
    file.current = event.target.files[0];
    setLabelFileName(file.current.name);

    setError(null);
    handleLabelSubmit(event);
  };
  
  let dataset_name = window.location.href.split("/")[6];

  const handleLabelSubmit = async (event) => {
    event.preventDefault();

    if (file.current) {
      if (/\.(csv)$/i.test(file.current.name) === false) {
        setError(
          "Unsupported File Format : The selected file is not a csv file. Please select .csv file and try again..!"
        );
        return null;
      }
      setIsLoading(true);

      let dataset_id = props["dataset_id"]
        ? props["dataset_id"]
        : window.location.href.split("/")[5];
      var params = "?id=" + dataset_id + "&__include_urls__=True";
      var createBucketAPI = settings.dataset;

      Requests(createBucketAPI, "GET", params, "query", token).then((data) => {
        console.log("Create Presigned url to update the label file :", data);
        var presignedLabelURL = data[0].labelsUploadInfo.csv;
        var formData = new FormData();
        Object.keys(presignedLabelURL.fields).forEach((key) => {
          formData.append(key, presignedLabelURL.fields[key]);
        });
        formData.append("file", file.current, "labels.csv");

        axios
          .post(presignedLabelURL["url"], formData, {
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setIsLoading(false);
              setIsProgress(percentCompleted);
            },
          })
          .then(function (response) {
            console.log("Label has been Updated Successfully:", response);
            if (response.status === 204) {
              setIsLoading(false);
              setError(null);
              document.getElementById("updateConfig").value = "";
              setLabelFileName(null);
              setSuccess(true);
              var freezeDatasetAPI = settings.freezeDataset;
              var params = "?id=" + dataset_id;
              Requests(freezeDatasetAPI, "POST", params, "query", token).then(
                (res) => {
                  console.log("Freeze Dataset", res);
                  window.open("/internal/dataset",'_self');
                  return false;
                }
              );
            }
          })
          .catch(function (error) {
            setError(
              "Internal Server Error..Please try again by refreshing your page. If the error persist, please contact support@xailient.com"
            );
            setIsLoading(false);
            console.log("Label File Update Error:", error);
          });
      });
    }
  };

  return (
    <div className="first-class">
      <Card.Header as="h5" className="cardHeading">
        {dataset_name ? "" : "Step 2. "} Upload Label File
      </Card.Header>
      <Card.Body>
        {props.dataset_name  && (
          <Card.Title style={{ fontSize: "14px", fontWeight: "600" }}>
            Dataset Name -{" "}
            <span style={{ fontSize: "14px", fontWeight: "400" }}>
              {props.dataset_name} 
            </span>
          </Card.Title>
        )}
        {dataset_name && (
          <Card.Title style={{ fontSize: "14px", fontWeight: "600" }}>
            Dataset Name -{" "}
            <span style={{ fontSize: "14px", fontWeight: "400" }}>{dataset_name}</span>
          </Card.Title>
        )}

        <Form>
          <Card>
            <Card.Body>
              <Row>
                <Col sm={8}>
                  <div className="img-div pull-left">
                    <img
                      src={Icons.DatasetLabel}
                      className="datasetsImg"
                      alt="upload"
                    />
                  </div>
                  <div className="btn-div pull-left">
                    <span className="cardText">
                      Please ensure your CSV has only these SIX columns.
                      Spelling matters and it's case sensitive. <br /> The order
                      doesn't matter.
                      <br />
                      E.g. ['image_name', 'class', 'xmin', 'ymin', 'xmax',
                      'ymax']
                    </span>
                  </div>
                </Col>
                <Col sm={4} style={{ marginTop: "15px" }}>
                  <Form.Group>
                    <Col>
                      <FormGroup id="file">
                        <FormLabel
                          htmlFor="updateConfig"
                          className="btn-light custom-file-upload"
                        >
                          Choose File {""}
                          <img src={Icons.UploadIcon} alt="upload" />
                        </FormLabel>
                        <span>
                          {" "}
                          {label_file_name}{" "}
                          {is_loading && (
                            <Spinner size="sm" role="status" color="warning" />
                          )}
                        </span>
                        <FormControl
                          onChange={handleUpdateFileChange}
                          name="file"
                          id="updateConfig"
                          type="file"
                        />
                      </FormGroup>
                    </Col>

                    {is_progress !== Number(0) && (
                      <CustomProgressBar isProgress={is_progress} />
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {success && (
            <CustomAlert
              message="Success! Label has been updated successfully."
              theme="success"
              image={Icons.CheckCircle}
            />
          )}
          {error && (
            <CustomAlert
              message={error}
              theme="danger"
              image={Icons.WarningRed}
            />
          )}
        </Form>
      </Card.Body>
    </div>
  );
}
