/*eslint-disable*/
import shrinkIcon from '../../assets/images/SVG/Shrink.svg';
import plusIcon from '../../assets/images/SVG/Expand.svg';
import DatasetAdd from '../../assets/images/SVG/DatasetAdd.svg';
import xailientLogo from '../../assets/images/SVG/LogoHeader.svg';
import xailientLoginImage from '../../assets/images/logo/XailientLogo@2x.png';
import logouticon from '../../assets/images/SVG/Logout.svg';
import helpicon from '../../assets/images/SVG/info_24px.svg';
import closeicon from '../../assets/images/SVG/Close.svg';
import quickStart1 from '../../assets/images/SVG/illus-datasets.svg';
import quickStart2 from '../../assets/images/SVG/illus-trainModels.svg';
import expandIcon from '../../assets/images/SVG/expand_more_24px.svg';
import DeviceIcon from '../../assets/images/SVG/DeviceColor.svg';
import DatasetIcon from '../../assets/images/SVG/DatasetColor.svg';
import AIModelIcon from '../../assets/images/SVG/AIModelColor.svg';
import DeviceGroupIcon from '../../assets/images/SVG/DeviceGroupColor.svg';
import CardIcon from '../../assets/images/SVG/Grid.svg';
import ListIcon from '../../assets/images/SVG/List.svg';
import withHeatmap from '../../assets/images/With_Heatmap.jpg';
import withoutHeatmap from '../../assets/images/Without_Heatmap.jpg';
import Alert from '../../assets/images/SVG/error.svg';
import ManageDevices from '../../assets/images/SVG/ManageDevices.svg';
import Train from '../../assets/images/SVG/Train.svg';
import DatasetView from '../../assets/images/SVG/DatasetView.svg';
import DatasetLabel from '../../assets/images/SVG/illus-choose-label.svg';
import DatasetUpload from '../../assets/images/SVG/illus-choose-datasets.svg';
import Trash from '../../assets/images/SVG/Trash.svg';
import Xlogo from '../../assets/images/SVG/Xlogo.svg';
import PreLoaded from '../../assets/images/SVG/Pre-loaded.svg';
import PreTrained from '../../assets/images/SVG/Pre-trained.svg';
import InfoUnionPrimary from '../../assets/images/SVG/Union.svg';
import InfoUnionSecondary from '../../assets/images/SVG/Union2.svg';
import QuestionMark from '../../assets/images/SVG/QuestionMark.svg';
import WarningRed from '../../assets/images/SVG/WarningRed.svg';
import UploadIcon from '../../assets/images/SVG/UploadIcon.svg';
import DownloadIcon from '../../assets/images/SVG/DownloadIcon.svg';
import ChevronRight from '../../assets/images/SVG/ChevronRight.svg';
import ChevronLeft from '../../assets/images/SVG/ChevronLeft.svg';
import SortAscending from '../../assets/images/SVG/SortAscending.svg';
import SortDescending from '../../assets/images/SVG/SortDescending.svg';
import CheckMark from '../../assets/images/SVG/CheckMark.svg';
import Edit from '../../assets/images/SVG/Edit.svg';
import Timer from '../../assets/images/SVG/loader.svg';
import ListIconHighlight from '../../assets/images/SVG/ListIconHighlight.svg';
import CardIconFaded from '../../assets/images/SVG/CardIconFaded.svg';
import CardEnlarge from '../../assets/images/SVG/Enlarge.svg';
import CardShrink from '../../assets/images/SVG/CardShrink.svg';
import MenuIcon from '../../assets/images/SVG/MenuIcon.svg';
import CopyIcon from '../../assets/images/SVG/Copy.svg';
import AIModelView from '../../assets/images/SVG/AIModelView.svg';
import SupportDocumentation from '../../assets/images/SVG/SupportDocumentation.svg';
import SupportBugReport from '../../assets/images/SVG/SupportBugReport.svg';
import UploadIconGrey from '../../assets/images/SVG/Upload.svg';
import Fastest from '../../assets/images/SVG/speedy.svg';
import Accurate from '../../assets/images/SVG/most_accurate.svg';
import CheckCircle from '../../assets/images/SVG/CheckCircle.svg';
import Circle from '../../assets/images/SVG/Circle.svg';
import profilephoto from '../../assets/images/users/profile-pic.jpg';
import xailientOrchestraitLogo from '../../assets/images/logo/Logo.svg';
import SDK from '../../assets/images/SVG/sdk.svg';
import OrchestraitLogoHeader from '../../assets/images/SVG/OrchestraitLogoHeader.svg';
import Orchestrait from '../../assets/images/SVG/Orchestrait.svg';
import No1 from '../../assets/images/SVG/one.svg';
import No2 from '../../assets/images/SVG/two.svg';
import DeveloperCenter from '../../assets/images/SVG/Developer.svg';
import emptyData from '../../assets/images/SVG/illus-empty.svg';
import Reload from '../../assets/images/SVG/Reload.svg';
import FRManagement from '../../assets/images/SVG/FRManagement.svg';
import Team from '../../assets/images/SVG/Team.svg'

const Icons = {
  emptyData,
  shrinkIcon,
  expandIcon,
  DeviceGroupIcon,
  xailientLogo,
  xailientLoginImage,
  logouticon,
  helpicon,
  closeicon,
  quickStart1,
  quickStart2,
  DeviceIcon,
  plusIcon,
  DatasetAdd,
  CardIcon,
  ListIcon,
  // labelAnnotation: labelAnnotation,
  // emptyData: emptyData,
  withHeatmap,
  withoutHeatmap,
  Alert,
  ManageDevices,
  PreTrained,
  Train,
  DatasetView,
  DatasetIcon,
  AIModelIcon,
  DatasetLabel,
  DatasetUpload,
  Xlogo,
  Trash,
  PreLoaded,
  InfoUnionPrimary,
  InfoUnionSecondary,
  QuestionMark,
  WarningRed,
  UploadIcon,
  DownloadIcon,
  ChevronRight,
  ChevronLeft,
  SortAscending,
  SortDescending,
  CheckMark,
  Edit,
  Timer,
  ListIconHighlight,
  CardIconFaded,
  CardEnlarge,
  CardShrink,
  MenuIcon,
  CopyIcon,
  AIModelView,
  SupportDocumentation,
  SupportBugReport,
  UploadIconGrey,
  Fastest,
  Accurate,
  CheckCircle,
  Circle,
  profilephoto,
  xailientOrchestraitLogo,
  SDK,
  OrchestraitLogoHeader,
  Orchestrait,
  No1,
  No2,
  DeveloperCenter,
  Reload,
  FRManagement,
  Team
};

export default Icons;
