/*eslint-disable*/

/* Convert unix timestamp into human readable date time format
   reused in Training Entitlement (Common Banner), Payment Gateway  
*/
export const TimeStampTodateConverter = (UNIX_timestamp) => {
  let unix_timestamp;
  if (typeof UNIX_timestamp === "string") {
    unix_timestamp = Number(UNIX_timestamp);
  } else {
    unix_timestamp = UNIX_timestamp;
  }
  var readableDate = new Date(unix_timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = readableDate.getFullYear();
  var month = months[readableDate.getMonth()];
  var date = readableDate.getDate();
  var time = date + " " + month + " " + year;
  return time;
};
