/*eslint-disable*/
import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';

function BackendPagination({
  previous,
  next,
  currentPage,
  // pagesCount,
  // increment,
  // size,
                           }) {
  const customStyle = {
    customNextLink: {
      paddingLeft: '5px',
    },
    customPreviousLink: {
      paddingRight: '5px',
    },
    // customFloat: {
    //   float: "right",
    // },
  };

  return (

      <Pagination  aria-label="Page navigation example">
          <PaginationItem className="pagination-style px-1 rounded-left"
          >
              <PaginationLink className="pagination-link-style" onClick={previous} previous href="#"/>
          </PaginationItem>
            <span className="mx-2">

            </span>
          <PaginationItem
            className="pagination-style px-1 rounded-right"
          >
              <PaginationLink className="pagination-link-style" onClick={next} next href="#"/>
          </PaginationItem>
      </Pagination>

  );
}

BackendPagination.propTypes = {
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  // pagesCount: PropTypes.number.isRequired,
  // increment: PropTypes.string.isRequired,
  // size: PropTypes.string.isRequired,
};

export default BackendPagination;
