/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { Requests } from "../../../request/request";
import settings from "../../../../config/config";
import useIsMountedRef from "../../../../views/helper/isMounted";
import { Spinner } from "reactstrap";
import { TimeStampTodateConverter } from "../../../../components/timestamp/unix_to_datetime_converter";
import Icons from "../../../../components/icons/icons";
import { Link } from "react-router-dom";
import { CustomTable } from "../../../../components/table/custom_table";
import CustomSpinner from "../../../../components/Spinner/customSpinner";

const initialHeader = [
  "Identity Name",
  "No. of Images for Identity",
  "Added on",
];

function DeviceDetail(props) {
  const { refreshedIdToken } = props;
  const [token] = useState(refreshedIdToken);
  const [device, setDevice] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deviceID, setDeviceID] = useState(null);
  const isMountedRef = useIsMountedRef();
  const [tableheader, setTableheader] = useState(initialHeader);
  const [frIdentity, setfrIdentity] = useState([]);
  const [loadIdentities,setLoadIdentities] = useState(false);
  const [identityLoading, setIdentityLoading] = useState(true)


  useEffect(() => {
    let deviceID = getDeviceID();
    setDeviceID(deviceID);
    callDeviceDetailAPI(`id=${deviceID}`);
  }, []);

  function callDeviceDetailAPI(params) {
    Requests(settings.device, "GET", params, "query", token)
      .then((data) => {
        if (data && data.items && !data.status ) {
            setDevice(data.items[0]);
            setLoading(false);
            window.localStorage.setItem("idstorage", data.items[0].id);
            data.items &&  data.items[0] && data.items[0].configuredGalleryIdentities.forEach(val => {
              if(val ){
              Requests(settings.frIdentity, "GET", `id=${val[0]}&version=${val[1]}`, "query", token)
              .then((data) => {
                console.log(data)
                if(data){
                setLoadIdentities(true)
                setIdentityLoading(false)
                setfrIdentity(prevPosts => [...prevPosts,  ...data])
                }
              })
              .catch((error) => {
                console.log(error);
                setLoadIdentities(false)
                setIdentityLoading(false)
              });
            }
            })
          if(data.items &&  data.items[0] && data.items[0].configuredGalleryIdentities.length === 0){
            setIdentityLoading(false)
          }
          }
      })
      .catch((error) => {
        setDevice([]);
        setLoading(false);
        setLoadIdentities(false)
        setIdentityLoading(false)
        return null;
      });
  }

  function getDeviceID() {
    return window.location.pathname.split("/").pop();
  }

  function checkStatus(status) {
    if (status) {
      return { variant: "success", text: "Activated" };
    }
    return { variant: "secondary", text: "Deactivated" };
  }

  function calculateDays(activationDate) {
    let utcSeconds = activationDate;
    let date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(utcSeconds);
    let today = new Date();
    return today.getDate() - date.getDate();
  }

  return (
    <div>
      <div className="mt-4">
        <Link to={`/customer/frmanagement`}>
          <span>
            <img alt="menu-icons" src={Icons.ChevronLeft} />
            <label className="text-black-50 link-cursor-pointer font-14">
              {" "}
              FR Management
            </label>
          </span>
        </Link>
        <h4 className="mb-4 ml-2 mt-0">
          <b>Device</b>
        </h4>
      </div>
      <Row>
        <Col md={10}>
          <Card>
            <Card.Body style={{ overflow: "auto" }}>
              <Card.Title>Identities</Card.Title>
              <Col>
                {identityLoading ?(
                    <CustomSpinner/>
                ):""}
                {loadIdentities ? (
                  <CustomTable
                    tableheader={tableheader}
                    data={frIdentity}
                    viewpage={"devicedetail"}
                    token={token}
                  />

                ):""}

                {error && (
                  <CustomAlert
                    message={error}
                    theme="danger"
                    image={Icons.Alert}
                    style={{ textAlign: "inherit" }}
                  />
                )}
                {loading && (
                  <div className="text-center">
                    <div
                      className="mt-4"
                      style={{
                        margin: "auto",
                        verticalAlign: "middle",
                        display: "block",
                      }}
                    >
                    </div>
                  </div>
                )}
              </Col>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body style={{ overflow: "auto" }}>
              <Card.Title>Face Recognition Settings</Card.Title>
              <Col></Col>
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card>
            <Card.Body style={{ overflow: "auto" }}>
              <Card.Title>Device Detail</Card.Title>
              <Col className="ml-0 pl-0 pr-0">
                <Col className="mt-2 pl-0">
                  <small className="text-black-50 ">
                    <b>Device ID</b>
                  </small>
                  <br></br>

                  {!loading ? (
                    <label>
                      <small className="font-10">
                        {device.customerDeviceID}
                      </small>
                    </label>
                  ) : (
                    <Spinner
                      type="grow"
                      size="sm"
                      style={{
                        color: "#6544D0",
                        display: "block",
                      }}
                    />
                  )}
                </Col>
                <Col className="mt-1 pl-0 pr-0">
                  <small className="text-black-50">
                    <b>Xailient Device ID</b>
                  </small>
                  <br></br>
                  {!loading ? (
                    <label>
                      <small className="font-10">{device.id}</small>
                    </label>
                  ) : (
                    <Spinner
                      type="grow"
                      size="sm"
                      style={{
                        color: "#6544D0",
                        display: "block",
                      }}
                    />
                  )}
                </Col>
                {device && device.lastCheckinDate ? (
                  <Col className="mt-1 pl-0 pr-0">
                    <small className="text-black-50">
                      <b>Last Check-in Date</b>
                    </small>
                    <br></br>
                    {!loading ? (
                      <label>
                        {TimeStampTodateConverter(device.lastCheckinDate)}
                      </label>
                    ) : (
                      <Spinner
                        type="grow"
                        size="sm"
                        style={{
                          color: "#6544D0",
                          display: "block",
                        }}
                      />
                    )}
                  </Col>
                ) : (
                  ""
                )}
                <Col className="ml-0 pl-0">
                  <small className="text-black-50 ">
                    <b>Device status</b>
                  </small>
                  <br></br>
                  {!loading ? (
                    <Button
                      className="rounded small py-0"
                      disabled
                      size="sm"
                      variant={`outline-${
                        checkStatus(device.activated).variant
                      }`}
                    >
                      <small>{checkStatus(device.activated).text}</small>
                    </Button>
                  ) : (
                    <Spinner
                      type="grow"
                      size="sm"
                      style={{
                        color: "#6544D0",
                        display: "block",
                      }}
                    />
                  )}
                </Col>
                <Col className="mt-2 pl-0">
                  <small className="text-black-50 ">
                    <b>Days active</b>
                  </small>
                  <br></br>
                  {!loading ? (
                    <label>{calculateDays(device.activationDate)}</label>
                  ) : (
                    <Spinner
                      type="grow"
                      size="sm"
                      style={{
                        color: "#6544D0",
                        display: "block",
                      }}
                    />
                  )}
                </Col>
                <Col className="mt-2 pl-0">
                  <small className="text-black-50 ">
                    <b>Hardware Model</b>
                  </small>
                  <br></br>
                  {!loading ? (
                    <label>{device.hardwareModelName}</label>
                  ) : (
                    <Spinner
                      type="grow"
                      size="sm"
                      style={{
                        color: "#6544D0",
                        display: "block",
                      }}
                    />
                  )}
                </Col>
                {device.installedSDK && device.installedSDK.length !== 0 ? (
                  <Col className="mt-2 pl-0">
                    <small className="text-black-50">
                      <b>Installed SDK</b>
                    </small>
                    <br></br>
                    {!loading ? (
                      <div>
                        <label className="mb-0">
                          <small> ID : {device.installedSDK[0]}</small>
                        </label>
                        <br></br>
                        <label>
                          <small>Version : {device.installedSDK[1]}</small>
                        </label>
                      </div>
                    ) : (
                      <Spinner
                        type="grow"
                        size="sm"
                        style={{
                          color: "#6544D0",
                          display: "block",
                        }}
                      />
                    )}
                  </Col>
                ) : (
                  ""
                )}
                {device.currentModels && device.currentModels.length !== 0 ? (
                  <Col className="mt-2 pl-0">
                    <small className="text-black-50">
                      <b>Current Models</b>
                    </small>
                    <br></br>
                    {!loading ? (
                      <div>
                        {device.currentModels.map((model,idx) => (
                          <div key={idx}>
                            <ul className="mb-0">
                              <li>
                                <label className="mb-0">
                                  <small> ID : {model[0]}</small>
                                </label>
                                <br></br>
                                <label>
                                  <small>Version : {model[1]}</small>
                                </label>
                              </li>
                            </ul>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Spinner
                        type="grow"
                        size="sm"
                        style={{
                          color: "#6544D0",
                          display: "block",
                        }}
                      />
                    )}
                  </Col>
                ) : (
                  ""
                )}
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

DeviceDetail.propTypes = {
  refreshedIdToken: PropTypes.string,
};

DeviceDetail.defaultProps = {
  refreshedIdToken: "",
};

export default DeviceDetail;