/*eslint-disable*/
import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  Col,
  FormGroup,
  Row,
  Form,
  FormControl,
  FormLabel,
  Button,
} from "react-bootstrap";
import { Spinner, Badge } from "reactstrap";
import CustomAlert from "../../../components/alert/alert";
import CustomProgressBar from "../../../components/progress/progress";
import Icons from "../../../components/icons/icons";
import settings from "../../../config/config";
import { Requests } from "../../request/request.js";
import axios from "axios";
import Label from "./label";
import useAutoDetectLabels from "../../helper/auto_detect_label";

const AddDataset = (props) => {
  const file = useRef(null);
  const [dataset_name, setDatasetName] = useState(null);
  const [dataset_id, setDatasetID] = useState(null);
  const [file_name, setFileName] = useState(null);
  const [file_size, setFileSize] = useState(null);
  const [file_error, setFileError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [is_loading, setIsLoading] = useState(false);
  const [is_progress, setIsProgress] = useState(0);
  const [show_hide_upload, setShowHideUpload] = useState(true);
  const [validated, setValidated] = useState(false);
  const [{ labels_exist }, labelCheck] = useAutoDetectLabels();

  let token = props.refreshedIdToken;

  useEffect(() => {
    if (dataset_id && dataset_name && success && !labels_exist) {
      setShowHideUpload(false);
    }    
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure to close this tab?";
      return "Are you sure to close this tab?";
    };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
    // eslint-disable-next-line
  }, [success]);

  const validateFileUploadSize = async (file) => {
    const fileSize = file.size / 1024 / 1024; // in MiB
    setFileSize(fileSize);
  };

  const handleFileChange = (event) => {
    file.current = event.target.files[0];
    if (file.current) {
      if (/\.(zip|tar.gz)$/i.test(file.current.name) === false) {
        setFileError(
          "Unsupported File Format : The selected file is not a zip file. Please select .zip file and try again..!"
        );
        return null;
      }
      setFileError(null);
      var setFilenameAsInput;
      if (!dataset_name) {
        setFileName(file.current.name);
        var inputFileName = file.current.name.split(".")[0];
        document.getElementById("formHorizontalTrainingName").value =
          inputFileName;
        setFilenameAsInput = document.getElementById(
          "formHorizontalTrainingName"
        ).value;
        setDatasetName(setFilenameAsInput);
      }
      if (document.getElementById("formHorizontalTrainingName").value) {
        setFileName(file.current.name);
        setFilenameAsInput = document.getElementById(
          "formHorizontalTrainingName"
        ).value;
        setDatasetName(setFilenameAsInput);
      }
      validateFileUploadSize(file.current);
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    setValidated(true);

    var checkSplCharacter = /^[a-zA-Z0-9-_]+$/;
    if (
      !file.current ||
      dataset_name.search(checkSplCharacter) === -1 ||
      dataset_name.length === 0 ||
      dataset_name.split(" ").length > 1 ||
      dataset_name === ""
    ) {
      setFileError(
        "Please provide a valid dataset name without any whitespace and Special characters."
      );
      return null;
    } else {
      setFileError(null);
      setIsLoading(true);
      var createBucketAPI = settings.dataset;
      Requests(
        createBucketAPI,
        "POST",
        JSON.stringify({ name: dataset_name }),
        "event_body",
        token
      )
        .then(async (res) => {
          console.log("Store the dataset name in the DB :", res);
          if (file_size < 5) {
            try {
              await labelCheck(file.current);
            } catch (error) {
              setFileError(
                "Unexpected error occurred while autodetecting the labels! Please try again!"
              );
            }
          }
          if (res) {
            setIsLoading(true);
            setDatasetID(res["id"]);
            var params = "?id=" + res["id"] + "&__include_urls__=True";
            Requests(createBucketAPI, "GET", params, "query", token).then(
              (data) => {
                if (data) {
                  console.log(
                    "Bucket Created for Upload Training Dataset :",
                    data
                  );

                  var url_info = data[0].imagesUploadInfo.zip;

                  const formData = new FormData();
                  Object.keys(url_info.fields).forEach((key) => {
                    formData.append(key, url_info.fields[key]);
                  });
                  formData.append("file", file.current, "images.zip");
        
                  if(url_info && url_info["url"]){
                  axios
                    .post(url_info["url"], formData, {
                      onUploadProgress: (progressEvent) => {
                        var percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setIsProgress(percentCompleted);
                      },
                    })
                    .then(function (response) {
                      console.log("Uploaded Training Dataset:", response);
                      if (response.status === 204) {
                        setValidated(false);
                        setIsLoading(false);
                        setFileError(null);
                        setIsProgress(0);
                        setSuccess(true);
                        if (file_size > 5) {
                          setShowHideUpload(false);
                        }
                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                      setIsLoading(false);
                      setFileError(
                        "Internal Server Error..Please try again by refreshing your page. If the error persist, please contact support@xailient.com",
                        error.toString()
                      );
                    });
                  }
                }
              }
            );
          } else {
            setIsLoading(false);
            setFileError(
              "Internal Server Error..Please try again by refreshing your page. If the error persist, please contact support@xailient.com"
            );
          }
        })
        .catch((err) => {
          console.log(
            "Error creating the dataset name while uploading the images:",
            err
          );
          setIsLoading(false);
          setFileError(
            "Internal Server Error..Please try again by refreshing your page. If the error persist, please contact support@xailient.com. "
          );
        });
    }
  };

  const handleLabelGeneration = (event) => {
    event.preventDefault();
    alert("Label Generation feature is work in progress.");
    if (labels_exist && dataset_id) {
      var freezeDatasetAPI = settings.freezeDataset;
      var params = "?id=" + dataset_id;
      Requests(freezeDatasetAPI, "POST", params, "query", token)
        .then((res) => {
          if(res){
          console.log("Freeze Dataset", res);
          window.location.href = "/user/viewDataset";
          }
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
          setFileError(
            "Internal Server Error..Please try again by refreshing your page. If the error persist, please contact support@xailient.com"
          );
        });
    }
  };

  return (
    <Card.Body>
      <div className="d-flex align-items-center">
        <Card.Title>Add new Datasets</Card.Title>
      </div>

      <Card>
        {show_hide_upload && (
          <Card.Body>
            <div>
              <div className="first-class">
                <Card.Header as="h5" className="cardHeading">
                  Step 1. Add Training Images
                </Card.Header>

                <Card.Body>
                  <Card.Title style={{ fontSize: "12px", fontWeight: "600" }}>
                    Set Dataset Name
                  </Card.Title>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group as={Row} controlId="formHorizontalTrainingName">
                      <Form.Label
                        style={{ color: "#333333" }}
                        className="mb-2 text-muted"
                        column
                        sm={2}
                      >
                        Dataset Name
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="datasetName"
                          onChange={(e) => setDatasetName(e.target.value)}
                          //   disabled={disableInput}
                          placeholder="Enter your Dataset Name"
                          pattern="^[a-zA-Z0-9-_]+$"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid dataset name without any
                          whitespace and Special characters.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Card.Title style={{ fontSize: "12px", fontWeight: "600" }}>
                      Choose Image File
                    </Card.Title>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col sm={8}>
                            <div className="img-div pull-left">
                              <img
                                src={Icons.DatasetUpload}
                                className="datasetsImg"
                                alt="upload"
                              />
                            </div>
                            <div className="btn-div pull-left">
                              <span className="cardText">
                                Upload your images in a compressed file (zip).{" "}
                                <br />
                                Please ensure your images are in <b>
                                  .jpg
                                </b>, <b>.JPG</b>, <b>.jpeg</b>, <b>.JPEG</b>,{" "}
                                <b>.png</b> or <b>.PNG</b> format. <br />
                                {""}Max 10,000 images in one dataset. To upload
                                more images, please contact
                                support@xailient.com.
                              </span>
                            </div>
                          </Col>
                          <Col sm={4} style={{ marginTop: "15px" }}>
                            <FormGroup id="file">
                              <FormLabel
                                htmlFor="myDatasetFile"
                                className="btn-light custom-file-upload"
                              >
                                Choose File {""}
                                <img src={Icons.UploadIcon} alt="upload" />
                              </FormLabel>
                              <span>
                                {" "}
                                {file_name}{" "}
                                {is_loading && (
                                  <Spinner
                                    size="sm"
                                    role="status"
                                    color="warning"
                                  />
                                )}
                              </span>
                              <FormControl
                                onChange={handleFileChange}
                                name="name"
                                id="myDatasetFile"
                                type="file"
                              />
                            </FormGroup>
                            {is_progress !== Number(0) && (
                              <CustomProgressBar isProgress={is_progress} />
                            )}
                          </Col>

                          {/* If label already exists show alert Message */}
                          {labels_exist && success && (
                            <Col>
                              <br />
                              <CustomAlert
                                message="Label file has been auto detected. Please click on the action Generate your label file to automatically prepare the label file!"
                                theme="success"
                                image={Icons.CheckCircle}
                              />
                            </Col>
                          )}
                          {success && !labels_exist && (
                            <Col>
                              <CustomAlert
                                message="Success! Training images has been uploaded
                                  successfully."
                                theme="success"
                                image={Icons.CheckCircle}
                              />
                            </Col>
                          )}
                        </Row>
                      </Card.Body>
                    </Card>
                    {file_error && (
                      <CustomAlert
                        message={file_error}
                        theme="danger"
                        image={Icons.WarningRed}
                      />
                    )}

                    <Button
                      type={!labels_exist ? "submit" : null}
                      onClick={labels_exist ? handleLabelGeneration : null}
                      style={{ float: "right", textAlign: "center" }}
                      variant="warning"
                      // disabled={success ? true : false}
                    >
                      {labels_exist && success 
                        ? "Generate your label file"
                        : "Upload Training Images"}
                    </Button>

                    <br />
                  </Form>
                </Card.Body>
              </div>
            </div>
          </Card.Body>
        )}
        {dataset_name && dataset_id && !labels_exist && success && (
          <Card.Body>
            <div>
              <div className="first-class">
                <Card.Header as="h5" className="cardHeading">
                  Step 1. Add Training Images{" "}
                  <Badge color="success" pill>
                    Success
                  </Badge>
                </Card.Header>{" "}
                <Label
                  {...props}
                  dataset_name={dataset_name}
                  dataset_id={dataset_id}
                />
              </div>
            </div>
          </Card.Body>
        )}
      </Card>
    </Card.Body>
  );
};

export default AddDataset;
