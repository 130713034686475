import React from 'react';
import {
  Accordion, Col, Row, Tabs,
} from 'react-bootstrap';
import '../../assets/scss/internal.css';
import PropTypes from 'prop-types';
import CustomToggle from './toggle';

function Tab({ tabsdata = [] }) {
  return (
    <Tabs defaultActiveKey={Object.keys(tabsdata)[0]} id="uncontrolled-tab-example" className="mb-3 small">
      {
                tabsdata.map((tab, index) => {
                  const tabKey = index + 1;
                  return (
                    <Tab key={tabKey} eventKey={index} title={Object.keys(tab)}>
                      {Object.values(tab)[0].map((sectionData, i) => {
                        const secKey = i + 1;
                        return (
                          <Accordion key={secKey} defaultActiveKey={i} className="mb-3">
                            <Accordion.Item eventKey={i}>
                              <CustomToggle eventKey={i}>
                                <small>{Object.keys(sectionData)}</small>
                              </CustomToggle>
                              <Accordion.Body>
                                <Row>
                                  {Object.values(sectionData)[0].map((subSection, subSecIndex) => {
                                    const subSecKey = subSecIndex + 1;
                                    return (
                                      <Col key={subSecKey} xs={12} md={4}>
                                        <Col
                                          xs={12}
                                          key={`${Object.keys(subSection)}${subSecKey}`}
                                        >
                                          <small><b>{Object.keys(subSection)}</b></small>
                                        </Col>
                                        <Col
                                          xs={12}
                                          key={`${Object.values(subSection)}${subSecKey}`}
                                        >
                                          <small>{Object.values(subSection)}</small>
                                        </Col>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        );
                      })}

                    </Tab>
                  );
                })
            }
    </Tabs>
  );
}

Tab.propTypes = {
  tabsdata: PropTypes.instanceOf(Array),
};

Tab.defaultProps = {
  tabsdata: [],
};
export default Tab;
