/*eslint-disable*/

import React, { useState, useEffect, Suspense } from "react";
import {
  Table,
  Card,
  Button,
  Spinner,
  Modal,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Requests } from "../request/request";
import settings from "../../config/config";
import CustomPagination from "../../components/pagination/pagination";
import { TimeStampTodateConverter } from "../../components/timestamp/unix_to_datetime_converter";
import useIsMountedRef from "../../views/helper/isMounted";
import { stringify } from "query-string";

export const ViewTeamMember = (props) => {
  const [token, setToken] = useState(props.refreshedIdToken);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const dataPerPage = 6;
  const currentData = users.slice(
    currentPage * dataPerPage,
    (currentPage + 1) * dataPerPage
  );
  const isMountedRef = useIsMountedRef();
  const [deletePopup, setDeletePopup] = useState(false);
  const [spin, setSpin] = useState(false);
  const [customerID, setCustomerID] = useState("");
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [roles, setRoles] = useState([]);
  const [userType, setUserType] = useState("Customer");

  const fetchAllUsers = () => {
    var queryParams = {
      q: JSON.stringify({
        userType: "Customer",
        companyID: window.localStorage.getItem("companyID") || "",
      }),
    };
    Requests(
      settings.user,
      "GET",
      props.viewTeamMembers ? stringify(queryParams) : "fetch=all",
      "query",
      token
    )
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setUsers(data);
          setLoading(false);
        } else {
          return;
        }
      })
      .catch((e) => {
        setUsers(null);
        setLoading(false);
        return null;
      });
  };
  useEffect(() => {
    fetchAllUsers();
  }, [isMountedRef]);

  const viewRoleNames = (id, user_id) => {
    if (id && user_id) {
      Requests(
        settings.role,
        "GET",
        "id=" + encodeURIComponent(id),
        "query",
        token
      )
        .then((data) => {
          if (data && id === data[0].id) {
            return (document.getElementById(user_id).innerHTML = data[0].name);
          } else {
            return null;
          }
        })
        .catch((e) => {
          return null;
        });
    } else {
      return null;
    }
  };

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  useEffect(() => {
    let params = "Customer"
    Requests(
      settings.role,
      "GET",
      `q=${encodeURIComponent(JSON.stringify({ userType: params }))}`,
      "query",
      token
    )
      .then((data) => {
        if (isMountedRef.current && data && !data.status) {
          setRoles(data);
        }
      })
      .catch((e) => {
        return null;
      });
  }, [isMountedRef]);

  return (
    <div className="content mt-4">
      <Card>
        <Card.Body style={{ overflow: "auto" }}>
          <div className="d-flex align-items-center">
            <Card.Title className="custom-card-title">
              {props.viewTeamMembers ? "View Team Members" : `View Users `} (
              {users.length})
            </Card.Title>
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Link
                  to={
                    props.viewTeamMembers ? "/inviteTeamMember" : "/manageusers"
                  }
                >
                  <Button
                    type="submit"
                    id="manage-users-btn"
                    variant="primary"
                    className="mb-2"
                  >
                    Invite New Team Member
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <Table
            borderless
            size="md"
            className="border-top"
            id="view-users-table"
          >
            <thead className="font-14">
              <tr className="border-bottom">
                <th className="white-header pl-5 column-width">ID</th>
                <th className="white-header">Email</th>
                <th className="white-header">First Name</th>
                <th className="white-header">Last Name</th>
                <th className="white-header">Role Name</th>
                <th className="white-header">User Type</th>
                <th className="white-header">Date</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((user, index) => (
                <tr className="font-14 border-bottom" key={index}>
                  <td className="pl-5">{user.id}</td>
                  <td>{user.customerID}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td id={user.id}>{viewRoleNames(user.roleID, user.id)}</td>
                  <td>{user.userType}</td>
                  <td>{TimeStampTodateConverter(user.creationDate)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && (
            <div className="text-center">
              <Spinner style={{ color: "#705AF3" }} animation="grow"></Spinner>
            </div>
          )}
        </Card.Body>
        <CustomPagination
          size="lg"
          previous={() => paginate(currentPage - 1)}
          next={() => paginate(currentPage + 1)}
          currentPage={currentPage}
          increment={`${currentPage + 1} of ${Math.ceil(
            users.length / dataPerPage
          )}`}
          pagesCount={Math.ceil(users.length / dataPerPage)}
        />
      </Card>
    </div>
  );
};
